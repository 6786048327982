import { useState } from 'react';
import { useGetConfigRegions } from '../../../../../hooks/admin/config/cities.hooks';
import MKButton from '../../../../UI/MKButton';
import MKCard from '../../../../UI/MKCard';
import CreateCityDrawer from './CreateCityDrawer';
import CreateRegionDrawer from './CreateRegionDrawer';
import RegionList from './RegionList';

const RegionsCitiesList = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [forceRefetch, setForceRefetch] = useState(false);
  const { regionsAndCities, loading, error, Refetch } = useGetConfigRegions();

  const handleDrawer = () => {
    setOpenDrawer((prevValue) => !prevValue);
  };

  return (
    <>
      <section>
        <MKCard className='flex flex-col items-center justify-center gap-2'>
          {(!regionsAndCities || regionsAndCities.length === 0) && !loading && (
            <>
              <p>No tienes departamentos creados, agrega uno nuevo</p>

              <MKButton
                onClick={handleDrawer}
                variant='outlined'
                className='w-32 self-center'
              >
                <p>Crear</p>
              </MKButton>
            </>
          )}

          {regionsAndCities && regionsAndCities.length > 0 && !loading && (
            <section className='flex w-full flex-col justify-start gap-4 '>
              <section className='flex flex-row items-center gap-4 border-b border-b-primary-100 pb-4'>
                <p>Departamentos</p>
                <MKButton
                  onClick={handleDrawer}
                  variant='outlined'
                  className='px-2'
                >
                  <p>Crear departamento</p>
                </MKButton>
              </section>
              {regionsAndCities.map(({ name, _id, active, cities }) => (
                <RegionList
                  Refetch={Refetch}
                  cities={cities}
                  active={active}
                  id={_id}
                  name={name}
                />
              ))}
            </section>
          )}
        </MKCard>
      </section>
      {openDrawer && (
        <>
          <CreateRegionDrawer
            Refetch={Refetch}
            handleCloseDrawer={handleDrawer}
          />
        </>
      )}
    </>
  );
};

export default RegionsCitiesList;
