import React, { useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { IoShieldCheckmark, IoShieldOutline } from 'react-icons/io5';
import { IStaffRoleToAddState } from '../../../../../datatypes/interfaces/roles';
import { IStaffRoleId } from '../../../../../datatypes/interfaces/staff';

interface ISingleStaffRoleProps {
  roleName: string;
  roleId: string;
  selectedRoles: string[];
  handleSelectRoles: (roleId: string, isSelected: boolean) => void;
}
const SingleStaffRole = ({
  roleName,
  roleId,
  selectedRoles,
  handleSelectRoles,
}: ISingleStaffRoleProps) => {
  const isSelected =
    Array.isArray(selectedRoles) &&
    selectedRoles.find((selectedRole) => selectedRole === roleId) !== undefined;
  const handleSelected = () => {
    handleSelectRoles(roleId, isSelected);
  };
  return (
    <>
      <section
        className={`mb-3 flex w-full flex-col items-center justify-between rounded-xl border-2 p-1 
          ${isSelected ? 'border-primary-500' : 'border-slate-200'}`}
      >
        <section className='w-full '>
          <button
            className={`flex w-full items-center justify-between py-2`}
            onClick={() => handleSelected()}
          >
            <section className='flex items-center'>
              <div className='flex h-8 w-8 items-center justify-center rounded-full bg-gray-300'>
                {isSelected && <FaCheck className='text-primary-500' />}
              </div>

              <div>
                <p className='mx-4 text-base font-bold'>{roleName}</p>
              </div>
            </section>
            {!isSelected ? (
              <IoShieldOutline className='h-6 w-6' />
            ) : (
              <IoShieldCheckmark className='h-6 w-6 text-primary-500' />
            )}
          </button>
        </section>
      </section>
    </>
  );
};

export default SingleStaffRole;
