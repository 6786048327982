import React from 'react';
import { MdOutlineHideImage } from 'react-icons/md';
import styles from '../../../styles/styles';

interface IProductInShopDetail {
  data: any;
}
const ProductInShopDetail = ({ data }: IProductInShopDetail) => {
  return (
    <>
      <section className='flex w-56 flex-col justify-between rounded-lg border border-slate-200 bg-white p-3'>
        <div className='h-[210px]'>
          {Array.isArray(data.images) && data.images.length >= 1 ? (
            <img
              src={`${data.images && data.images[0]?.url}`}
              alt=''
              className='h-[120px] w-full object-contain'
            />
          ) : (
            <MdOutlineHideImage className='h-[120px] w-full text-gray-200' />
          )}

          <section className='px-2'>
            <div className='flex items-center justify-between pt-2'>
              <div className=''>
                <p className={`${styles.productDiscountPrice}`}>
                  C$
                  {data.originalPrice === 0
                    ? data.originalPrice.toFixed(2)
                    : data.discountPrice?.toFixed(2)}
                </p>
                <p className={`${styles.price} text-black`}>
                  {data.originalPrice
                    ? 'C$' + data.originalPrice.toFixed(2)
                    : null}
                </p>
              </div>
            </div>

            <h4 className='mb-2 line-clamp-1 font-[400] text-gray-600'>
              {data.name}
            </h4>
          </section>
        </div>
      </section>
    </>
  );
};

export default ProductInShopDetail;
