import axios from 'axios';
import {
  ICategoryModel,
  ICategoryModelResponse,
  ICreateCategory,
} from '../../interfaces/category/categoryModel';
import { server } from '../../server';

export const createCategoryService = async (category: ICreateCategory) => {
  try {
    const result = await axios.post<ICategoryModel>(
      `${server}/category/create-category`,
      category,
      {
        withCredentials: true,
      }
    );
    if (result.status === 201) {
      return {
        success: true,
        data: result.data,
      };
    }
    return {
      error: true,
      message: result.statusText,
    };
  } catch (error) {
    return {
      error: true,
      message: error.message,
    };
  }
};

export const fetchAllCategoriesService = async () => {
  try {
    const result = await axios.get<ICategoryModelResponse>(
      `${server}/category/get-all`,
      {
        withCredentials: true,
      }
    );
    return result;
  } catch (error) {
    return {
      error: true,
      message: error.message,
    };
  }
};

export const updateCategoryService = async (
  id: string,
  category: Partial<ICreateCategory>
) => {
  try {
    // update-category
    const result = await axios.put<Partial<ICategoryModel>>(
      `${server}/category/update-category/${id}`,
      category,
      {
        withCredentials: true,
      }
    );
    if (result.status === 200) {
      return {
        success: true,
        data: result.data,
      };
    }
    return {
      error: true,
      message: result.statusText,
    };
  } catch (error) {
    return {
      error: true,
      message: error.message,
    };
  }
};
