import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ShopCreate from '../components/Shop/ShopCreate';
import { useAppSelector } from '../hooks/common/redux.hooks';

const ShopCreatePage = () => {
  const navigate = useNavigate();
  const { isSeller, seller } = useAppSelector((state) => state.seller);

  useEffect(() => {
    if (isSeller === true) {
      navigate(`/shop/${seller._id}`);
    }
  }, []);
  return (
    <div>
      <ShopCreate />
    </div>
  );
};

export default ShopCreatePage;
