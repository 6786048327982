import { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../UI/MKTabs';
import AllPartners from '../AllPartners';
import PotentialPartners from '../PotentialPartners';

const PartnersList = () => {
  const [activeTab, setActiveTab] = useState('allPartners');
  const handleTabChange = (name) => {
    setActiveTab(name);
  };
  return (
    <>
      <div className='flex w-full justify-center px-2 pt-5 md:px-4'>
        <Tabs
          defaultValue='allPartners'
          value={activeTab}
          onValueChange={(tabName) => handleTabChange(tabName)}
          className='w-full'
        >
          <TabsList className='grid w-full grid-cols-2 bg-gray-100 p-0'>
            <TabsTrigger
              className='data-[state=active]:border-b-2 data-[state=active]:border-primary-200'
              value='allPartners'
            >
              <p className='text-base font-semibold'>Afiliados</p>
            </TabsTrigger>
            <TabsTrigger
              className='data-[state=active]:border-b-2 data-[state=active]:border-primary-200'
              value='potentialPartners'
            >
              <p className='text-base font-semibold'>Afiliados Potenciales</p>
            </TabsTrigger>
          </TabsList>

          <TabsContent className='mt-8' value='allPartners'>
            <AllPartners />
          </TabsContent>
          <TabsContent value='potentialPartners'>
            <PotentialPartners />
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
};

export default PartnersList;
