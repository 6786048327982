import { useEffect, useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../UI/MKTabs';
import OrdersOnHold from './OrdersOnHold';
import OrdersToDeliver from './OrdersToDeliver';
import useDriverSession from '../../../hooks/drivers/useDriverSession';

const OrdersTab = () => {
  const { findDriverOrders } = useDriverSession();
  const [activeTab, setActiveTab] = useState('ordersOnHold');
  const handleTabChange = (name) => {
    setActiveTab(name);
  };

  useEffect(() => {
    findDriverOrders();
  }, []);

  return (
    <>
      <div className='flex w-full pt-5'>
        <Tabs
          defaultValue='ordersOnHold'
          value={activeTab}
          onValueChange={(tabName) => handleTabChange(tabName)}
          className='w-full'
        >
          <TabsList className='grid w-full grid-cols-2 bg-gray-100 p-0'>
            <TabsTrigger
              className='data-[state=active]:border-b-2 data-[state=active]:border-primary-200'
              value='ordersOnHold'
            >
              <p className='text-base font-semibold'>Ordenes pendientes</p>
            </TabsTrigger>
            <TabsTrigger
              className='data-[state=active]:border-b-2 data-[state=active]:border-primary-200'
              value='ordersToDeliver'
            >
              <p className='text-base font-semibold'>Ordenes a entregar</p>
            </TabsTrigger>
          </TabsList>

          <TabsContent className='mt-8' value='ordersOnHold'>
            <OrdersOnHold />
          </TabsContent>
          <TabsContent value='ordersToDeliver'>
            <OrdersToDeliver />
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
};

export default OrdersTab;
