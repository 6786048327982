export const ADMIN_NAVIGATION = {
  dashboard: {
    link: '/admin-dashboard',
    id: 1,
  },
  orders: {
    link: '/admin-orders',
    id: 2,
  },
  sellers: {
    link: '/admin-sellers',
    id: 3,
  },
  users: {
    link: '/admin-users',
    id: 4,
  },
  products: {
    link: '/admin-products',
    id: 5,
  },
  banners: {
    link: '/admin-banners-list',
    id: 6,
  },
  drivers: {
    link: '/admin-drivers-list',
    id: 10,
  },
  categories: {
    link: '/admin-categories',
    id: 9,
  },
  withdrawRequest: {
    link: '/admin-withdraw-request',
    id: 7,
  },
  configuration: {
    link: '/admin-configuration',
    id: 8,
  },
};

export const DRIVER_NAVIGATION = {
  dashboard: {
    link: '/drivers-dashboard',
    id: 1,
  },
  orders: {
    link: '/drivers-orders',
    id: 2,
  },
};
