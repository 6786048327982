import { useState } from 'react';
import { IoMdInformationCircle } from 'react-icons/io';
import RoleResources from '../Resources';
import { SHOP_RESOURCE_ROLE_NAME } from '../../../../../utils/constants/common';
import styles from '../../../../../styles/styles';
import { IResourceRole } from '../../../../../datatypes/interfaces/roles';
import { createRoleService } from '../../../../../services/roles/roles';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CreateRoleForm = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [resources, setResources] = useState<IResourceRole[]>([]);
  const resourcesName = Object.keys(SHOP_RESOURCE_ROLE_NAME);
  const handleAddNewResource = (res: IResourceRole) => {
    setResources((prev) => [...prev, res]);
  };
  const handleUpdateResource = (res: IResourceRole) => {
    const resourceToUpdate = resources.map((resource) =>
      resource.name === res.name
        ? { ...resource, permissions: { ...res.permissions } }
        : resource
    );
    if (resourceToUpdate.length > 0) {
      setResources(resourceToUpdate);
    }
  };

  const handleRemoveResource = (resourceName: string) => {
    // setResource((prev) => [...prev, res]);
    const filterResource = resources.filter(
      (resourceInRole) => resourceInRole.name !== resourceName
    );
    setResources(filterResource);
  };
  const handleClickCreateRole = async () => {
    if (name === '' || resources.length === 0) {
      toast.error('Debes brindar un nombre y seleccionar al menos un rol');
      return;
    }
    const roleAttributes = { name, resources };

    const result = await createRoleService(roleAttributes);
    if (result.status === 500) {
      toast.error(
        'Disculpa, ha ocurrido un error al crear un rol, intenta mas tarde'
      );
      return;
    }
    toast.success('Rol creado con éxito');
    navigate('/dashboard-roles');
    // console.log('result:', result);
  };
  return (
    <>
      <section className='mt-8 w-full sm:mx-auto md:w-3/4'>
        <div>
          <label htmlFor='email' className='block font-medium '>
            Nombre del rol
          </label>
          <div className='mt-1'>
            <input
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
              className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-200 focus:outline-none focus:ring-primary-200 sm:text-sm'
            />
          </div>
        </div>
        <div>
          <p className='my-4 block font-medium '>
            Selecciona los recursos a los que tendrá acceso este rol
          </p>
          {resources.length > 0 && (
            <section className='my-3 flex w-max items-center gap-2 rounded-md bg-gray-100 p-2'>
              <IoMdInformationCircle className='text-gray-400' />
              El permiso de lectura debe estar activo para permitir los demás
              permisos
            </section>
          )}
          <div className='flex w-full flex-col'>
            {resourcesName?.map((resourceName) => (
              <RoleResources
                name={resourceName}
                resources={resources}
                handleAddNewResource={handleAddNewResource}
                handleUpdateResource={handleUpdateResource}
                handleRemoveResource={handleRemoveResource}
              />
            ))}
          </div>
        </div>
        <section className='flex justify-center'>
          <button
            onClick={handleClickCreateRole}
            className={`${styles.button} w-full md:w-2/4`}
          >
            Crear Rol
          </button>
        </section>
      </section>
    </>
  );
};

export default CreateRoleForm;
