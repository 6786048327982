import { LuBadgeInfo } from 'react-icons/lu';
import MKCard from '../../UI/MKCard';
import ConfigList from './Config/configList';

const AdminConfiguration = () => {
  return (
    <section className='m-4 w-full'>
      <p className='mb-2 text-lg font-bold '>Configuración de sitio</p>
      <MKCard className='w-full p-8'>
        <p className='text-md mb-6 border-b-2 pb-4 font-bold'>
          Administra las zonas de operación
        </p>
        <section className='my-3 rounded-sm border border-dashed border-orange-400 bg-orange-50 p-4'>
          <div className='flex flex-row items-center gap-2'>
            <LuBadgeInfo className=' h-8 w-8 self-start text-orange-300' />
            <section>
              <p className='font-bold'>
                Puedes cambiar el nombre de pais, los departamentos donde opera
                tu negocio y las ciudades
              </p>
              <p className='text-sm text-slate-500'>
                Ten en cuenta que cualquier cambio puede alterar tus areas
                operativas activas
              </p>
            </section>
          </div>
        </section>
        <ConfigList />
      </MKCard>
    </section>
  );
};

export default AdminConfiguration;
