import { useState } from 'react';
import MKDrawer from '../../UI/MKDrawer';
import MKButton from '../../UI/MKButton';
import { IoMdCloseCircle } from 'react-icons/io';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  ICategoryModel,
  ICreateCategory,
} from '../../../interfaces/category/categoryModel';
import styles from '../../../styles/styles';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { GradientPicker } from '../../Common/ColorPicker/ColorPicker';
import { FaEdit } from 'react-icons/fa';
import useCategories from '../../../hooks/category/useCategoriesData';
import { toast } from 'react-toastify';

interface IAddCategoryProps {
  closeAddCategoryDrawer: (triggerFetch?: boolean) => void;
  categoryProps?: ICategoryModel;
}

const AddCategory = ({
  closeAddCategoryDrawer,
  categoryProps,
}: IAddCategoryProps) => {
  const { create, clearState, update, state } = useCategories();
  const [images, setImages] = useState(
    categoryProps ? categoryProps.image.url : null
  );

  const [background, setBackground] = useState(
    categoryProps ? categoryProps.image?.mainColor : '#FDAA5D'
  );
  const [roundedColor, setRoundedColor] = useState(
    categoryProps ? categoryProps.image?.secondaryColor : '#CA884A'
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ICreateCategory>(
    categoryProps
      ? {
          defaultValues: {
            name: categoryProps.name,
            isActive: categoryProps.isActive,
          },
        }
      : {}
  );
  const watchName = watch('name'); // you can also target specific fields by their names

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    // setImages([]);
    const reader = new FileReader();

    files.forEach((file: any) => {
      reader.onload = () => {
        if (reader.readyState === 2 && typeof reader.result === 'string') {
          setImages(reader.result);
        }
      };
      reader.readAsDataURL(file);
    });
  };
  const handleCreateCategory: SubmitHandler<ICreateCategory> = async ({
    name,
    isActive,
  }) => {
    if (!name || images === null) {
      toast.error('Por favor completar los campos requeridos');
      return;
    }
    if (categoryProps) {
      const updatevalues = {
        name,
        isActive,
        imageProperty: { mainColor: background, secondaryColor: roundedColor },
      };
      if (categoryProps && categoryProps.image?.url !== images) {
        // @ts-ignore
        updatevalues.image = images;
      }
      const res = await update(categoryProps._id, {
        ...updatevalues,
      });
      if (res == null || res.error || res.success === false) {
        console.log('ACTUALIZADOO ERROR!');

        toast.error(state.message);
        clearState();
        return;
      }
      clearState();
      toast.success('Actualizado con éxito');
      closeAddCategoryDrawer(true);
      return;
    }

    const res = await create({
      name,
      isActive,
      // @ts-ignore
      image: images,
      imageProperty: { mainColor: background, secondaryColor: roundedColor },
    });

    if (res == null || res.error || res.success === false) {
      toast.error(state.message);
      clearState();
      return;
    }
    clearState();
    toast.success('Guardado con éxito');
    closeAddCategoryDrawer(true);
  };

  return (
    <MKDrawer>
      <section className='p-2 md:p-4'>
        <section className='flex w-full items-center justify-between gap-2'>
          <p className='text-lg'>
            {categoryProps ? 'Modificar ' : 'Crear '} categoria
          </p>
          <MKButton onClick={() => closeAddCategoryDrawer()} variant='none'>
            <IoMdCloseCircle className='h-7 w-7' />
          </MKButton>
        </section>
        <form onSubmit={handleSubmit(handleCreateCategory)}>
          <section className='py-2'>
            <label htmlFor='name'>Nombre de categoria</label>
            <input
              {...register('name')}
              placeholder='Abarrotes, Frutas, etc...'
              className={styles.default_input}
            />
          </section>
          <div>
            <label className='pb-2'>
              Cargar Imagen<span className='text-red-500'>*</span>
            </label>
            <input
              type='file'
              name=''
              id='upload'
              className='hidden'
              multiple
              onChange={handleImageChange}
            />
            <div className='flex w-full flex-wrap items-center'>
              {images === null ? (
                <label htmlFor='upload'>
                  <AiOutlinePlusCircle
                    size={30}
                    className='mt-3 text-primary-200'
                  />
                </label>
              ) : (
                <section>
                  <div className='flex items-center gap-2'>
                    <label htmlFor='upload'>
                      <FaEdit size={30} className='mt-3 text-primary-500' />
                    </label>
                    <div
                      style={{ background }}
                      className='relative h-48 w-48 overflow-hidden rounded-md'
                    >
                      <div className='absolute inset-x-0 top-2 text-center text-white md:top-4'>
                        <p className='line-clamp-2 text-sm md:text-base'>
                          {watchName}
                        </p>
                      </div>

                      <div
                        className='absolute -bottom-1/3 left-0 right-0 ml-auto mr-auto h-40 w-40 rounded-full'
                        style={{ background: roundedColor }}
                      />
                      <img
                        src={images}
                        alt=''
                        className='absolute bottom-0 left-0 right-0 ml-auto mr-auto w-full object-cover'
                      />
                    </div>
                  </div>
                  <p className='mt-2 text-lg font-bold text-gray-400 md:mt-4'>
                    Propiedades de imagen
                  </p>
                  <p>Color de fondo</p>
                  <div
                    className='preview flex h-full min-h-[25px] w-full items-center justify-center rounded !bg-cover !bg-center p-5 transition-all'
                    style={{ background }}
                  >
                    <GradientPicker
                      background={background}
                      setBackground={setBackground}
                    />
                  </div>
                  <p>Color secundario</p>
                  <div
                    className='preview flex h-full min-h-[25px] w-full items-center justify-center rounded !bg-cover !bg-center p-5 transition-all'
                    style={{ background: roundedColor }}
                  >
                    <GradientPicker
                      background={roundedColor}
                      setBackground={setRoundedColor}
                    />
                  </div>
                </section>
              )}
            </div>
            <section className='flex items-center gap-2 py-2'>
              <input
                className='h-4 w-4 accent-primary-200'
                {...register('isActive')}
                type='checkbox'
                defaultChecked={categoryProps ? categoryProps.isActive : true}
              />
              <label htmlFor='active'>Categoria activa</label>
            </section>
            <div className='flex justify-center'>
              <input
                type='submit'
                value={
                  categoryProps ? 'Actualizar categoría' : 'Crear categoría'
                }
                className={`${styles.button} w-2/3`}
              />
            </div>
          </div>
        </form>
      </section>
    </MKDrawer>
  );
};

export default AddCategory;
