import { create } from 'zustand';
import { ICategoryModel } from '../interfaces/category/categoryModel';
import { IFetchState } from '../interfaces/common';

interface ICategoriesStore {
  categories: ICategoryModel[] | null;
  state: IFetchState;
  setCategories: (allCategories: ICategoryModel[]) => void;
  setLoading: (loading?: boolean) => void;
  setError: (error?: boolean, message?: string) => void;
  clearState: () => void;
}
export const useCategoriesStore = create<ICategoriesStore>((set) => ({
  categories: null,
  state: { loading: false, error: false },
  setCategories: (allCategories: ICategoryModel[]) =>
    set({ categories: allCategories, state: { loading: false, error: false } }),
  setLoading: (loading = true) =>
    set({ state: { loading: loading, error: false, message: '' } }),
  setError: (error = true, message = '') =>
    set({ state: { loading: false, error: error, message } }),
  clearState: () =>
    set({ state: { loading: false, error: false, message: null } }),
}));
