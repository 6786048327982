import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ShopLogin from '../components/Shop/ShopLogin';
import { useAppSelector } from '../hooks/common/redux.hooks';
import axios from 'axios';
import { server } from '../server';

const ShopLoginPage = () => {
  const navigate = useNavigate();
  const { isSeller, isLoading } = useAppSelector((state) => state.seller);
  const { admin } = useAppSelector((state) => state.admin);
  const logoutHandler = async () => {
    try {
      await axios.get(`${server}/admin/logout-admin`, {
        withCredentials: true,
      });
    } catch (error) {}
  };

  useEffect(() => {
    console.log('admin:', admin);

    if (isSeller === true) {
      if (admin) {
        logoutHandler().finally(() => {
          navigate(`/dashboard`);
        });
      } else {
        navigate(`/dashboard`);
      }
    }
  }, [isSeller, admin]);

  return (
    <div>
      <ShopLogin />
    </div>
  );
};

export default ShopLoginPage;
