import { useState } from 'react';
import {
  clearProductState,
  createProduct,
  getAllProductsShop,
} from '../../redux/actions/product';
import { useAppDispatch, useAppSelector } from '../common/redux.hooks';
import { ICreateProduct } from '../../interfaces/products/createProduct';

interface IUseShopPdoucts {
  fetch?: boolean;
  shopId: string;
}
const useShopProducts = ({ fetch = false, shopId }: IUseShopPdoucts) => {
  const dispatch = useAppDispatch();
  const { isLoading, status, products } = useAppSelector(
    (state) => state.products
  );
  const [refetch, setRefetch] = useState(fetch);

  const handleSetRefresh = () => {
    dispatch(getAllProductsShop(shopId));
  };
  const create = ({
    name,
    description,
    categories,
    originalPrice,
    discountPrice,
    stock,
    images,
    isActive,
  }: ICreateProduct) => {
    dispatch(
      createProduct({
        name,
        description,
        categories,
        originalPrice: Number(originalPrice),
        discountPrice: Number(discountPrice),
        stock,
        images,
        isActive,
      })
    );
  };
  const clearState = () => {
    dispatch(clearProductState());
  };

  if (refetch) {
    setRefetch(false);
    console.log('refetch getAllProductsShop');

    dispatch(getAllProductsShop(shopId));
  }

  return {
    isLoading,
    products,
    status,
    shopProductsStatus: status,
    refetchProducts: handleSetRefresh,
    clearState,
    create,
  };
};

export default useShopProducts;
