import { AiOutlineGift } from 'react-icons/ai';
import { MdOutlineLocalOffer } from 'react-icons/md';
import { FiPackage, FiShoppingBag } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BiMessageSquareDetail } from 'react-icons/bi';
import styles from '../../../styles/styles';
import { BsShop } from 'react-icons/bs';

const DashboardHeader = () => {
  const { seller } = useSelector((state) => state.seller);
  return (
    <div className={`${styles.section} mx-auto my-0 mt-4 max-w-[80rem]`}>
      <div className='flex flex-row justify-between'>
        <Link to='/dashboard'>
          <img
            className='h-16 w-16'
            src='/assets/mercoline-logo-300x300.jpeg'
            alt=''
          />
        </Link>
        <div className='flex items-center'>
          <div className='mr-4 flex items-center'>
            <Link to='/dashboard/cupouns' className='hidden 800px:block'>
              <AiOutlineGift
                color='#555'
                size={30}
                className='mx-5 cursor-pointer'
              />
            </Link>
            <Link to='/dashboard-events' className='hidden 800px:block'>
              <MdOutlineLocalOffer
                color='#555'
                size={30}
                className='mx-5 cursor-pointer'
              />
            </Link>
            <Link to='/dashboard-products' className='hidden 800px:block'>
              <FiShoppingBag
                color='#555'
                size={30}
                className='mx-5 cursor-pointer'
              />
            </Link>
            <Link to='/dashboard-orders' className='hidden 800px:block'>
              <FiPackage
                color='#555'
                size={30}
                className='mx-5 cursor-pointer'
              />
            </Link>
            <Link to='/dashboard-messages' className='hidden 800px:block'>
              <BiMessageSquareDetail
                color='#555'
                size={30}
                className='mx-5 cursor-pointer'
              />
            </Link>
            <Link to={`/shop/${seller.shopId}`}>
              {seller.shop?.avatar?.url ? (
                <img
                  src={`${seller?.shop?.avatar?.url}`}
                  alt=''
                  className='h-[50px] w-[50px] rounded-full object-cover'
                />
              ) : (
                <BsShop className='h-[28px] w-[28px] text-gray-700' />
              )}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
