import { Navigate } from 'react-router-dom';
import { useDriverSessionStore } from '../store/driversSession';

const ProtectedDriverRoute = ({ children }) => {
  const { driverSession, driverFetchState } = useDriverSessionStore();
  console.log('driverFetchState:', driverFetchState);
  console.log('driverSession:', driverSession);
  if (driverFetchState.loading === false) {
    if (!driverSession) {
      return <Navigate to='/drivers-signin' replace />;
    }
    return children;
  }

  return <></>;
};

export default ProtectedDriverRoute;
