import CategoryList from '../../../components/Admin/Category/CategoryList';
import AdminSideBar from '../../../components/Admin/Layout/AdminSideBar';
import AdminHeader from '../../../components/Layout/AdminHeader';

const CategoriesPage = () => {
  return (
    <div>
      <div>
        <AdminHeader />
        <div className='flex w-full'>
          <div className='flex w-full items-start justify-between'>
            <div className='w-[80px] 800px:w-[330px]'>
              <AdminSideBar active={9} />
            </div>
            <CategoryList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoriesPage;
