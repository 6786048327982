import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@material-ui/data-grid';
import { AiOutlineDelete } from 'react-icons/ai';
import { RxCross1 } from 'react-icons/rx';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';

import { getAllUsers } from '../../redux/actions/user';
import { server } from '../../server';
import styles from '../../styles/styles';
import { useAdminStaff } from '../../hooks/admin/useAdminStaff';

const AllUsers = () => {
  const { staffMembers } = useAdminStaff(true);
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState('');

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  const handleDelete = async (id) => {
    await axios
      .delete(`${server}/user/delete-user/${id}`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
      });

    dispatch(getAllUsers());
  };

  const columns = [
    { field: 'id', headerName: 'User ID', minWidth: 150, flex: 0.7 },

    {
      field: 'name',
      headerName: 'name',
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'text',
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: 'role',
      headerName: 'User Role',
      type: 'text',
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: 'joinedAt',
      headerName: 'joinedAt',
      type: 'text',
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: ' ',
      flex: 1,
      minWidth: 150,
      headerName: 'Delete User',
      type: 'number',
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button onClick={() => setUserId(params.id) || setOpen(true)}>
              <AiOutlineDelete size={20} />
            </Button>
          </>
        );
      },
    },
  ];

  const row = [];
  staffMembers &&
    staffMembers.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        email: item.email,
        role: item.role,
        joinedAt: item.createdAt.slice(0, 10),
      });
    });

  return (
    <div className='flex w-full justify-center pt-5'>
      <div className='w-[97%]'>
        <h3 className='pb-2 font-Poppins text-[22px]'>Staff administrativo</h3>
        <div className='min-h-[45vh] w-full rounded bg-white'>
          <DataGrid
            rows={row}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
          />
        </div>
        {open && (
          <div className='fixed left-0 top-0 z-[999] flex h-screen w-full items-center justify-center bg-[#00000039]'>
            <div className='min-h-[20vh] w-[95%] rounded bg-white p-5 shadow 800px:w-[40%]'>
              <div className='flex w-full cursor-pointer justify-end'>
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className='py-5 text-center font-Poppins text-[25px] text-[#000000cb]'>
                Are you sure you wanna delete this user?
              </h3>
              <div className='flex w-full items-center justify-center'>
                <div
                  className={`${styles.button} mr-4 !h-[42px] text-[18px] text-white`}
                  onClick={() => setOpen(false)}
                >
                  cancel
                </div>
                <div
                  className={`${styles.button} ml-4 !h-[42px] text-[18px] text-white`}
                  onClick={() => setOpen(false) || handleDelete(userId)}
                >
                  confirm
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllUsers;
