import { ReactNode } from 'react';
import DashboardHeader from '../../Shop/Layout/DashboardHeader';
import Footer from '../Footer';

interface IDashboardCommonLayout {
  children: ReactNode;
}

const DashboardCommonLayout = ({ children }: IDashboardCommonLayout) => {
  return (
    <div className='flex min-h-screen flex-col justify-between'>
      <div className='mb-12'>
        <DashboardHeader />
        <div className='mx-auto my-0 max-w-[80rem]'>{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default DashboardCommonLayout;
