import React from 'react';
import MKDrawer from '../../../../components/UI/MKDrawer';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { CiEdit } from 'react-icons/ci';
import { IShopRole } from '../../../../datatypes/interfaces/roles';
import styles from '../../../../styles/styles';
import RoleResources from '../../../../components/Shop/Roles/CreateRole/Resources';

interface IEditRoleProps {
  roleDetail: IShopRole;
  handleEditRoleDrawer: () => void;
  handleEnableEditing: () => void;
}
const ReadRole = ({
  roleDetail,
  handleEditRoleDrawer,
  handleEnableEditing,
}: IEditRoleProps) => {
  return (
    <MKDrawer>
      <section className='px-2 py-1'>
        <div className='flex  flex-col'>
          <button className='self-end' onClick={handleEditRoleDrawer}>
            <IoCloseCircleOutline className='h-8 w-8 text-gray-500' />
          </button>
          <section className=' mb-3 flex items-center justify-between border-b'>
            <p>Rol {roleDetail.name}</p>
            <div className='flex'>
              <button
                className={`${styles.button} gap-2 px-2`}
                onClick={handleEnableEditing}
              >
                <CiEdit className='h-5 w-5 ' />
                Editar rol
              </button>
            </div>
          </section>
        </div>
        <div className=''>
          {roleDetail.resources.map((resource) => (
            <div>
              <RoleResources
                name={resource.name}
                resources={roleDetail.resources}
                initialPermissionValues={resource.permissions}
              />
            </div>
          ))}
        </div>
      </section>
    </MKDrawer>
  );
};

export default ReadRole;
