import { useEffect, useState } from 'react';
import useAdminConfig from '../../../hooks/admin/config/config.hooks';
import { useBanners } from '../../../hooks/banners/useBanners';
import SelectRegionCity from '../../Common/SelectRegion';
import Loader from '../../Layout/Loader';
import CreateBanner from './CreateBanner';
import SingleBannerCard from './SingleBannerCard';

const BannersList = () => {
  const { banners, fetchBanners, state } = useBanners();
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);

  const onChangeSelectedRegion = (region: string) => {
    setSelectedRegion(region);
    setSelectedCity(null);
  };
  const onChangeSelectedCity = (region: string) => {
    setSelectedCity(region);
  };
  // if (
  //   selectedCity &&
  //   state.action === 'create' &&
  //   !state.loading &&
  //   banners.length === 0
  // ) {
  //   fetchBanners(config.cityId);
  // }
  useEffect(() => {
    if (selectedCity && !banners) {
      fetchBanners(selectedCity);
    }
  }, [selectedCity, banners]);

  console.log('banners:', banners);

  if (state.loading) return <Loader />;
  return (
    <>
      <section className='mt-4 w-full px-2 md:px-6'>
        <div className='item-center mb-4 flex w-full justify-between rounded-md border-b-2 p-3 shadow-sm'>
          <div className='flex items-center px-2'>
            <h3 className='text-center text-lg'>Lista de banners</h3>
          </div>
          <CreateBanner />
        </div>
        <SelectRegionCity
          selectedRegion={selectedRegion}
          onChangeSelectedRegion={onChangeSelectedRegion}
          onChangeSelectedCity={onChangeSelectedCity}
        />

        {!selectedCity && !selectedRegion && (
          <p className='text-center text-sm text-gray-500'>
            Por favor selecciona una región y ciudad
          </p>
        )}

        <div className='mt-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3'>
          {banners &&
            Array.isArray(banners.data) &&
            banners.data.map((banner) => <SingleBannerCard banner={banner} />)}
        </div>
      </section>
    </>
  );
};

export default BannersList;
