import { useAdminDrivers } from '../../../hooks/admin/useAdminDrivers';
import EmptyState from '../../Common/EmptyState';
import { LuBadgeInfo } from 'react-icons/lu';
import MKButton from '../../UI/MKButton';
import MKCard from '../../UI/MKCard';
import {
  FaCheck,
  FaMapMarkedAlt,
  FaMotorcycle,
  FaRegTrashAlt,
} from 'react-icons/fa';
import { MdMail, MdOutlinePhoneAndroid } from 'react-icons/md';
import { toast } from 'react-toastify';
import { rejectDriverService } from '../../../services/drivers/driverStatus';

const PotentialDrivers = () => {
  const {
    potentialDrivers,
    approveDriver,
    refetchPotentialDrivers,
    refetchDrivers,
  } = useAdminDrivers();
  console.log('potentialDrivers:', potentialDrivers);
  const handleApproveDriver = async (id: string) => {
    const result = await approveDriver(id);
    if (!result.success) {
      toast.error('Ha ocurrido un error');
      return;
    }
    refetchPotentialDrivers();
    refetchDrivers();
  };

  const handleRejectPartner = async (driverId: string) => {
    const result = await rejectDriverService(driverId);
    if (!result.success) {
      toast.error('Ha ocurrido un error');
      return;
    }
    toast.success('Rechazado con éxito');
    refetchPotentialDrivers();
    refetchDrivers();
  };
  return (
    <section>
      <section className='my-1 rounded-sm border border-dashed border-orange-400 bg-orange-50 p-2 md:my-2 md:p-4'>
        <div className='flex flex-row items-center gap-2'>
          <LuBadgeInfo className=' h-8 w-8 self-center text-orange-300' />
          <section>
            <p className='text-lg font-bold'>
              Repartidores en etapa de revisión
            </p>
            <p className='text-sm text-slate-500'>
              Los repartidores en etapa de revisión aún no tienen acceso a la
              plataforma Merkoline. Puedes realizar las siguientes acciones para
              cada cuenta
            </p>
            <ul className='ml-6 list-disc text-sm text-slate-500'>
              <li>
                Aprobar repartidor: Al aprobar una repartidores le permitirles
                iniciar sesión y que administrar su cuenta.
              </li>
              <li>
                Rechazar repartidores: Al rechazar un repartidores se eliminará
                de esta lista y el acceso permanecerá denegado.
              </li>
            </ul>
          </section>
        </div>
      </section>
      {Array.isArray(potentialDrivers) && potentialDrivers.length === 0 && (
        <>
          <EmptyState width={140} height={140} />
          <p className='text-center text-base md:text-xl'>
            No tienes repartidores en espera
          </p>
        </>
      )}

      {Array.isArray(potentialDrivers) && potentialDrivers.length > 0 && (
        <section className='flex flex-col gap-2'>
          {potentialDrivers.map((driver) => (
            <MKButton
              variant='none'
              key={`potential-driver-${driver._id}`}
              className='w-full'
              // onClick={() => handleShowPartnerDetail(potentialPartner)}
            >
              <MKCard className='flex flex-col gap-4 text-left'>
                <section className='border-b pb-2'>
                  <section className='flex items-center justify-between '>
                    <p>
                      {driver.firstName} {driver.lastName}
                    </p>

                    <div>
                      <section className='flex gap-2'>
                        <MKButton
                          variant='outlined'
                          className='flex items-center px-2 py-1'
                          onClick={(e) => handleApproveDriver(driver._id)}
                        >
                          <FaCheck />
                          <p>Aprobar</p>
                        </MKButton>
                        <MKButton
                          variant='outlined'
                          className='flex items-center border-red-400 px-2 py-1 hover:bg-red-50'
                          onClick={() => handleRejectPartner(driver._id)}
                        >
                          <FaRegTrashAlt className='text-red-800' />
                          <p className='text-red-800'>Rechazar </p>
                        </MKButton>
                      </section>
                    </div>
                  </section>
                </section>

                <div className='flex flex-wrap gap-2 md:gap-8'>
                  {/* general info */}
                  <section className='flex w-full flex-col gap-2 sm:w-auto  md:flex-row md:gap-8'>
                    <section className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
                      <div className='rounded-lg bg-primary-100 p-2'>
                        <MdMail className='text-primary-500' />
                      </div>
                      <div>
                        <p className='text-left font-semibold'>Correo</p>
                        <p className='text-gray-700'>{driver.email}</p>
                      </div>
                    </section>
                    <section className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
                      <div className='rounded-lg bg-primary-100 p-2'>
                        <MdOutlinePhoneAndroid className='text-primary-500' />
                      </div>
                      <div>
                        <p className='font-semibold'>Teléfono</p>
                        <p className='text-gray-700'>{driver.phoneNumber}</p>
                      </div>
                    </section>
                    <div className='flex items-center gap-2  rounded-lg border border-dashed p-2 md:px-4'>
                      <div className='rounded-lg bg-primary-100 p-2'>
                        <FaMapMarkedAlt className='text-primary-500' />
                      </div>
                      <div>
                        <p className='font-semibold'>Ciudad</p>
                        <p className='text-gray-700'>{driver.city} </p>
                      </div>
                    </div>
                    <div className='flex items-center gap-2  rounded-lg border border-dashed p-2 md:px-4'>
                      <div className='rounded-lg bg-primary-100 p-2'>
                        <FaMotorcycle className='text-primary-500' />
                      </div>
                      <div>
                        <p className='font-semibold'>Vehículo</p>
                        <p className='text-gray-700'>
                          {driver.vehicleInfo.vehicleType}
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </MKCard>
            </MKButton>
          ))}
        </section>
      )}
    </section>
  );
};

export default PotentialDrivers;
