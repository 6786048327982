import { BannersList } from '../../../components/Admin/Banners';
import AdminSideBar from '../../../components/Admin/Layout/AdminSideBar';
import AdminHeader from '../../../components/Layout/AdminHeader';
import { ADMIN_NAVIGATION } from '../../../utils/constants/navigation';

const BannerPage = () => {
  return (
    <div>
      <div>
        <AdminHeader />
        <div className='flex w-full'>
          <div className='flex w-full items-start justify-between'>
            <div className='w-[80px] 800px:w-[330px]'>
              <AdminSideBar active={ADMIN_NAVIGATION.banners.id} />
            </div>
            <BannersList />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerPage;
