import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { twMerge } from 'tailwind-merge';
import { IoMdAdd } from 'react-icons/io';

import MKButton from '../UI/MKButton';
import Loader from '../Layout/Loader';
import SingleProduct from './Products/SingleProduct';
import CreateProduct from './Products/CreateProduct';
import { getAllProductsShop } from '../../redux/actions/product';
import {
  REQUEST_STATUS,
  SHOP_RESOURCE_ROLE_NAME,
} from '../../utils/constants/common';

import styles from '../../styles/styles';
import { useParams } from 'react-router-dom';
import useShopProducts from 'src/hooks/products/useShopProducts';

const AllProducts = () => {
  const { id } = useParams();

  const { refetchProducts, status, isLoading, clearState, create, products } =
    useShopProducts({
      shopId: id,
    });
  console.log('AllProducts:', products);
  const [enableCreateProduct, setEnableCreateProduct] = useState(false);
  const [productState, setProductsState] = useState(products);
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();

  const createPermission =
    seller?.isAdmin ||
    seller?.permission[SHOP_RESOURCE_ROLE_NAME.products]?.permissions?.create;
  const closeAddProductDrawer = () => setEnableCreateProduct(false);

  useEffect(() => {
    dispatch(getAllProductsShop(seller.shopId));
  }, []);

  // useEffect(() => {
  //   setProductsState(products);
  // }, [products]);
  console.log('status load:', status);
  return (
    <>
      <div className='item-center mb-4 flex w-full justify-between rounded-md border-b-2 p-3 shadow-sm'>
        <div className='flex items-center px-2'>
          <h3 className='text-center text-lg'>Mis productos</h3>
        </div>
        {createPermission && (
          <MKButton onClick={() => setEnableCreateProduct(true)} variant='none'>
            <section className={twMerge(styles.button, 'my-1 h-10  px-2')}>
              <IoMdAdd />
              Agregar producto
            </section>
          </MKButton>
        )}
      </div>
      {isLoading && status.type === REQUEST_STATUS.loadAll ? (
        <Loader />
      ) : (
        <div className='mb-12 flex flex-wrap gap-4'>
          {Array.isArray(products) &&
            products.map((product) => <SingleProduct product={product} />)}
        </div>
      )}
      {enableCreateProduct && (
        <CreateProduct handleCloseModal={closeAddProductDrawer} />
      )}
    </>
  );
};

export default AllProducts;
