import axios from 'axios';
import { server } from '../../server';

// refresh configuration --- admin
export const refreshConfiguration = () => async (dispatch) => {
  try {
    dispatch({
      type: 'RefreshConfiguration',
    });

    const { data } = await axios.get(`${server}/configuration/get-config`, {
      withCredentials: true,
    });

    if (!data || !data?.siteConfig) {
      return;
    }
    dispatch({
      type: 'LoadConfigSuccess',
      payload: data.siteConfig,
    });
  } catch (error) {
    dispatch({
      type: 'LoadConfigFail',
    });
  }
};
