import MKCard from '../../UI/MKCard';
import { Banner } from '../../../interfaces/admin/banners';
import { format } from 'date-fns';
import { FaCalendarTimes, FaCalendarCheck } from 'react-icons/fa';
import { useState } from 'react';
import MKButton from '../../UI/MKButton';
import BannerDetails from './BannerDetails';

interface ISingleBannerCardProps {
  banner: Banner;
  section?: string;
}

const SingleBannerCard = ({
  banner,
  section = 'banners-home',
}: ISingleBannerCardProps) => {
  return (
    <>
      <MKCard key={`${section}-${banner._id}`}>
        <MKButton className='w-full text-left' variant='text'>
          {banner.imageUrl && (
            <img
              src={banner.imageUrl}
              alt={banner.title}
              className='h-auto w-full'
              width={100}
            />
          )}

          <div className='flex flex-col'>
            <p className='text-lg font-semibold'>{banner.title}</p>
            <p className='text-sm text-gray-500'>{banner.description}</p>
          </div>

          {banner.type === 'link' && banner.targetUrl && (
            <div className='flex items-center justify-between'>
              <a href={banner.targetUrl} target='_blank' rel='noreferrer'>
                {banner.targetUrl}
              </a>
            </div>
          )}

          <div className='flex items-center justify-between'>
            {banner.startDate && (
              <div className='flex items-center gap-2'>
                <FaCalendarCheck />

                <div>
                  <p className='text-sm font-semibold'>Fecha inicio</p>
                  <p className='text-sm text-gray-500'>
                    {format(banner.startDate, 'dd/MM/yyyy')}
                  </p>
                </div>
              </div>
            )}
            {banner.endDate && (
              <div className='flex items-center gap-2'>
                <FaCalendarTimes />

                <div>
                  <p className='text-sm font-semibold'>Fecha fin</p>
                  <p className='text-sm text-gray-500'>
                    {format(banner.endDate, 'dd/MM/yyyy')}
                  </p>
                </div>
              </div>
            )}
          </div>
          <BannerDetails banner={banner} />
        </MKButton>
      </MKCard>
    </>
  );
};

export default SingleBannerCard;
