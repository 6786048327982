import DashboardCommonLayout from 'src/components/Layout/DashboardCommonLayout';
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import AllRefundOrders from '../../components/Shop/AllRefundOrders';

const ShopAllRefunds = () => {
  return (
    <>
      <DashboardCommonLayout>
        <div className='flex w-full justify-between'>
          <div className='w-[80px] 800px:w-[330px]'>
            <DashboardSideBar active={10} />
          </div>

          <AllRefundOrders />
        </div>
      </DashboardCommonLayout>
    </>
  );
};

export default ShopAllRefunds;
