import { Route } from 'react-router-dom';
import DriversLogin from '../../components/Drivers/DriversLogin';
import DriversSignUp from '../../components/Drivers/DriversSignUp';
import DriversListPage from '../../pages/Drivers/DriversList';
import ProtectedAdminRoute from '../ProtectedAdminRoute';
import ProtectedDriverRoute from '../ProtectedDriverRoute';
import DriversDashboardPage from '../../pages/Drivers/DriversDashboard';
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <Route path='/drivers-signin' element={<DriversLogin />} />,
  <Route path='/drivers-signup' element={<DriversSignUp />} />,
  <Route
    path='/admin-drivers-list'
    element={
      <ProtectedAdminRoute>
        <DriversListPage />
      </ProtectedAdminRoute>
    }
  />,
  <Route
    path='/drivers-dashboard'
    element={
      <ProtectedDriverRoute>
        <DriversDashboardPage />
      </ProtectedDriverRoute>
    }
  />,
];
