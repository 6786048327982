import DriverAvailability from './DriverAccount/Availability';
import OrdersTab from './DriverOrders/OrdersTab';

const DriversDashboard = () => {
  return (
    <section className='w-full px-2 md:px-8'>
      <div className=' w-full pt-5'>
        <p className='text-lg font-bold'>Bienvenido driver</p>
        <p className='text-gray-500'>Aqui puedes ver detalles de tu cuenta</p>
      </div>
      <OrdersTab />
      {/* <DriverAvailability /> */}
    </section>
  );
};

export default DriversDashboard;
