import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import CreateProduct from '../../components/Shop/CreateProduct';
import DashboardCommonLayout from '../../components/Layout/DashboardCommonLayout';

const ShopCreateProduct = () => {
  return (
    <DashboardCommonLayout>
      <div className='flex w-full justify-between'>
        <div className='w-[80px] 800px:w-[330px]'>
          <DashboardSideBar active={4} />
        </div>
        <div className='w-full'>
          <CreateProduct />
        </div>
      </div>
    </DashboardCommonLayout>
  );
};

export default ShopCreateProduct;
