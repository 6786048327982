import { toast } from 'react-toastify';
import { SubmitHandler, useForm } from 'react-hook-form';
import MKButton from '../../../../../UI/MKButton';
import MKDrawer from '../../../../../UI/MKDrawer';
import { IoMdCloseCircle } from 'react-icons/io';
import {
  ICurrencyAttributes,
  IEditCurrencyAttributes,
} from '../../../../../../interfaces/admin/configuration';
import {
  addNewCurrencyService,
  updateCurrencyService,
} from '../../../../../../services/admin/config';
import styles from '../../../../../../styles/styles';
import { useAppDispatch } from '../../../../../../hooks/common/redux.hooks';
import { refreshConfiguration } from '../../../../../../redux/actions/config';

interface IAddCurrencyDrawerProps {
  handleCloseDrawer: () => void;
  currencyData?: IEditCurrencyAttributes;
}
const AddCurrencyDrawer = ({
  handleCloseDrawer,
  currencyData,
}: IAddCurrencyDrawerProps) => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<ICurrencyAttributes>(
    currencyData
      ? {
          defaultValues: {
            currency_symbol: currencyData.currency_symbol,
            conversion_rate: currencyData.conversion_rate,
            name: currencyData.name,
            abbreviation: currencyData.abbreviation,
            active: currencyData.active,
          },
        }
      : {}
  );
  const handleSaveCurrency: SubmitHandler<ICurrencyAttributes> = async ({
    name,
    active,
    currency_symbol,
    conversion_rate,
    abbreviation,
  }) => {
    if (currencyData) {
      const result = await updateCurrencyService({
        name,
        active,
        currency_symbol,
        conversion_rate,
        abbreviation,
        id: currencyData.id,
      });
      if (result?.error) {
        toast.error(
          'Ha ocurrido un error, asegurate de tener los permisos necesarios'
        );
        return;
      }
      dispatch(refreshConfiguration());
      toast.success('Moneda actualizada con éxito');

      handleCloseDrawer();
      return;
    }

    const result = await addNewCurrencyService({
      name,
      active,
      currency_symbol,
      conversion_rate,
      abbreviation,
    });
    if (result?.error) {
      toast.error(
        'Ha ocurrido un error, asegurate de tener los permisos necesarios'
      );
      return;
    }
    dispatch(refreshConfiguration());

    toast.success('Moneda guardada con éxito');
    handleCloseDrawer();
  };
  return (
    <MKDrawer>
      <section className='p-2 md:p-4'>
        <section className='flex w-full items-center justify-between gap-2'>
          <p className='text-lg'>
            {currencyData ? 'Modificar' : 'Agregar nueva'} moneda
          </p>
          <MKButton onClick={handleCloseDrawer} variant='none'>
            <IoMdCloseCircle className='h-7 w-7' />
          </MKButton>
        </section>
        <form onSubmit={handleSubmit(handleSaveCurrency)}>
          <section className='py-2'>
            <label htmlFor='name'>Nombre de divisa</label>
            <input
              {...register('name')}
              placeholder='Ejemplo: US Dollar'
              className={styles.default_input}
            />
          </section>
          <section className='py-2'>
            <label htmlFor='abbreviation'>Abreviatura</label>
            <input
              {...register('abbreviation')}
              placeholder='Ejemplo: USD'
              className={styles.default_input}
            />
          </section>
          <section className='py-2'>
            <label htmlFor='currency_symbol'>Simbolo de moneda</label>
            <input
              {...register('currency_symbol')}
              placeholder='Ejemplo: $, €, ¥'
              className={styles.default_input}
            />
          </section>

          <section className='py-2'>
            <label htmlFor='conversion_rate'>
              Tasa de conversión de su moneda corporativa a esta nueva moneda
            </label>
            <input
              {...register('conversion_rate')}
              type='number'
              step='any'
              placeholder='Tasa de conversión'
              className={styles.default_input}
            />
            <p></p>
          </section>
          <section className='flex items-center gap-2 py-2'>
            <input
              className='h-4 w-4 accent-primary-200'
              {...register('active')}
              type='checkbox'
            />
            <label htmlFor='active'>Activo</label>
          </section>
          <MKButton className='px-4' type='submit'>
            Guardar
          </MKButton>
        </form>
      </section>
    </MKDrawer>
  );
};

export default AddCurrencyDrawer;
