export const DEFAULT_ERROR_MESSAGE = 'Ha ocurrido un error';

export const DATE_UNITS = {
  day: 86400,
  hour: 3600,
  minute: 60,
  second: 1, // un segundo == un segundo :D
};

export const CHECKOUT_STEPS = {
  delivery: {
    name: 'Entrega',
    number: 1,
    link: '/checkout/location',
  },
  payment: {
    name: 'Pago',
    number: 2,
    link: '/checkout/payment',
  },
  confirmation: {
    name: 'Confirmar',
    number: 3,
    link: '/checkout/confirm',
  },
};

export const DAYS = {
  mon: 'Lunes',
  tue: 'Martes',
  wed: 'Miercoles',
  thu: 'Jueves',
  fri: 'Viernes',
  sat: 'Sabado',
  sun: 'Domingo',
};

export const MONTH_NAMES = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Dicembre',
];

export const DAYS_ORDER = {
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
  sun: 7,
};

export const PAYMENT_METHODS = {
  cashOnDelivery: {
    type: 'Cash On Delivery',
    label: 'Pago en efectivo',
  },
  card: {
    type: 'Stripe',
    label: 'Stripe',
  },
  paypal: {
    type: 'Paypal',
    label: 'Paypal',
  },
};

export const SHIPPING_COST = 100;

export const ORDER_STATUS = {
  Processing: {
    label: 'En proceso',
    value: 'Processing',
  },
  'Transferred to delivery partner': {
    label: 'Transferido a socio de entrega',
    value: 'Transferred to delivery partner',
  },
  Shipping: {
    label: 'Enviado',
    value: 'Shipping',
  },
  Received: {
    label: 'Recibido',
    value: 'Received',
  },
  'On the way': {
    label: 'En camino',
    value: 'On the way',
  },
  Delivered: {
    label: 'Entregado',
    value: 'Delivered',
  },
};

export const SHOP_RESOURCE_ROLE_NAME = {
  orders: 'orders',
  products: 'products',
  staff: 'staff',
  roles: 'roles',
  shopConfig: 'shopConfig',
};

export const PERMISSION_NAME = {
  read: 'read',
  create: 'create',
  update: 'update',
  delete: 'delete',
};

export const INITIAL_PERMISSION_VALUES = {
  read: false,
  delete: false,
  update: false,
};

export const REQUEST_STATUS = {
  read: 'read',
  create: 'create',
  update: 'update',
  delete: 'delete',
  loading: 'loading',
  error: 'error',
  loadAll: 'loadAll',
};
