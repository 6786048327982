import { useEffect } from 'react';
import {
  findDriversByCityService,
  findDriversBySpecificOrderService,
} from '../../services/drivers/driverStatus';
import { useDriverStore } from '../../store/drivers';
import {
  assignDriverToOrderService,
  getDriverByIdService,
} from '../../services/drivers/driverOrders';

export const usePublicDrivers = (orderId?: string) => {
  const { drivers, driverState, setDriverError, setDriverLoading, setDrivers } =
    useDriverStore();

  const getDrivers = async () => {
    console.log('getDrivers fetch:', orderId);

    if (!orderId) return;
    setDriverLoading();
    const result = await getDriversBySpecificOrder(orderId);
    setDriverLoading(false);

    if (result.success) {
      setDrivers(result.drivers);
      return;
    }
    setDriverError();
  };

  const getDriversBySpecificOrder = async (orderId: string) => {
    return await findDriversBySpecificOrderService(orderId);
  };

  const assignDriverToOrder = async (orderId: string, driverId: string) => {
    return await assignDriverToOrderService(orderId, driverId);
  };

  const getDriverById = async (driverId: string) => {
    return await getDriverByIdService(driverId);
  };

  useEffect(() => {
    if (drivers === null) {
      getDrivers();
    }
  }, []);

  return {
    drivers,
    driverState,
    refetchDrivers: getDrivers,
    assignDriverToOrder,
    getDriverById,
    getDriversBySpecificOrder,
  };
};
