import { CreateBanner } from '../../interfaces/admin/banners';
import { createBannerService } from '../../services/banners/createBanner';
import { findAllBannersService } from '../../services/banners/fiandAllBanners';
import { useBannersStore } from '../../store/banners';

export const useBanners = () => {
  const { banners, state, createBanner, setAllBanners, clearState } =
    useBannersStore();

  const createBannerAction = async (banner: CreateBanner) => {
    createBanner(true);
    const response = await createBannerService(banner);
    createBanner(false);
    if (response.success) {
      setAllBanners(response.data);
      createBanner(false);
      return true;
    }
    return { success: false, message: response.message };
  };

  const findAllBannersAction = async (
    cityId: string,
    lastId?: string,
    limit: number = 30
  ) => {
    const response = await findAllBannersService(cityId, lastId, limit);
    if (response.success) {
      setAllBanners(response.data);
      return true;
    }
  };

  return {
    banners,
    state,
    clearState,
    createBanner: createBannerAction,
    fetchBanners: findAllBannersAction,
  };
};
