import { Link } from 'react-router-dom';
import styles from '../../styles/styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../UI/MKForm/Form';
import MKInput from '../UI/MKInput';
import { CreateDriverForm } from '../../interfaces/admin/driver';
import SelectRegionCity from '../Common/SelectRegion';
import { getCountryData } from 'countries-list';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { driverSignUpResolver } from './utils/formResolver';
import { ReactComponent as DriverIcon } from '../../Assests/driver01.svg';
import { signUpDriverService } from '../../services/drivers/auth';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../UI/MKAlertDialog';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../UI/MKSelect';
import MKCard from '../UI/MKCard';
import { uploadDriverProfile } from '../../services/drivers/driverStatus';
import { Avatar, AvatarFallback, AvatarImage } from '../UI/MKAvatar';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

const DriversSignUp = () => {
  const countryCode = `+${getCountryData('NI').phone}`;

  const form = useForm<CreateDriverForm>({
    resolver: zodResolver(driverSignUpResolver),
  });
  const [showSuccessDriverAlert, setShowSuccessDriverAlert] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedCity, setselectedCity] = useState(null);
  const [file, setFile] = useState({ preview: '', file: '' });

  function handleChangeProfile(e: any) {
    if (typeof e?.target?.files === 'object') {
      setFile({
        preview: URL.createObjectURL(e.target.files[0]),
        file: e.target.files[0],
      });
    }
  }

  const onChangeSelectedRegion = (region: string) => {
    setSelectedRegion(region);
    setselectedCity(null);
  };
  const onChangeSelectedCity = (region: string) => {
    setselectedCity(region);
  };
  console.log('form', form.formState.errors);

  const onSubmit: SubmitHandler<CreateDriverForm> = async ({
    firstName,
    lastName,
    phoneNumber,
    email,
    password,
    vehicleInfo,
  }) => {
    if (!file || file.file === '') {
      toast.error('Debes subir una foto reciente');
      return;
    }
    if (selectedRegion === '') {
      toast.error('Seleccione su departamento');
      return;
    }

    if (selectedCity === '' || selectedCity === null) {
      toast.error('Seleccione su ciudad');
      return;
    }

    const formData = new FormData();
    formData.append('image', file.file);

    const uploadPhotoRes = await uploadDriverProfile(formData);
    if (!uploadPhotoRes.success || !uploadPhotoRes.data) {
      toast.error(
        'Ha ocurrido un error al subir tu imagen, intentalo nuevamente'
      );
      return;
    }

    const { success, message, data } = await signUpDriverService({
      firstName,
      lastName,
      password,
      email,
      tokenImage: uploadPhotoRes.data,
      phoneNumber: `${phoneNumber}`,
      countryCode: countryCode,
      regionId: selectedRegion,
      city: selectedCity,
      contactInfo: {
        address: '',
      },
      vehicleInfo,
    });
    if (!success || !data) {
      toast.error(message);
      return;
    }
    setSelectedRegion(null);
    setselectedCity(null);
    setShowSuccessDriverAlert(true);
    setFile({ preview: '', file: '' });
  };

  useEffect(() => {
    if (showSuccessDriverAlert) {
      form.reset({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        password: '',
        email: '',
        vehicleInfo: {
          vehicleType: '',
        },
      });
    }
  }, [showSuccessDriverAlert]);

  return (
    <div>
      <div className='mx-auto h-screen w-11/12 flex-1 overflow-auto bg-white p-2 text-lg md:p-4 xl:w-3/4'>
        <div className='flex h-full w-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
          <section className='flex h-full w-full flex-col items-center md:flex-row md:items-start'>
            <section className='flex w-full flex-col items-center gap-2 md:w-2/5'>
              <DriverIcon className='h-28 md:h-48' />
              <div className='mt-2 w-full self-start text-center'>
                <h2 className='text-xl font-bold'>Registrate </h2>
                <p>Unete a la red de repartidores de Merkoline</p>
              </div>
            </section>

            <MKCard className='w-full overflow-auto scroll-auto rounded-md border  shadow '>
              <div className=' sm:mx-auto sm:w-full sm:max-w-xl'>
                <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
                  Crear cuenta driver
                </h2>
              </div>
              <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-xl md:h-2/3'>
                <div className='bg-white px-4 py-4 sm:rounded-lg sm:px-10'>
                  <section className='mb-2'>
                    <p>Foto</p>
                    {file.preview === '' ? (
                      <MKInput
                        multiple={false}
                        type='file'
                        onChange={handleChangeProfile}
                      />
                    ) : (
                      <section className='flex items-center gap-2'>
                        <Avatar>
                          <AvatarImage src={file.preview} />
                          <AvatarFallback>CN</AvatarFallback>
                        </Avatar>
                        <section>
                          <label
                            className='text-sm text-gray-500'
                            htmlFor='upload-profile'
                          >
                            Cambiar
                          </label>
                          <input
                            className='hidden'
                            type='file'
                            multiple={false}
                            id='upload-profile'
                            onChange={handleChangeProfile}
                          />
                        </section>
                      </section>
                    )}
                  </section>

                  <Form {...form}>
                    <form
                      onSubmit={form.handleSubmit(onSubmit)}
                      className='w-full space-y-4'
                    >
                      <div>
                        <p className='text-sm'>
                          Por favor selecciona tu departamento y ciudad
                          <span className='text-red-500'>*</span>
                        </p>
                        <SelectRegionCity
                          selectedRegion={selectedRegion}
                          onChangeSelectedRegion={onChangeSelectedRegion}
                          onChangeSelectedCity={onChangeSelectedCity}
                        />
                      </div>
                      <FormField
                        control={form.control}
                        name='firstName'
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              Nombres
                              <span className='text-red-500'>*</span>
                            </FormLabel>
                            <FormControl>
                              <MKInput
                                placeholder='Ingresa tu nombre'
                                {...field}
                                className={styles.default_input}
                              />
                            </FormControl>

                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name='lastName'
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              Apellidos <span className='text-red-500'>*</span>
                            </FormLabel>
                            <FormControl>
                              <MKInput
                                placeholder='Ingresa tus apellidos'
                                {...field}
                                type='text'
                                className={styles.default_input}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name='password'
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              Contraseña
                              <span className='text-red-500'>*</span>
                            </FormLabel>
                            <FormControl>
                              <MKInput
                                type={showPassword ? 'text' : 'password'}
                                placeholder='Ingresa tu contraseña'
                                {...field}
                                className={styles.default_input}
                                endIcon={
                                  showPassword ? (
                                    <AiOutlineEyeInvisible
                                      className='cursor-pointer'
                                      onClick={() =>
                                        setShowPassword((prev) => !prev)
                                      }
                                    />
                                  ) : (
                                    <AiOutlineEye
                                      className='cursor-pointer'
                                      onClick={() =>
                                        setShowPassword((prev) => !prev)
                                      }
                                    />
                                  )
                                }
                              />
                            </FormControl>

                            <FormMessage className='text-sm text-red-500' />
                          </FormItem>
                        )}
                      />

                      <FormField
                        control={form.control}
                        name='email'
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>
                              Email
                              <span className='text-red-500'>*</span>
                            </FormLabel>
                            <FormControl>
                              <MKInput
                                placeholder='Ingresa tu email'
                                {...field}
                                type='email'
                                className={styles.default_input}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <p className='text-sm'>
                        Teléfono
                        <span className='text-red-500'>*</span>
                      </p>
                      <section className='flex flex-1 items-center gap-2'>
                        <p className='text-sm'>{countryCode}</p>
                        <FormField
                          control={form.control}
                          name='phoneNumber'
                          render={({ field }) => (
                            <FormItem className='flex-1'>
                              <FormControl>
                                <MKInput
                                  placeholder='Ingresa tu número'
                                  {...field}
                                  type='text'
                                  className={`${styles.default_input} flex-1`}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </section>
                      <FormField
                        control={form.control}
                        name='vehicleInfo.vehicleType'
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Vehículo</FormLabel>
                            <Select
                              onValueChange={field.onChange}
                              defaultValue={field.value}
                            >
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder='Selecciona tu vehículo' />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                <SelectItem value='Moto'>Moto</SelectItem>
                                <SelectItem value='Automóvil'>
                                  Automóvil
                                </SelectItem>
                              </SelectContent>
                            </Select>
                          </FormItem>
                        )}
                      />

                      <div>
                        <button
                          type='submit'
                          className='group relative flex h-[40px] w-full justify-center rounded-md border border-transparent bg-primary-200 px-4 py-2 text-sm font-medium text-white hover:bg-primary-500'
                        >
                          Registrarme
                        </button>
                      </div>
                      <div className={`${styles.noramlFlex} w-full`}>
                        <h4>¿Ya tienes una cuenta?</h4>
                        <Link
                          to='/drivers-signin'
                          className='pl-2 text-primary-200'
                        >
                          Iniciar sesión
                        </Link>
                      </div>
                    </form>
                  </Form>
                </div>
              </div>
            </MKCard>
          </section>
        </div>
      </div>
      <AlertDialog open={showSuccessDriverAlert}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Gracias por registrarte en Merkoline
            </AlertDialogTitle>
            <AlertDialogDescription>
              Estaremos revisando tu aplicación y guiandote en los siguientes
              pasos
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction onClick={() => setShowSuccessDriverAlert(false)}>
              Continuar
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default DriversSignUp;
