import { ICreateCategory } from '../../interfaces/category/categoryModel';
import {
  createCategoryService,
  updateCategoryService,
  fetchAllCategoriesService,
} from '../../services/category/category';
import { useCategoriesStore } from '../../store/categories';

/**
 *
 * @description
 * Custom hook to handle categories, include fetch, save in store, refetch
 */
const useCategories = (lazyLoad = false) => {
  const { categories, setCategories, setError, setLoading, state, clearState } =
    useCategoriesStore();

  const refetch = async () => {
    fetchCategories();
  };

  const fetchCategories = async () => {
    setLoading();
    const result = await fetchAllCategoriesService();
    // @ts-ignore
    if (result.error) {
      // @ts-ignore
      setError(result.message || '');
    }
    // @ts-ignore
    if (!result.error && result.status === 201 && result.data.success) {
      // @ts-ignore
      setCategories(result.data.allCategories);
      return;
    }
  };

  const create = async (category: ICreateCategory) => {
    setLoading();
    const result = await createCategoryService(category);
    if (result.error) {
      setError();
      return {
        error: true,
        message: result.message,
      };
    }
    setLoading(false);
    return result;
  };
  const update = async (id: string, category: Partial<ICreateCategory>) => {
    setLoading();
    const result = await updateCategoryService(id, category);

    if (result.error) {
      setError();
      return {
        error: true,
        message: result.message,
      };
    }
    setLoading(false);
    return result;
  };

  if (categories === null && !state.loading) {
    fetchCategories();
  }

  return { categories, state, refetch, create, clearState, update };
};

export default useCategories;
