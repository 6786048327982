import axios from 'axios';
import { FiPackage, FiShoppingBag } from 'react-icons/fi';
import { RxDashboard } from 'react-icons/rx';
import { CiSettings } from 'react-icons/ci';
import { Link } from 'react-router-dom';
import { FaUsersCog } from 'react-icons/fa';
import { MdOutlineSecurity } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { AiOutlineLogout } from 'react-icons/ai';
import { HiOutlineReceiptRefund } from 'react-icons/hi';

import { server } from '../../../server';
import { SHOP_RESOURCE_ROLE_NAME } from '../../../utils/constants/common';

const DashboardSideBar = ({ active }) => {
  const { seller } = useSelector((state) => state.seller);
  const logoutHandler = () => {
    axios.get(`${server}/shop/logout`, {
      withCredentials: true,
    });
    window.location.reload();
  };

  return (
    <div className='sticky left-0 top-0 z-10 mt-2 h-full w-full overflow-y-scroll bg-white shadow-sm'>
      {/* single item */}
      <div className='flex w-full items-center p-4'>
        <Link to='/dashboard' className='flex w-full items-center'>
          <RxDashboard
            size={30}
            className={`${
              active === 1 ? 'text-primary-200' : 'text-slate-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === 1 ? 'text-primary-200' : 'text-slate-500'
            }`}
          >
            Dashboard
          </h5>
        </Link>
      </div>

      {(seller.isAdmin ||
        seller.permission[SHOP_RESOURCE_ROLE_NAME.orders]?.permissions?.read ||
        seller.permission[SHOP_RESOURCE_ROLE_NAME.orders]?.permissions
          ?.update) && (
        <div className='flex w-full items-center p-4'>
          <Link to='/dashboard-orders' className='flex w-full items-center'>
            <FiShoppingBag
              size={30}
              className={`${
                active === 2 ? 'text-primary-200' : 'text-slate-500'
              }`}
            />
            <h5
              className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
                active === 2 ? 'text-primary-200' : 'text-slate-500'
              }`}
            >
              Mis Pedidos
            </h5>
          </Link>
        </div>
      )}

      {(seller.isAdmin ||
        seller.permission[SHOP_RESOURCE_ROLE_NAME.products]?.permissions
          ?.read ||
        seller.permission[SHOP_RESOURCE_ROLE_NAME.products]?.permissions
          ?.update) && (
        <div className='flex w-full items-center p-4'>
          <Link to='/dashboard-products' className='flex w-full items-center'>
            <FiPackage
              size={30}
              className={`${
                active === 3 ? 'text-primary-200' : 'text-slate-500'
              }`}
            />
            <h5
              className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
                active === 3 ? 'text-primary-200' : 'text-slate-500'
              }`}
            >
              Mis Productos
            </h5>
          </Link>
        </div>
      )}

      {/* <div className="w-full flex items-center p-4">
        <Link to="/dashboard-messages" className="w-full flex items-center">
          <BiMessageSquareDetail
            size={30}
            color={`${active === 8 ? "crimson" : "#555"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 8 ? "text-[crimson]" : "text-[#555]"
            }`}
          >
            Shop Inbox
          </h5>
        </Link>
      </div> */}

      {/* <div className='flex w-full items-center p-4'>
        <Link to='/dashboard-coupouns' className='flex w-full items-center'>
          <AiOutlineGift
            size={30}
            className={`${
              active === 9 ? 'text-primary-200' : 'text-slate-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === 9 ? 'text-primary-200' : 'text-slate-500'
            }`}
          >
            Codigos de descuento
          </h5>
        </Link>
      </div> */}

      <div className='flex w-full items-center p-4'>
        <Link to='/dashboard-refunds' className='flex w-full items-center'>
          <HiOutlineReceiptRefund
            size={30}
            className={`${
              active === 10 ? 'text-primary-200' : 'text-slate-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === 10 ? 'text-primary-200' : 'text-slate-500'
            }`}
          >
            Reembolsos
          </h5>
        </Link>
      </div>
      {(seller.isAdmin ||
        seller.permission[SHOP_RESOURCE_ROLE_NAME.staff]?.permissions?.read ||
        seller.permission[SHOP_RESOURCE_ROLE_NAME.staff]?.permissions
          ?.update) && (
        <div className='flex w-full items-center p-4'>
          <Link to='/dashboard-my-staff' className='flex w-full items-center'>
            <FaUsersCog
              size={30}
              className={`${
                active === 12 ? 'text-primary-200' : 'text-slate-500'
              }`}
            />
            <h5
              className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
                active === 12 ? 'text-primary-200' : 'text-slate-500'
              }`}
            >
              Staff
            </h5>
          </Link>
        </div>
      )}

      {(seller.isAdmin ||
        seller.permission[SHOP_RESOURCE_ROLE_NAME.roles]?.permissions?.read ||
        seller.permission[SHOP_RESOURCE_ROLE_NAME.roles]?.permissions
          ?.update) && (
        <div className='flex w-full items-center p-4'>
          <Link to='/dashboard-roles' className='flex w-full items-center'>
            <MdOutlineSecurity
              size={30}
              className={`${
                active === 13 ? 'text-primary-200' : 'text-slate-500'
              }`}
            />
            <h5
              className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
                active === 13 ? 'text-primary-200' : 'text-slate-500'
              }`}
            >
              Roles
            </h5>
          </Link>
        </div>
      )}

      <div className='flex w-full items-center p-4'>
        <Link
          to={`/shop/${seller.shopId}`}
          className='flex w-full items-center'
        >
          <CiSettings
            size={30}
            className={`${
              active === 11 ? 'text-primary-200' : 'text-slate-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === 11 ? 'text-primary-200' : 'text-slate-500'
            }`}
          >
            Configuración
          </h5>
        </Link>
      </div>
      <div
        className='flex w-full cursor-pointer items-center p-4 text-slate-500'
        onClick={logoutHandler}
      >
        <AiOutlineLogout size={30} color={active === 8 ? 'red' : ''} />
        <span
          className={`pl-3 ${
            active === 8 ? 'text-[red]' : ''
          } hidden 800px:block`}
        >
          Cerrar sesión
        </span>
      </div>
    </div>
  );
};

export default DashboardSideBar;
