import { useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../UI/MKTabs';
import ActiveDrivers from './ActiveDrivers';
import PotentialDrivers from './PotentialDrivers';

const DriversTabs = () => {
  const [activeTab, setActiveTab] = useState('activeDrivers');
  const handleTabChange = (name) => {
    setActiveTab(name);
  };
  return (
    <>
      <div className='flex w-full justify-center px-2 pt-5 md:px-4'>
        <Tabs
          defaultValue='activeDrivers'
          value={activeTab}
          onValueChange={(tabName) => handleTabChange(tabName)}
          className='w-full'
        >
          <TabsList className='grid w-full grid-cols-2 bg-gray-100 p-0'>
            <TabsTrigger
              className='data-[state=active]:border-b-2 data-[state=active]:border-primary-200'
              value='activeDrivers'
            >
              <p className='text-base font-semibold'>Repartidores</p>
            </TabsTrigger>
            <TabsTrigger
              className='data-[state=active]:border-b-2 data-[state=active]:border-primary-200'
              value='potentialDrivers'
            >
              <p className='text-base font-semibold'>Repartidores en espera</p>
            </TabsTrigger>
          </TabsList>

          <TabsContent className='mt-8' value='activeDrivers'>
            <ActiveDrivers />
          </TabsContent>
          <TabsContent value='potentialDrivers'>
            <PotentialDrivers />
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
};

export default DriversTabs;
