import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MdArrowBackIosNew, MdOutlineHideImage } from 'react-icons/md';
import { HiPencilAlt } from 'react-icons/hi';

import Loader from '../../Layout/Loader';
import AdditionalDetails from './AdditionalDetails';
import { BsShop } from 'react-icons/bs';
import { useAppSelector } from '../../../hooks/common/redux.hooks';

const ShopInfo = ({ isOwner, data }) => {
  const { admin } = useAppSelector((state) => state.admin);
  const { seller } = useAppSelector((state) => state.seller);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div>
            <div className='flex w-full flex-col justify-between px-6 py-2 md:flex-row md:px-12'>
              <div className='item-center flex'>
                <div className='mr-4 self-center'>
                  <button
                    className='inline-block max-h-min p-1'
                    onClick={() =>
                      navigate(admin ? '/admin-sellers' : '/dashboard')
                    }
                  >
                    <MdArrowBackIosNew className='h-6 w-6 text-gray-400' />
                  </button>
                </div>
                {data?.avatar?.url ? (
                  <img
                    src={`${data?.avatar?.url}`}
                    alt=''
                    className='h-[4rem] w-[4rem] rounded-full object-cover'
                  />
                ) : (
                  <section className='justify-cente flex h-[4rem] w-[4rem] items-center'>
                    <MdOutlineHideImage className='h-8 w-8 text-gray-500' />
                  </section>
                )}
                <div className='self-center px-2'>
                  <h3 className='self-center text-center text-lg'>
                    {data.name}
                  </h3>
                </div>
              </div>
              <section className='w-ful flex items-center gap-2'>
                <AdditionalDetails shop={data} />
                {isOwner && (
                  <>
                    <Link to='/shop-settings'>
                      <div
                        className={`flex items-center rounded-md border border-primary-200 px-2 py-2`}
                      >
                        <HiPencilAlt className='text-primary-200' />
                        <p className='text-primary-200'>Editar tienda</p>
                      </div>
                    </Link>
                  </>
                )}
              </section>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ShopInfo;
