import { BiMessageSquareDetail } from 'react-icons/bi';
import { FiPackage, FiShoppingBag } from 'react-icons/fi';
import { FaRegUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { useDriverSessionStore } from '../../../store/driversSession';

const DriverHeader = () => {
  const { driverSession } = useDriverSessionStore();

  return (
    <div className='sticky left-0 top-0 z-30 flex h-[80px] w-full items-center justify-between bg-white px-4 shadow'>
      <div>
        <Link to='/'>
          <img
            className='h20 w-20'
            src='/assets/mercoline-logo-300x300.jpeg'
            alt=''
          />
        </Link>
      </div>
      <div className='flex items-center'>
        <div className='mr-4 flex items-center gap-2'>
          {driverSession?.profileImage ? (
            <img
              src={`${driverSession?.profileImage}`}
              alt=''
              className='h-[50px] w-[50px] rounded-full object-cover'
            />
          ) : (
            <>
              <FaRegUser color='#555' className='h-6 w-6 ' />
            </>
          )}
          <p>
            {driverSession.firstName} {driverSession.lastName}
          </p>
        </div>
      </div>
    </div>
  );
};

export default DriverHeader;
