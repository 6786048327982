import { z } from 'zod';

export const driverSignUpResolver = z.object({
  firstName: z
    .string({
      required_error: 'El nombre es requerido ',
    })
    .min(1),
  lastName: z
    .string({
      required_error: 'El apellido es requerido ',
    })
    .min(1),
  password: z
    .string({
      required_error:
        'La contraseña debe tener al menos 6 carácteres y contener una mayúscula, una minúscula y un número',
    })
    .refine(
      (value) => /^(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{6,}$/.test(value ?? ''),
      'La contraseña debe tener al menos 6 carácteres y contener una mayúscula, una minúscula y un número'
    ),
  phoneNumber: z
    .string({
      required_error: 'El teléfono es requerido ',
    })
    .min(6),

  email: z
    .string({
      required_error: 'El email es requerido ',
    })
    .min(1),
  vehicleInfo: z.object({
    vehicleType: z.string({
      required_error: 'El vehículo es requerido ',
    }),
  }),
});
