import { useEffect, useState } from 'react';
import MKDrawer from '../../../../components/UI/MKDrawer';
import { IStaffResult } from '../../../../datatypes/interfaces/staff';
import { IoCloseCircleOutline, IoShieldCheckmark } from 'react-icons/io5';
import styles from '../../../../styles/styles';
import { CiEdit } from 'react-icons/ci';
import { findStaffRoles } from '../../../../services/staff/staff';
import { FaCheck } from 'react-icons/fa';

interface IStaffDetailsProps {
  staff: IStaffResult;
  handleCloseStaffDrawer: () => void;
  handleEnableEditing?: () => void;
}
const StaffDetails = ({
  staff,
  handleCloseStaffDrawer,
  handleEnableEditing = () => {},
}: IStaffDetailsProps) => {
  const [roles, setRoles] = useState([]);
  const handleGetStaffRoles = async () => {
    const rolesResult = await findStaffRoles(staff._id);
    console.log('rolesResult:', rolesResult);

    if (rolesResult?.staffRoles?.roles) {
      setRoles(rolesResult?.staffRoles?.roles);
    }
  };

  useEffect(() => {
    handleGetStaffRoles();
  }, []);

  return (
    <MKDrawer>
      <section className='px-2 py-1'>
        <div className='flex  flex-col'>
          <button className='self-end' onClick={handleCloseStaffDrawer}>
            <IoCloseCircleOutline className='h-8 w-8 text-gray-500' />
          </button>
          <section className=' mb-3 flex items-center justify-between border-b'>
            <p>Detalle de Empleado</p>
            <div className='flex'>
              <button
                className={`${styles.button} gap-2 px-2`}
                onClick={handleEnableEditing}
              >
                <CiEdit className='h-5 w-5 ' />
                Editar
              </button>
            </div>
          </section>
        </div>
        <div className=''>
          <section className='my-2'>
            <p className='font-bold'>
              Nombre:{' '}
              <span className='text-primary-200'>
                {staff.name} {staff.lastName}
              </span>
            </p>
            <p className='font-bold'>Correo: {staff.email}</p>
          </section>
          {roles.length > 0 && (
            <>
              <p className='my-1 font-bold'>Roles</p>

              {roles.map((role) => (
                <section
                  className={`my-2 flex items-center gap-4 rounded-xl border-2 border-primary-100
                  p-2`}
                >
                  <section className='w-full '>
                    <div
                      className={`flex w-full items-center justify-between py-2`}
                    >
                      <section className='flex items-center'>
                        <div className='flex h-8 w-8 items-center justify-center rounded-full bg-gray-300'>
                          <FaCheck className='text-primary-500' />
                        </div>

                        <div>
                          <p className='mx-4 text-base font-bold'>
                            {role.name}
                          </p>
                        </div>
                      </section>
                      <IoShieldCheckmark className='h-6 w-6 text-primary-500' />
                    </div>
                  </section>
                </section>
              ))}
            </>
          )}
        </div>
      </section>
    </MKDrawer>
  );
};

export default StaffDetails;
