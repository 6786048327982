import { CiSaveDown2 } from 'react-icons/ci';
import MKDrawer from '../../../../components/UI/MKDrawer';
import { IoCloseCircleOutline } from 'react-icons/io5';
import styles from '../../../../styles/styles';
import { useState } from 'react';
import StaffRoles from '../CreateStaff/StaffRoles';
import { FaCheck } from 'react-icons/fa';
import { updateStaffService } from '../../../../services/staff/staff';
import { toast } from 'react-toastify';

interface IUpdateStaffProps {
  staff: any;
  handleCloseStaffDrawer: () => void;
  getMyStaff: () => Promise<void>;
}
const UpdateStaff = ({
  staff,
  handleCloseStaffDrawer,
  getMyStaff,
}: IUpdateStaffProps) => {
  const [name, setName] = useState(staff.name);
  const [lastName, setLastName] = useState(staff.lastName);
  const [isActive, setIsActive] = useState(staff.isActive);
  const [email, setEmail] = useState(staff.email);
  const [selectedRoles, setSelectedRoles] = useState(staff.roles ?? []);
  const handleChangeSelected = (roleId: string) => {
    setSelectedRoles((prevState) => [...prevState, roleId]);
  };

  const handleRemoveRoleFromSelected = (roleToRemoveId: string) => {
    const filterRoles = selectedRoles.filter((role) => role !== roleToRemoveId);
    setSelectedRoles(filterRoles);
  };

  const handleUpdateStaff = async () => {
    const staffChange = {
      _id: staff._id,
      name,
      lastName,
      email,
      roles: selectedRoles,
      isActive,
    };
    const result = await updateStaffService(staffChange);
    console.log('result update:', result);

    if (result.success) {
      getMyStaff();
      toast.success('Cambios realizados con éxito');
      handleCloseStaffDrawer();
      return;
    }
    toast.error(
      'Ha ocurrido un error, asegurate que tienes los permisos adecuados'
    );
  };

  return (
    <MKDrawer>
      <section className='px-2 py-1'>
        <div className='flex  flex-col'>
          <button className='self-end' onClick={handleCloseStaffDrawer}>
            <IoCloseCircleOutline className='h-8 w-8 text-gray-500' />
          </button>
          <section className=' mb-3 flex items-center justify-between border-b'>
            <p>Actualizar Empleado</p>
            <div className='flex'>
              <button
                onClick={handleUpdateStaff}
                className={`${styles.button} gap-2 px-2`}
              >
                <CiSaveDown2 className='h-5 w-5 ' />
                Guardar cambios
              </button>
            </div>
          </section>
        </div>
        <div className=''>
          <section className='my-2'>
            <div>
              <label className='pb-2'>
                Nombre <span className='text-red-500'>*</span>
              </label>
              <input
                type='text'
                name='name'
                value={name}
                className='mt-2 block h-[35px] w-full appearance-none rounded-[3px] border border-gray-300 px-3 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
                onChange={(e) => setName(e.target.value)}
                placeholder='Nombre...'
              />
            </div>
            <div className='my-2'>
              <label className='pb-2'>
                Apellido <span className='text-red-500'>*</span>
              </label>
              <input
                type='text'
                name='name'
                value={lastName}
                className='mt-2 block h-[35px] w-full appearance-none rounded-[3px] border border-gray-300 px-3 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
                onChange={(e) => setLastName(e.target.value)}
                placeholder='Nombre...'
              />
            </div>
            <div className='my-2'>
              <label className='pb-2'>
                Correo <span className='text-red-500'>*</span>
              </label>
              <input
                type='email'
                name='name'
                value={email}
                className='mt-2 block h-[35px] w-full appearance-none rounded-[3px] border border-gray-300 px-3 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Correo'
              />
            </div>
            <div className='my-3 flex items-center'>
              <button
                onClick={() => setIsActive((prev) => !prev)}
                className='flex h-6 w-6 items-center justify-center rounded-sm border border-primary-200'
              >
                {isActive && <FaCheck className='text-primary-200' />}
              </button>

              <p className='ml-2 '>Empleado activo</p>
            </div>
            <div className='my-2 w-full '>
              <p className='pb-2'>
                Roles <span className='text-red-500'>*</span>
              </p>
              <StaffRoles
                selectedRoles={selectedRoles}
                handleChangeSelected={handleChangeSelected}
                handleRemoveRoleFromSelected={handleRemoveRoleFromSelected}
              />
            </div>
          </section>
        </div>
      </section>
    </MKDrawer>
  );
};

export default UpdateStaff;
