import axios from 'axios';
import { serverV3 } from '../../server';
import { FindDriverResponse } from '../../interfaces/driver/driverDashboard';

export const findDriverOrdersService = async (driverId: string) => {
  try {
    const { data } = await axios.get(
      `${serverV3}/driver/get-all-order/${driverId}`,
      {
        withCredentials: true,
      }
    );

    console.log('data findDriverOrdersService:', data);

    return {
      success: data.success,
      orders: data.orders,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const assignDriverToOrderService = async (
  orderId: string,
  driverId: string
) => {
  try {
    const { data } = await axios.post(
      `${serverV3}/driver/assign-to-shipment`,
      { orderId, driverId },
      { withCredentials: true }
    );
    console.log('data assignDriverToOrderService:', data);

    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};

export const getDriverByIdService = async (driverId: string) => {
  try {
    const { data } = await axios.get<FindDriverResponse>(
      `${serverV3}/driver/${driverId}/find`,
      {
        withCredentials: true,
      }
    );

    console.log('data getDriverByIdService:', data);
    return {
      success: true,
      driver: data.driver,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};
