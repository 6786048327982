import { useEffect } from 'react';
import {
  approveDriverService,
  findApprovedDriversService,
  findPotentialDriversService,
} from '../../services/drivers/driverStatus';
import { useDriverStore } from '../../store/drivers';

export const useAdminDrivers = (autoLoad = true) => {
  const {
    drivers,
    driverState,
    potentialDrivers,
    potentialDriverState,
    setDriverError,
    setDriverLoading,
    setDrivers,
    setPotentialDrivers,
    setPotentialDriversError,
    setPotentialDriversLoading,
  } = useDriverStore();

  const getDrivers = async () => {
    setDriverLoading();
    const result = await findApprovedDriversService();
    setDriverLoading(false);

    if (result.success) {
      setDrivers(result.drivers);
      return;
    }
    setDriverError();
  };

  const getPotentialDrivers = async () => {
    setPotentialDriversLoading();
    const result = await findPotentialDriversService();
    setPotentialDriversLoading(false);
    console.log('getPotentialDrivers result:', result);

    if (result.success) {
      setPotentialDrivers(result.potentialDrivers);
      return;
    }
    setPotentialDriversError();
  };
  const approveDriver = async (id: string) => {
    const result = await approveDriverService(id);
    return result;
  };

  useEffect(() => {
    if (drivers === null && !driverState.loading && autoLoad) {
      getDrivers();
    }
    if (
      potentialDrivers === null &&
      !potentialDriverState.loading &&
      autoLoad
    ) {
      getPotentialDrivers();
    }
  }, []);

  return {
    drivers,
    potentialDrivers,
    approveDriver,
    refetchPotentialDrivers: getPotentialDrivers,
    refetchDrivers: getDrivers,
  };
};
