import axios from 'axios';
import { serverV3 } from '../../server';
import { DEFAULT_ERROR_MESSAGE } from '../../utils/constants/common';
import { CreateDriverForm, LoginDriver } from '../../interfaces/admin/driver';

export const signUpDriverService = async (props: CreateDriverForm) => {
  try {
    const { data } = await axios.post(
      `${serverV3}/driver/create-driver`,
      props
    );
    return {
      success: true,
      data,
    };
  } catch (error: any) {
    console.log('error:', error);
    const requestError = error?.response?.data?.message || error?.message;
    return {
      success: false,
      message: requestError || DEFAULT_ERROR_MESSAGE,
    };
  }
};

export const signInDriverService = async (props: LoginDriver) => {
  try {
    const { data } = await axios.post(`${serverV3}/driver/login`, props);
    console.log('Success login:', data);

    return {
      success: true,
      data,
    };
  } catch (error) {
    console.log('error:', error);
    return {
      success: false,
      message: error?.response?.data?.message || error?.message,
    };
  }
};
