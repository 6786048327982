import MKDrawer from '../../UI/MKDrawer';
import { Driver } from '../../../interfaces/admin/driver';
import { IoClose } from 'react-icons/io5';
import { MdDeleteOutline, MdMail, MdOutlinePhoneAndroid } from 'react-icons/md';
import { FaMapMarkedAlt, FaMotorcycle } from 'react-icons/fa';
import { GoDotFill } from 'react-icons/go';
import MKButton from '../../UI/MKButton';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../UI/MKAlertDialog';
import { toggleDriverActiveStatus } from '../../../services/drivers/driverStatus';
import { useState } from 'react';
import { toast } from 'react-toastify';

interface DriverDetailsProps {
  driver: Driver;
  handleHideDriver: () => void;
}
const DriverDetails = ({ driver, handleHideDriver }: DriverDetailsProps) => {
  const [isActive, setIsActive] = useState(driver.isActive);
  const [openDriverAlert, setOpenDriverAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleTootleDriverStatus = async () => {
    if (loading) {
      setOpenDriverAlert(false);
      return;
    }

    setLoading(true);
    const result = await toggleDriverActiveStatus(driver._id, !isActive);
    console.log('result:', result);

    // setLoading(false);
    if (result.success === false || typeof result.isActive !== 'boolean') {
      toast.error('Ha ocurrido un error, intenta mas tarde');
      setOpenDriverAlert(false);
      return;
    }
    toast.success(
      `Repartidor ${isActive ? 'inactivado' : 'activado'} con éxito`
    );

    setIsActive(result.isActive);
    setOpenDriverAlert(false);
  };
  return (
    <MKDrawer>
      <section className='my-2 flex flex-col gap-2 p-2 md:my-4'>
        <div className='flex justify-between'>
          <div className='flex items-center gap-2'>
            <p className='text-xl font-semibold'>
              {' '}
              Repartidor {driver.firstName} {driver.lastName}
            </p>
          </div>
          <button
            onClick={handleHideDriver}
            className='rounded-full border border-gray-600 px-2 py-2'
          >
            <IoClose />
          </button>
        </div>
        <section className=' mb-2 flex w-full flex-col gap-2 sm:w-auto md:gap-4'>
          <section className='flex items-center gap-2 rounded-lg border-b border-dashed p-2 md:px-4'>
            <div className='rounded-lg bg-primary-100 p-2'>
              <MdMail className='text-primary-500' />
            </div>
            <div>
              <p className='text-left font-semibold'>Correo</p>
              <p className='text-gray-700'>{driver.email}</p>
            </div>
          </section>
          <section className='flex items-center gap-2 rounded-lg border-b border-dashed p-2 md:px-4'>
            <div className='rounded-lg bg-primary-100 p-2'>
              <MdOutlinePhoneAndroid className='text-primary-500' />
            </div>
            <div>
              <p className='font-semibold'>Teléfono</p>
              <p className='text-gray-700'>{driver.phoneNumber}</p>
            </div>
          </section>
          <div className='flex items-center gap-2  rounded-lg border-b border-dashed p-2 md:px-4'>
            <div className='rounded-lg bg-primary-100 p-2'>
              <FaMapMarkedAlt className='text-primary-500' />
            </div>
            <div>
              <p className='font-semibold'>Ciudad</p>
              <p className='text-gray-700'>{driver.city} </p>
            </div>
          </div>
          <div className='flex items-center gap-2  rounded-lg border-b border-dashed p-2 md:px-4'>
            <div className='rounded-lg bg-primary-100 p-2'>
              <FaMotorcycle className='text-primary-500' />
            </div>
            <div>
              <p className='font-semibold'>Vehículo</p>
              <p className='text-gray-700'>{driver.vehicleInfo.vehicleType}</p>
            </div>
          </div>
        </section>
        <section className='flex items-center gap-2'>
          <GoDotFill
            className={`${isActive ? 'text-green-500' : 'text-red-500'}`}
          />
          <p>{`${isActive ? 'Activo' : 'Inactivo'}`}</p>
        </section>
        <AlertDialog open={openDriverAlert} onOpenChange={setOpenDriverAlert}>
          <AlertDialogTrigger asChild>
            <MKButton
              variant='outlined'
              className={`${
                isActive
                  ? 'border-red-500 text-red-500 hover:bg-red-500 hover:text-white'
                  : 'border-green-500  text-green-800 hover:bg-green-800 hover:text-white'
              }`}
            >
              {`${isActive ? 'Inactivar' : 'Activar'}`}
            </MKButton>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                ¿Estás completamente seguro de{' '}
                {`${isActive ? 'inactivar' : 'activar'}`} este repartidor?
              </AlertDialogTitle>
              <AlertDialogDescription>
                Esta acción tiene implicaciones de acceso al repartidor. Al
                {`${
                  driver.isActive ? ' inactivarlo ya no ' : ' activarlo'
                }`}{' '}
                podrá acceder a la plataforma
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancelar</AlertDialogCancel>
              <MKButton onClick={() => handleTootleDriverStatus()}>
                <p>
                  Entendido, {`${driver.isActive ? 'Inactivar' : 'Activar'}`}
                </p>
              </MKButton>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </section>
    </MKDrawer>
  );
};

export default DriverDetails;
