import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { DateRange } from 'react-day-picker';
import { toast } from 'react-toastify';

import MKInput from '../../UI/MKInput';

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../UI/MKForm/Form';
import { DatePickerWithRange } from '../../UI/DateRangePicker';
import SelectRegionCity from '../../Common/SelectRegion';

import {
  Banner,
  CreateBanner,
  CreateBannerFormValues,
} from '../../../interfaces/admin/banners';
import { createBannerResolver } from './utils/createBannerFormResolver';

import useCategories from '../../../hooks/category/useCategoriesData';
import { useAppDispatch } from '../../../hooks/common/redux.hooks';
import { createBanner } from '../../../redux/actions/sellers';
import styles from '../../../styles/styles';
import {
  createBannerService,
  updateBannerService,
  uploadImageService,
} from '../../../services/banners/createBanner';

interface CreateBannerFormProps {
  banner?: Banner;
  handleClose?: () => void;
}

const CreateBannerForm = ({ banner, handleClose }: CreateBannerFormProps) => {
  const [selectedRegion, setSelectedRegion] = useState<string | null>(null);
  const [selectedCity, setSelectedCity] = useState<string | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(
    banner?.imageUrl || null
  );
  const [date, setDate] = useState<DateRange | undefined>(
    banner?.startDate && banner?.endDate
      ? { from: banner.startDate, to: banner.endDate }
      : undefined
  );
  // const { categories } = useCategories();
  const [imageFile, setImageFile] = useState<any>({ preview: '', file: '' });

  const form = useForm<CreateBannerFormValues>({
    resolver: zodResolver(createBannerResolver),

    defaultValues: {
      title: banner?.title || '',
      description: banner?.description || '',
      startDate: banner?.startDate ? new Date(banner.startDate) : new Date(),
      endDate: banner?.endDate ? new Date(banner.endDate) : new Date(),
      isActive: banner?.isActive || true,
      isApproved: banner?.isApproved || true,
    },
  });

  console.log('form state:', form.formState.errors);

  const onChangeSelectedRegion = (region: string) => {
    setSelectedRegion(region);
    setSelectedCity(null);
  };
  const onChangeSelectedCity = (region: string) => {
    setSelectedCity(region);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (typeof file === 'object') {
      setImageFile({
        preview: URL.createObjectURL(file),
        file: file,
      });
    }
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCreateBanner = async (data: CreateBannerFormValues) => {
    if (!date || imageFile.file === '') {
      return;
    }

    const uploadResponse = await uploadImageService(imageFile.file);

    if (uploadResponse.success === false) {
      toast.error(
        'Oops, tu imagen no se ha subido correctamente, intentalo nuevamente'
      );
      return;
    }

    const bannerData: CreateBanner = {
      ...data,
      city: selectedCity || '',
      startDate: date?.from,
      endDate: date?.to,
      tokenImage: uploadResponse.data.token,
      isActive: true,
      isApproved: true,
      type: 'link',
    };

    const { success, message } = await createBannerService(bannerData);

    if (success) {
      toast.success('Banner creado correctamente');
      if (handleClose) {
        handleClose();
      }
      return;
    }
    toast.error(message || 'Ha ocurrido un error al crear el banner.');
  };

  const handleUpdateBanner = async (data: CreateBannerFormValues) => {
    console.log('update banner', banner);

    const bannerUpdateData: Partial<Banner> = {
      _id: banner._id,
      title: data.title,
      description: data.description,
      type: banner.type,
      isActive: true,
      isApproved: true,
      city: selectedCity,
    };
    const updateResponse = await updateBannerService(bannerUpdateData);
    if (updateResponse.success) {
      toast.success('Banner actualizado correctamente');
      if (handleClose) {
        handleClose();
      }
    }
  };

  const handleCreateOrEditBanner = (data: CreateBannerFormValues) => {
    console.log('handleCreateOrEditBanner');

    if (banner) {
      handleUpdateBanner(data);
      return;
    }
    handleCreateBanner(data);
  };

  return (
    <>
      <Form {...form}>
        <form
          className='my-4 flex flex-col gap-4 px-4 md:my-8'
          onSubmit={form.handleSubmit(handleCreateOrEditBanner)}
        >
          <label htmlFor=''>Selecciona una región y una ciudad</label>
          <SelectRegionCity
            selectedRegion={selectedRegion}
            onChangeSelectedRegion={onChangeSelectedRegion}
            onChangeSelectedCity={onChangeSelectedCity}
          />
          <FormField
            control={form.control}
            name='title'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  Titulo
                  <span className='text-red-500'>*</span>
                </FormLabel>
                <FormControl>
                  <MKInput
                    placeholder='Ingresa el titulo del banner'
                    {...field}
                    className={styles.default_input}
                  />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='description'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Descripción</FormLabel>
                <FormControl>
                  <MKInput
                    {...field}
                    placeholder='Ingresa la descripción del banner'
                    className={styles.default_input}
                  />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name='imageUrl'
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  {banner ? 'Actualizar imagen' : 'Cargar imagen'}
                  <span className='text-red-500'>*</span>
                </FormLabel>
                <FormControl>
                  <MKInput
                    {...field}
                    type='file'
                    placeholder='Selecciona una imagen'
                    className={styles.default_input}
                    onChange={(e) => {
                      field.onChange(e);
                      handleImageChange(e);
                    }}
                  />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />

          {imagePreview && (
            <div>
              <img src={imagePreview} alt='banner' />
            </div>
          )}
          <p>Fecha de inicio y fecha de finalización</p>
          <DatePickerWithRange date={date} setDate={setDate} />

          {/* <FormField
          control={form.control}
          name='categoryId'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Categoría</FormLabel>
              <FormControl>
                <Select {...field}>
                  <SelectTrigger>
                    <SelectValue placeholder='Selecciona una categoría' />
                  </SelectTrigger>
                  {categories && (
                    <SelectContent>
                      <SelectItem value={null}>Ninguno</SelectItem>
                      {categories.map((category) => (
                        <SelectItem key={category._id} value={category._id}>
                          {category.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  )}
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        /> */}

          {/* sellers */}
          {/* <FormField
          control={form.control}
          name='storeId'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Afiliado</FormLabel>
              <FormControl>
                <Select {...field}>
                  <SelectTrigger>
                    <SelectValue placeholder='Selecciona una tienda' />
                  </SelectTrigger>
                  {Array.isArray(sellers) && (
                    <SelectContent>
                      <SelectItem value={null}>Ninguno</SelectItem>

                      {sellers.map((seller) => (
                        <SelectItem key={seller._id} value={seller._id}>
                          {seller.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  )}
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        /> */}

          <button
            type='submit'
            className='w-full self-center rounded-xl bg-primary-200 px-4 py-2 text-white hover:bg-primary-500 md:w-1/2'
          >
            {banner ? 'Actualizar banner' : 'Crear banner'}
          </button>
        </form>
      </Form>
    </>
  );
};

export default CreateBannerForm;
