import { useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { getCountryDataList, countries, getEmojiFlag } from 'countries-list';

import MKDrawer from '../../../UI/MKDrawer';
import MKButton from '../../../UI/MKButton';
import MKCard from '../../../UI/MKCard';
import { useSaveAdminConfig } from '../../../../hooks/admin/config/config.hooks';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { refreshConfiguration } from '../../../../redux/actions/config';

interface IBeginSiteConfigurationDrawer {
  handleCloseDrawer: () => void;
}
interface ICountryProps {
  name: string;
  countryCode: string;
  currency: string;
  currencySign: string;
  telephoneCountryCode: string;
}
const BeginSiteConfigurationDrawer = ({
  handleCloseDrawer,
}: IBeginSiteConfigurationDrawer) => {
  const dispatch = useDispatch();
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [currencyName, setCurrencyName] = useState('');
  const [shippingPrice, setShippingPrice] = useState(0);
  const [countryProps, setcountryProps] = useState<ICountryProps | null>(null);
  const [serviceFee, setServiceFee] = useState(0);
  const [taxPrice, setTaxPrice] = useState(0);
  const { savingConfig, saveConfiguration, saveConfigError } =
    useSaveAdminConfig();

  const allCountries = getCountryDataList();

  const handleSelectedCountry = (selected: string) => {
    if (countries[selected]) {
      setCurrencyName(countries[selected].currency[0]);
      setcountryProps({
        ...countries[selected],
        telephoneCountryCode: `+${countries[selected]?.phone[0]}`,
        countryCode: selected,
        currency: countries[selected].currency[0],
      });
    }
  };

  const saveSiteConfiguration = async () => {
    if (
      !countryProps ||
      !currencySymbol ||
      !currencyName ||
      shippingPrice === null ||
      taxPrice === null ||
      serviceFee === null
    ) {
      toast.error('Por favor llenar todos los campos');
      return;
    }

    const res = await saveConfiguration({
      country_name: countryProps.name,
      country_code: countryProps.countryCode,
      currency_sign: currencySymbol,
      currency: currencyName,
      telephone_country_code: countryProps.telephoneCountryCode,
      shipping_price: shippingPrice,
      tax: Number(taxPrice),
      service_fee: serviceFee,
    });
    if (res.success) {
      toast.success('Guardado con éxito');
      // @ts-ignore
      dispatch(refreshConfiguration());
      handleCloseDrawer();
    }
  };

  return (
    <MKDrawer>
      <section className='flex justify-center'>
        <div className='w-full overflow-y-scroll rounded-md bg-white '>
          <div className='my-4 w-full shadow'>
            <div className='flex items-center justify-between px-6 pb-2 '>
              <h5 className='text-center text-xl '>Configura tu sitio</h5>
              <button onClick={handleCloseDrawer} className='flex'>
                <RxCross2 size={25} className='cursor-pointer' />
              </button>
            </div>
          </div>

          {/* create config section */}
          <section className='px-6 '>
            <MKCard>
              <p className='mb-4 border-b pb-2 font-medium text-primary-200'>
                Pais de operación
              </p>
              <div className='mb-2'>
                <p>¿En que pais tendrás operaciones? *</p>
                <select
                  onChange={(e) => handleSelectedCountry(e.target.value)}
                  id='countries'
                  className='mt-2 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500'
                >
                  <option selected>Seleccione el pais</option>
                  {allCountries.map((country) => (
                    <option value={country.iso2}>
                      {getEmojiFlag(country.iso2)}
                      <p className='pl-4'>{country.native}</p>
                    </option>
                  ))}
                </select>
              </div>

              {countryProps && (
                <>
                  <div className='mb-2'>
                    <div className='mb-2'>
                      <p>Código telefónico</p>
                      <MKCard className='px-4 py-2'>
                        <p>{countryProps.telephoneCountryCode}</p>
                      </MKCard>
                    </div>
                    <p>Moneda corporativa</p>
                    <input
                      type='text'
                      name='country-name'
                      required
                      value={currencyName}
                      onChange={(e) => setCurrencyName(e.target.value)}
                      className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm'
                    />
                  </div>
                  <div className='mb-2'>
                    <p>Simbolo moneda corporativa</p>
                    <input
                      type='text'
                      name='country-name'
                      required
                      placeholder='Ingresa el simbolo de la moneda '
                      value={currencySymbol}
                      onChange={(e) => setCurrencySymbol(e.target.value)}
                      className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm'
                    />
                  </div>
                </>
              )}
            </MKCard>
            <MKCard className='mt-4'>
              <p className='my-4 border-b pb-2 font-medium text-primary-200'>
                Finanzas
              </p>
              <div className='mb-2'>
                <p>Costo de envio *</p>
                <input
                  type='number'
                  name='country-name'
                  required
                  value={shippingPrice}
                  onChange={(e) => setShippingPrice(Number(e.target.value))}
                  className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm'
                />
              </div>
              <div className='mb-2'>
                <p>IVA (porcentaje por venta) *</p>
                <input
                  type='number'
                  name='country-name'
                  required
                  value={taxPrice}
                  onChange={(e) => setTaxPrice(Number(e.target.value))}
                  className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm'
                />
              </div>
              <div className='mb-2'>
                <p>
                  Tarifa de servicio{' '}
                  <span className='font-bold text-gray-500'>
                    (porcentaje por venta)
                  </span>
                  *
                </p>
                <input
                  type='number'
                  name='country-name'
                  required
                  placeholder='Indica la tarifa impuesta por cada venta, ej: 10'
                  value={serviceFee}
                  onChange={(e) => setServiceFee(Number(e.target.value))}
                  className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm'
                />
              </div>
            </MKCard>
            <MKButton
              onClick={saveSiteConfiguration}
              className='mt-8 w-full hover:bg-primary-500'
            >
              <p className='text-white'>Guardar</p>
            </MKButton>
          </section>
        </div>
      </section>
    </MKDrawer>
  );
};

export default BeginSiteConfigurationDrawer;
