import DriversTabs from '../../components/Admin/Drivers/DriversTabs';
import AdminSideBar from '../../components/Admin/Layout/AdminSideBar';
import AdminHeader from '../../components/Layout/AdminHeader';

const DriversListPage = () => {
  return (
    <div>
      <AdminHeader />
      <div className='flex w-full'>
        <div className='flex w-full items-start justify-between'>
          <div className='w-[80px] 800px:w-[330px]'>
            <AdminSideBar active={10} />
          </div>
          <DriversTabs />
        </div>
      </div>
    </div>
  );
};

export default DriversListPage;
