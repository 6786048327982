import React from 'react';
import AdminHeader from '../components/Layout/AdminHeader';
import AdminSideBar from '../components/Admin/Layout/AdminSideBar';
import AllEvents from '../components/Admin/AllEvents';

const AdminDashboardEvents = () => {
  return (
    <div>
      <AdminHeader />
      <div className='flex w-full'>
        <div className='flex w-full items-start justify-between'>
          <div className='w-[80px] 800px:w-[330px]'>
            <AdminSideBar active={6} />
          </div>
          <AllEvents />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardEvents;
