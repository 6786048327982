import React from 'react';
import DashboardCommonLayout from '../../../../components/Layout/DashboardCommonLayout';
import DashboardSideBar from '../../../../components/Shop/Layout/DashboardSideBar';
import MKCard from '../../../../components/UI/MKCard';
import { MdArrowBackIosNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import CreateRoleForm from '../../../../components/Shop/Roles/CreateRole/CreateRoleForm';

const CreateRole = () => {
  const navigate = useNavigate();
  return (
    <>
      <DashboardCommonLayout>
        <>
          <div className='flex w-full justify-between'>
            <div className='w-[80px] 800px:w-[330px]'>
              <DashboardSideBar active={13} />
            </div>
            <div className='w-full '>
              <MKCard className=' '>
                <div className='mr-4 flex'>
                  <button
                    className='inline-block max-h-min p-1'
                    onClick={() => navigate(-1)}
                  >
                    <MdArrowBackIosNew className='h-6 w-6 text-gray-400' />
                  </button>
                  <p className='text-center text-lg font-bold'>Crear rol</p>
                </div>
                <div className=''>
                  <CreateRoleForm />
                </div>
              </MKCard>
            </div>
          </div>
        </>
      </DashboardCommonLayout>
    </>
  );
};

export default CreateRole;
