import { findDriverOrdersService } from '../../services/drivers/driverOrders';
import { useDriverSessionStore } from '../../store/driversSession';

const useDriverSession = () => {
  const {
    driverSession,
    driverActiveOrders,
    driverPendingOrders,
    logoutDriver,
  } = useDriverSessionStore();

  const findDriverOrders = async () => {
    const { success, orders } = await findDriverOrdersService(
      driverSession._id
    );
  };

  return {
    driverSession,
    driverActiveOrders,
    driverPendingOrders,
    findDriverOrders,
    logoutDriver,
  };
};

export default useDriverSession;
