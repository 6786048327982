import { useState } from 'react';
import { MdOutlineClose } from 'react-icons/md';

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '../../UI/MKDrawer';
import MKButton from '../../UI/MKButton';
import CreateBannerForm from './CreateBannerForm';
import { useBanners } from '../../../hooks/banners/useBanners';

const CreateBanner = () => {
  const { fetchBanners } = useBanners();

  const [open, setOpen] = useState(false);

  const handleSetClose = () => {
    fetchBanners("")
    setOpen(false);
  }
  return (
    <>
      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerTrigger
        className='rounded-xl bg-primary-200 px-3 py-2 text-white hover:bg-primary-500'>
          Crear Banner
        </DrawerTrigger>
        <DrawerContent className='px-4 md:px-24'>
          <DrawerHeader className='flex gap-4 border-b border-gray-400 p-0 py-2'>
            <DrawerClose className='rounded-full bg-gray-200'>
              <MKButton variant='text'>
                <MdOutlineClose size={25} />
              </MKButton>
            </DrawerClose>
            <section>
              <DrawerTitle>Banner</DrawerTitle>
              <DrawerDescription>
                Crea banners personalizados y disponibles por ciudad.
              </DrawerDescription>
            </section>
          </DrawerHeader>
          <section className='py-2 md:py-4'>
            <CreateBannerForm handleClose={handleSetClose} />
          </section>
        </DrawerContent >
      </Drawer>
    </>
  );
};

export default CreateBanner;
