import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DataGrid } from '@material-ui/data-grid';
import { BsPencil } from 'react-icons/bs';
import { RxCross1 } from 'react-icons/rx';

import styles from '../../styles/styles';
import { server } from '../../server';

const AllWithdraw = () => {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [withdrawData, setWithdrawData] = useState();
  const [withdrawStatus, setWithdrawStatus] = useState('Processing');

  useEffect(() => {
    axios
      .get(`${server}/withdraw/get-all-withdraw-request`, {
        withCredentials: true,
      })
      .then((res) => {
        setData(res.data.withdraws);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  }, []);

  const columns = [
    { field: 'id', headerName: 'Withdraw Id', minWidth: 150, flex: 0.7 },
    {
      field: 'name',
      headerName: 'Shop Name',
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: 'shopId',
      headerName: 'Shop Id',
      minWidth: 180,
      flex: 1.4,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 100,
      flex: 0.6,
    },
    {
      field: 'status',
      headerName: 'status',
      type: 'text',
      minWidth: 80,
      flex: 0.5,
    },
    {
      field: 'createdAt',
      headerName: 'Request given at',
      type: 'number',
      minWidth: 130,
      flex: 0.6,
    },
    {
      field: ' ',
      headerName: 'Update Status',
      type: 'number',
      minWidth: 130,
      flex: 0.6,
      renderCell: (params) => {
        return (
          <BsPencil
            size={20}
            className={`${
              params.row.status !== 'Processing' ? 'hidden' : ''
            } mr-5 cursor-pointer`}
            onClick={() => setOpen(true) || setWithdrawData(params.row)}
          />
        );
      },
    },
  ];

  const handleSubmit = async () => {
    await axios
      .put(
        `${server}/withdraw/update-withdraw-request/${withdrawData.id}`,
        {
          sellerId: withdrawData.shopId,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success('Withdraw request updated successfully!');
        setData(res.data.withdraws);
        setOpen(false);
      });
  };

  const row = [];

  data &&
    data.forEach((item) => {
      row.push({
        id: item._id,
        shopId: item.seller._id,
        name: item.seller.name,
        amount: 'US$ ' + item.amount,
        status: item.status,
        createdAt: item.createdAt.slice(0, 10),
      });
    });
  return (
    <div className='flex w-full items-center justify-center pt-5'>
      <div className='w-[95%] bg-white'>
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
      </div>
      {open && (
        <div className='fixed left-0 top-0 z-[9999] flex h-screen w-full items-center justify-center bg-[#00000031]'>
          <div className='min-h-[40vh] w-[50%] rounded bg-white p-4 shadow'>
            <div className='flex w-full justify-end'>
              <RxCross1 size={25} onClick={() => setOpen(false)} />
            </div>
            <h1 className='text-center font-Poppins text-[25px]'>
              Update Withdraw status
            </h1>
            <br />
            <select
              name=''
              id=''
              onChange={(e) => setWithdrawStatus(e.target.value)}
              className='h-[35px] w-[200px] rounded border'
            >
              <option value={withdrawStatus}>{withdrawData.status}</option>
              <option value={withdrawStatus}>Succeed</option>
            </select>
            <button
              type='submit'
              className={`block ${styles.button} mt-4 !h-[42px] text-[18px] text-white`}
              onClick={handleSubmit}
            >
              Update
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllWithdraw;
