import { create } from 'zustand';
import { IFetchState } from '../interfaces/common';
import { IStaff } from '../interfaces/admin/staff';

interface IStaffStore {
  staffMembers: IStaff[] | null;
  state: IFetchState;
  setAllStaff: (staffMembers: IStaff[]) => void;
  setLoading: (loading?: boolean) => void;
  setError: (error?: boolean, message?: string) => void;
  clearState: () => void;
}
export const useAdminStaffStore = create<IStaffStore>((set) => ({
  staffMembers: null,
  state: { loading: false, error: false },
  setAllStaff: (staffMembers: IStaff[]) =>
    set({ staffMembers, state: { loading: false, error: false } }),
  setLoading: (loading = true) =>
    set({ state: { loading: loading, error: false, message: '' } }),
  setError: (error = true, message = '') =>
    set({ state: { loading: false, error: error, message } }),
  clearState: () =>
    set({ state: { loading: false, error: false, message: null } }),
}));
