// @ts-nocheck
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllSellers } from '../../../../redux/actions/sellers';
import AllPartnersList from './AllPartnersList';

const AllSellers = () => {
  const dispatch = useDispatch();
  const { sellers } = useSelector((state) => state.seller);

  useEffect(() => {
    if (!sellers) {
      dispatch(getAllSellers({ isApproved: true }));
    }
  }, []);

  return (
    <div className='justify-centerpt-5 flex w-full'>
      <div className='flex w-11/12 '>
        {sellers && Array.isArray(sellers) && (
          <AllPartnersList partners={sellers} />
        )}
      </div>
    </div>
  );
};

export default AllSellers;
