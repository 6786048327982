import { createReducer } from '@reduxjs/toolkit';
import { REQUEST_STATUS } from '../../utils/constants/common';

const initialState = {
  isLoading: true,
  product: null,
  products: [],
  status: {
    error: false,
    success: false,
    message: '',
    type: 'none',
  },
};

export const productReducer = createReducer(initialState, {
  productCreateRequest: (state) => {
    state.isLoading = true;
    state.error = false;
    state.status = {
      error: false,
      success: false,
      message: '',
      type: REQUEST_STATUS.create,
    };
  },
  productEditRequest: (state) => {
    state.isLoading = true;
    state.error = false;
    state.status = {
      error: false,
      success: true,
      message: '',
      type: REQUEST_STATUS.update,
    };
  },
  productCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.product = action.payload;
    state.error = false;
    state.status = {
      ...state.status,
      success: true,
      message: '',
      type: REQUEST_STATUS.create,
    };
  },
  productEditSuccess: (state, action) => {
    state.isLoading = false;
    state.product = action.payload;
    state.status = {
      error: false,
      success: true,
      message: '',
      type: REQUEST_STATUS.update,
    };
    state.error = false;
  },
  productCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
    state.status = {
      error: true,
      success: false,
      message: action.payload,
      type: REQUEST_STATUS.create,
    };
  },
  productEditFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
    state.status = {
      error: true,
      success: false,
      message: action.payload,
      type: REQUEST_STATUS.error,
    };
  },

  // get all products of shop
  getAllProductsShopRequest: (state) => {
    state.isLoading = true;
    state.status = {
      error: false,
      success: false,
      message: '',
      type: REQUEST_STATUS.loadAll,
    };
  },
  getAllProductsShopSuccess: (state, action) => {
    state.isLoading = false;
    state.products = action.payload;
    state.status = {
      error: false,
      success: true,
      message: '',
      type: REQUEST_STATUS.loadAll,
    };
  },
  getAllProductsShopFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.products = [];
    state.status = {
      error: true,
      success: false,
      message: action.payload,
      type: REQUEST_STATUS.loadAll,
    };
  },

  // delete product of a shop
  deleteProductRequest: (state) => {
    state.isLoading = true;
    state.status = {
      error: false,
      success: false,
      message: '',
    };
  },
  deleteProductSuccess: (state, action) => {
    state.isLoading = false;
    state.message = action.payload;
    state.status = {
      error: false,
      success: true,
      message: action.payload,
    };
  },
  deleteProductFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.status = {
      error: true,
      success: false,
      message: action.payload,
    };
  },

  clearErrors: (state) => {
    state.error = null;
  },
  clearProductState: (state) => {
    state.status = {
      error: false,
      success: false,
      message: '',
      type: 'none',
    };
    state.isLoading = false;
    state.error = false;
  },
});
