import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { RxDashboard } from 'react-icons/rx';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { AiOutlineLogout } from 'react-icons/ai';

import { DRIVER_NAVIGATION } from '../../../utils/constants/navigation';
import useDriverSession from '../../../hooks/drivers/useDriverSession';

const DriverSideBar = ({ active }) => {
  const { logoutDriver } = useDriverSession();
  const logoutHandler = () => {
    Cookies.remove('driver-token', { path: '' });
    logoutDriver();
  };
  return (
    <div className='sticky left-0 top-0 z-10 h-[90vh] w-full overflow-y-scroll bg-white shadow-sm'>
      {/* single item */}
      <div className='flex w-full items-center p-4'>
        <Link
          to={DRIVER_NAVIGATION.dashboard.link}
          className='flex w-full items-center'
        >
          <RxDashboard
            size={30}
            className={`${
              active === DRIVER_NAVIGATION.dashboard.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === DRIVER_NAVIGATION.dashboard.id
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          >
            Dashboard
          </h5>
        </Link>
      </div>

      <div className='flex w-full items-center p-4'>
        <Link
          to={DRIVER_NAVIGATION.orders.link}
          className='flex w-full items-center'
        >
          <HiOutlineUserGroup
            size={30}
            className={`${
              active === DRIVER_NAVIGATION.orders.link
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          />
          <h5
            className={`hidden pl-2 text-[18px] font-[400] 800px:block ${
              active === DRIVER_NAVIGATION.orders.link
                ? 'text-primary-200'
                : 'text-gray-500'
            }`}
          >
            Usuarios
          </h5>
        </Link>
      </div>

      <div
        className='flex w-full cursor-pointer items-center p-4 text-slate-500'
        onClick={logoutHandler}
      >
        <AiOutlineLogout size={30} />
        <span className={`hidden pl-3 800px:block`}>Cerrar sesión</span>
      </div>
    </div>
  );
};

export default DriverSideBar;
