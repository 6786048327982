import { findAllStaffMembers } from '../../services/staff/staff';
import { useAdminStaffStore } from '../../store/staff';

export const useAdminStaff = (lazyLoad = false) => {
  const { staffMembers, setAllStaff, setLoading, setError, clearState } =
    useAdminStaffStore();
  const fetchStaffMembers = async () => {
    const getAllStaffResponse = await findAllStaffMembers();
    if (getAllStaffResponse.success) {
      setAllStaff(getAllStaffResponse.data);
    } else {
      setError(true, getAllStaffResponse.message);
    }
  };

  if (!lazyLoad && !staffMembers) {
    fetchStaffMembers();
  }

  return {
    staffMembers,
    setAllStaff,
    setLoading,
    setError,
    clearState,
    fetchStaffMembers,
    fetch: fetchStaffMembers,
  };
};
