import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { IFetchState } from '../interfaces/common';
import { Driver } from '../interfaces/admin/driver';
import { DriverOrder } from '../interfaces/driver/driverDashboard';

interface DriverSessionStore {
  driverSession: Driver | null;
  driverFetchState: IFetchState;
  token: string | null;
  driverActiveOrders: DriverOrder[] | null;
  driverPendingOrders: DriverOrder[] | null;
  setDriverActiveOrders: (orders: DriverOrder[]) => void;
  setDriverPendingOrders: (orders: DriverOrder[]) => void;
  setDriverLoading: (loading?: boolean) => void;
  setDriverError: (error?: boolean, message?: string) => void;
  singInDriver: (driver: Driver, token: string) => void;
  clearDriverState: () => void;
  logoutDriver: () => void;
}

export const useDriverSessionStore = create<DriverSessionStore>()(
  persist(
    (set) => ({
      driverSession: null,
      driverFetchState: { loading: false, error: false },
      token: null,
      driverActiveOrders: null,
      driverPendingOrders: null,
      setDriverActiveOrders: (orders: DriverOrder[]) =>
        set({ driverActiveOrders: orders }),
      setDriverPendingOrders: (orders: DriverOrder[]) =>
        set({ driverPendingOrders: orders }),
      singInDriver: (driver: Driver, token) =>
        set({ driverSession: driver, token }),
      logoutDriver: () => set({ driverSession: null }),

      setDriverLoading: (loading = true) =>
        set({
          driverFetchState: { loading: loading, error: false, message: '' },
        }),
      setDriverError: (error = true, message = '') =>
        set({ driverFetchState: { loading: false, error: error, message } }),
      clearDriverState: () =>
        set({
          driverFetchState: { loading: false, error: false, message: null },
        }),

      clearPotentialDriversState: () =>
        set({
          driverFetchState: { loading: false, error: false, message: null },
        }),
    }),
    {
      name: 'driver-session',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
