import { createReducer } from '@reduxjs/toolkit';
import { IConfigurationResponse } from '../../interfaces/admin/configuration';

interface IConfigurationReducer {
  isLoading: boolean;
  refresh: boolean;
  error: boolean;
  configuration: IConfigurationResponse | null;
}

const initialState: IConfigurationReducer = {
  isLoading: true,
  refresh: false,
  error: false,
  configuration: null,
};

export const configurationReducer = createReducer(initialState, {
  RefreshConfiguration: (state) => {
    state.isLoading = true;
    state.refresh = true;
    state.error = false;
    state.configuration = null;
  },

  LoadConfigSuccess: (state, action) => {
    state.isLoading = false;
    state.refresh = false;
    state.error = false;
    state.configuration = action.payload;
  },
  LoadConfigFail: (state, action) => {
    state.isLoading = false;
    state.refresh = false;
    state.error = true;
    state.configuration = null;
  },

  clearErrors: (state) => {
    state.error = null;
  },
});
