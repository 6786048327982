import React from 'react';
import MKDrawer from '../../../../UI/MKDrawer';
import MKButton from '../../../../UI/MKButton';
import {
  FaCheck,
  FaMapMarkedAlt,
  FaMapPin,
  FaRegCalendarCheck,
  FaRegTrashAlt,
} from 'react-icons/fa';
import MKCard from '../../../../UI/MKCard';
import { IoClose, IoMailOutline } from 'react-icons/io5';
import { getTimeAgo } from '../../../../../utils/datetime/time';
import {
  MdOutlineHideImage,
  MdOutlineLocationOn,
  MdOutlinePhoneAndroid,
} from 'react-icons/md';
import { MapApp } from '../../../../UI/GoogleMap';
import { BiMessageSquareDetail } from 'react-icons/bi';
import {
  approvePotentialPartnerService,
  rejectPotentialPartnerService,
} from '../../../../../services/admin/partners';
import { useAppDispatch } from '../../../../../hooks/common/redux.hooks';
import { getPotentialPartners } from '../../../../../redux/actions/sellers';
import { toast } from 'react-toastify';

interface IPotentialPartnerProps {
  partner: any;
  handleClosePartnerDetail: () => void;
}
const PotentialPartnerDetails = ({
  partner,
  handleClosePartnerDetail,
}: IPotentialPartnerProps) => {
  const dispatch = useAppDispatch();
  const handleApprovePartner = async () => {
    const approvePartnerResponse = await approvePotentialPartnerService(
      partner
    );
    if (
      approvePartnerResponse &&
      approvePartnerResponse.data?.success === true
    ) {
      handleClosePartnerDetail();
      dispatch(getPotentialPartners());
      toast.success(`${approvePartnerResponse.data?.message}`);
    }
  };

  const handleRejectPartner = async () => {
    const rejectPartner = await rejectPotentialPartnerService(partner);
    if (rejectPartner && rejectPartner.data?.success === true) {
      handleClosePartnerDetail();
      dispatch(getPotentialPartners());
      toast.success(`${rejectPartner.data?.message}`);
    }
  };
  return (
    <>
      <MKDrawer>
        <section className='my-2 flex flex-col gap-2 p-2 md:my-4'>
          <div className='flex justify-between'>
            <div className='flex items-center gap-2'>
              {partner.avatar ? (
                <img className='h-6 w-6' src={partner.avatar.url} alt='' />
              ) : (
                <MdOutlineHideImage className='h-6 w-6 text-gray-400' />
              )}
              <p className='text-xl font-semibold'> {partner.name}</p>
            </div>
            <button
              onClick={handleClosePartnerDetail}
              className='rounded-full border border-gray-600 px-2 py-2'
            >
              <IoClose />
            </button>
          </div>

          <MKCard className='my-2 flex flex-col gap-2 py-2 shadow-md'>
            <p className='border-b font-bold '>Cuenta en estado de revisión</p>
            <section className='my-2 flex gap-2'>
              <MKButton
                onClick={handleApprovePartner}
                className='flex items-center px-4 py-2'
              >
                <FaCheck />
                <p>Aprobar</p>
              </MKButton>
              <MKButton
                onClick={handleRejectPartner}
                variant='outlined'
                className='flex items-center border-red-400 px-4 py-2 hover:bg-red-50'
              >
                <FaRegTrashAlt className='text-red-800' />
                <p className='ml-1 text-red-800'>Rechazar </p>
              </MKButton>
            </section>
          </MKCard>
          <section className='my-2 flex items-center'>
            <BiMessageSquareDetail className='h-6 w-6 text-primary-500' />
            <p className='text-lg font-semibold text-primary-500'>
              Información general
            </p>
          </section>
          <section className='flex items-center gap-2 border-b border-dashed'>
            <div className='rounded-lg bg-primary-100 p-2'>
              <IoMailOutline className='text-primary-500' />
            </div>
            <div>
              <p className='font-semibold'>Correo</p>
              <p className='text-gray-700'>{partner.email}</p>
            </div>
          </section>

          <section className='flex items-center gap-2 border-b border-dashed'>
            <div className='rounded-lg bg-primary-100 p-2'>
              <MdOutlinePhoneAndroid className='text-primary-500' />
            </div>
            <div>
              <p className='font-semibold'>Teléfono</p>
              <p className='text-gray-700'>{partner.phoneNumber}</p>
            </div>
          </section>

          <section className='flex items-center gap-2 border-b border-dashed'>
            <div className='rounded-lg bg-primary-100 p-2'>
              <FaRegCalendarCheck className='text-primary-500' />
            </div>
            <div>
              <p className='font-semibold'>Fecha</p>
              <p className='text-gray-700'>
                Solicitud creada {getTimeAgo(new Date(partner.createdAt))}
              </p>
            </div>
          </section>

          <section className='my-2 flex items-center'>
            <MdOutlineLocationOn className='h-6 w-6 text-primary-500' />
            <p className='text-lg font-semibold text-primary-500'>Ubicación</p>
          </section>
          <section className='flex items-center gap-2 border-b border-dashed'>
            <div className='rounded-lg bg-primary-100 p-2'>
              <FaMapMarkedAlt className='text-primary-500' />
            </div>
            <div>
              <p className='font-semibold'>{partner.address.region}</p>
              <p className='text-gray-700'>{partner.address.city}</p>
            </div>
          </section>
          <section className='flex items-center gap-2 border-b border-dashed'>
            <div className='rounded-lg bg-primary-100 p-2'>
              <FaMapPin className='text-primary-500' />
            </div>
            <div>
              <p className='font-semibold'>Barrio / sector</p>
              <p className='text-gray-700'>{partner.address.neighborhood}</p>
            </div>
          </section>
          <MapApp
            width='100%'
            height='50vh'
            coordenadas={{
              lat: partner.address.coordinates[1],
              lng: partner.address.coordinates[0],
            }}
            includeRadioInMap={false}
            enableSearch={false}
            enableChangeLocation={false}
          />
        </section>
      </MKDrawer>
    </>
  );
};

export default PotentialPartnerDetails;
