import React, { useState } from 'react';
import { FaCheckCircle, FaPencilAlt } from 'react-icons/fa';
import { FaTreeCity } from 'react-icons/fa6';

import { MdOutlineBlock } from 'react-icons/md';
import MKButton from '../../../../UI/MKButton';
import CreateCityDrawer from './CreateCityDrawer';

interface IRegionList {
  name: string;
  id: string;
  active: boolean;
  cities: any[];
  Refetch: () => void;
}
const RegionList = ({ name, id, active, cities, Refetch }: IRegionList) => {
  const [enableCreateCity, setEnableCreateCity] = useState(false);
  const disableCreateCity = () => {
    setEnableCreateCity(false);
    Refetch();
  };
  return (
    <section key={`region-${name}${id}`} className='border-b pb-4'>
      <section className='mb-3 md:mb-5'>
        <div className='flex items-center gap-3'>
          <p>{name}</p>
          {active ? (
            <FaCheckCircle className='text-primary-500' />
          ) : (
            <MdOutlineBlock />
          )}
          <section className='flex items-center gap-2'>
            |
            <MKButton
              className='flex items-center gap-2 py-1'
              variant='outlined'
            >
              <FaPencilAlt />
              Editar
            </MKButton>
          </section>
        </div>
      </section>
      {cities.length === 0 && !enableCreateCity && (
        <section className='flex flex-col gap-3 md:flex-row md:items-center'>
          <p className='mb-2'>Aun no has agregado ninguna ciudad</p>
          <MKButton
            onClick={() => {
              setEnableCreateCity(true);
            }}
            className='px-3 py-2'
            variant='outlined'
          >
            Crear ciudad
          </MKButton>
        </section>
      )}
      {cities.length > 0 && !enableCreateCity && (
        <section>
          <section className='mb-4 flex flex-col gap-4 md:mb-6 md:flex-row md:items-center'>
            <p className='mb-2'>Lista de Ciudades</p>
            <MKButton
              onClick={() => {
                setEnableCreateCity(true);
              }}
              className='px-3 py-2'
              variant='outlined'
            >
              Crear ciudad
            </MKButton>
          </section>

          <section className='flex flex-col gap-3'>
            {cities.map((city) => (
              <section className='flex items-center gap-2'>
                <FaTreeCity />
                <p>{city.name}</p>
                <p>Radio: {(Number(city.range) / 1000).toFixed(1)} KM</p>
                {city.active ? (
                  <FaCheckCircle className='text-primary-500' />
                ) : (
                  <MdOutlineBlock />
                )}
                <div>
                  |<MKButton variant='text'>Editar</MKButton>
                </div>
              </section>
            ))}
          </section>
        </section>
      )}
      {enableCreateCity && (
        <>
          <CreateCityDrawer disableCreateCity={disableCreateCity} _id={id} />
        </>
      )}
    </section>
  );
};

export default RegionList;
