import { IShopRole } from '../../../../datatypes/interfaces/roles';
import {
  INITIAL_PERMISSION_VALUES,
  SHOP_RESOURCE_ROLE_NAME,
} from '../../../../utils/constants/common';

export const joinResourceWithGrantedPermission = (roleDetail: IShopRole) => {
  const resourcesName = Object.keys(SHOP_RESOURCE_ROLE_NAME);

  return resourcesName.map((defaultResource) => {
    const existinPermission = roleDetail.resources.find(
      (resource) => resource.name === defaultResource
    );
    if (existinPermission) {
      return {
        name: existinPermission.name,
        permissions: existinPermission.permissions,
      };
    }
    return {
      name: defaultResource,
      permissions: INITIAL_PERMISSION_VALUES,
    };
  });
};
