import { ButtonVariantType } from '../../../types/common';

export const defaultButtonVariantStyle = (variant?: ButtonVariantType) => {
  const variantStyle = {
    none: '',
    outlined:
      'border border-primary-200 hover:bg-primary-200 text-primary-500 hover:text-white rounded-2xl self-start px-4 py-3',
    contained:
      'bg-primary-200 hover:bg-primary-500 rounded-2xl self-start px-4 py-3',
    text: 'bg-transparent self-start px-2 py-1',
    default:
      'bg-primary-200 hover:bg-primary-500 text-white rounded-xl self-start px-4 py-2',
    ghost: 'hover:bg-accent hover:text-accent-foreground',
  };
  const selectedStyle = variantStyle[variant || 'default'];

  return selectedStyle;
};
