import axios from 'axios';
import { server } from '../../server';
import { DEFAULT_ERROR_MESSAGE } from '../../utils/constants/common';
import { RegionRequestResponse } from '../../interfaces/region';

export const getRegionsAndCitiesService = async () => {
  try {
    const { data } = await axios.get<RegionRequestResponse>(
      `${server}/region/get-regions-and-cities`
    );
    return {
      success: true,
      data: data.regions,
    };
  } catch (error: any) {
    return {
      success: false,
      message: error.message || DEFAULT_ERROR_MESSAGE,
    };
  }
};

export const createCityService = async ({
  name,
  location,
  active,
  regionId,
  range,
}) => {
  try {
    const { data } = await axios.post(`${server}/city/create-city`, {
      name,
      location,
      active,
      regionId,
      range,
    });
    return data;
  } catch (error: any) {
    return {
      error: true,
      message: error.message || DEFAULT_ERROR_MESSAGE,
    };
  }
};
