import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import DashboardHero from '../../components/Shop/DashboardHero';
import DashboardCommonLayout from '../../components/Layout/DashboardCommonLayout';

const ShopDashboardPage = () => {
  return (
    <DashboardCommonLayout>
      <div className='flex w-full items-start justify-between'>
        <div className='w-[80px] 800px:w-[330px]'>
          <DashboardSideBar active={1} />
        </div>
        <DashboardHero />
      </div>
    </DashboardCommonLayout>
  );
};

export default ShopDashboardPage;
