'use client';

import * as React from 'react';
import { format } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { LuCalendarDays } from 'react-icons/lu';

import { Calendar } from '../Calendar';
import { Popover, PopoverContent, PopoverTrigger } from '../MKPopover';
import { cn } from '../../../utils/cn';

interface DateRangePickerProps extends React.HTMLAttributes<HTMLDivElement> {
  date: DateRange;
  setDate: (date: DateRange) => void;
}

export function DatePickerWithRange({
  date,
  setDate,
  className,
}: DateRangePickerProps) {
  return (
    <>
      <div className={cn('grid gap-2', className)}>
        <Popover modal={true}>
          <PopoverTrigger asChild>
            <button
              id='date'
              className={cn(
                'flex w-[300px] items-center justify-start rounded-md border-2  px-2 py-1 text-left font-normal',
                !date && 'text-muted-foreground'
              )}
            >
              <LuCalendarDays className='mr-2 h-4 w-4' />
              {date?.from ? (
                date.to ? (
                  <>
                    {format(date.from, 'LLL dd, y')} -{' '}
                    {format(date.to, 'LLL dd, y')}
                  </>
                ) : (
                  format(date.from, 'LLL dd, y')
                )
              ) : (
                <span>Selecciona una fecha</span>
              )}
            </button>
          </PopoverTrigger>
          <PopoverContent className='w-auto bg-white p-0' align='start'>
            <Calendar
              mode='range'
              defaultMonth={date?.from}
              selected={date}
              onSelect={setDate}
              numberOfMonths={2}
            />
          </PopoverContent>
        </Popover>
      </div>
    </>
  );
}
