import axios from 'axios';
import { server } from '../../server';

// get all sellers --- admin
export const getAdminUser = (admin) => async (dispatch) => {
  try {
    dispatch({
      type: 'LoadAdminRequest',
    });
    if (admin) {
      dispatch({
        type: 'LoadAdminSuccess',
        payload: admin,
      });
      return;
    }
    const { data } = await axios.get(`${server}/admin/load-admin`, {
      withCredentials: true,
    });

    if (!data || !data?.admin) {
      return;
    }

    dispatch({
      type: 'LoadAdminSuccess',
      payload: data.admin,
    });
  } catch (error) {
    dispatch({
      type: 'LoadAdminFail',
    });
  }
};
