import axios from 'axios';
import { serverV3 } from '../../server';

export const findAllBannersService = async (
  cityId: string,
  lastId?: string,
  limit?: number
) => {
  try {
    const response = await axios.get(`${serverV3}/banners`, {
      headers: { cityId },
      params: { lastId, limit },
      withCredentials: true,
    });
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};
