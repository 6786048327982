import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Loader from '../components/Layout/Loader';
import { useAppSelector } from '../hooks/common/redux.hooks';

const SellerProtectedRoute = ({ children }) => {
  const { isLoading, isSeller, seller } = useAppSelector(
    (state) => state.seller
  );
  if (isLoading === true) {
    return <Loader />;
  }
  if (!isSeller) {
    return <Navigate to={`/shop-login`} replace />;
  }
  return children;
};

export default SellerProtectedRoute;
