import { useDriverSessionStore } from '../../../store/driversSession';
import DriverHeader from './DriverMainHeader';
import DriverSideBar from './DriverSideBar';

interface DriverLayoutProps {
  active: number;
  children: React.ReactNode;
}
const DriverLayout = ({ active, children }: DriverLayoutProps) => {
  const { driverSession } = useDriverSessionStore();

  return (
    <>
      <DriverHeader />
      <div className='flex w-full'>
        <div className='flex w-full'>
          <section className='w-20 md:w-80'>
            <DriverSideBar active={active} />
          </section>
          {children}
        </div>
      </div>
    </>
  );
};

export default DriverLayout;
