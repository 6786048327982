import { useState } from 'react';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { Link, Navigate } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

import styles from '../../styles/styles';
import { ReactComponent as DriverIcon } from '../../Assests/driver01.svg';
import { signInDriverService } from '../../services/drivers/auth';
import { useDriverSessionStore } from '../../store/driversSession';

const DriversLogin = () => {
  const { singInDriver, driverSession, token } = useDriverSessionStore();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);

  if (driverSession && token) {
    return <Navigate to='/drivers-dashboard' />;
  }
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (email === '' || password === '') {
      toast.error('Todos los campos son requeridos');
      return;
    }
    const { success, message, data } = await signInDriverService({
      user: email,
      password,
    });
    if (!success || !data) {
      toast.error(message);
      return;
    }

    Cookies.set('driver_token', `${data.token}`);
    singInDriver(data.driverRes, data.token);
  };

  return (
    <div>
      <section className='mx-auto flex h-14 w-full items-center bg-black px-2'>
        <div className='mx-auto w-2/3'>
          <p className='text-primary-50'>
            ¿Tienes un negocio?{' '}
            <Link className='text-primary-200' to={'/shop-login'}>
              Empieza a vender
            </Link>
          </p>
        </div>
      </section>

      <div className='mx-auto h-screen w-11/12  flex-1  p-2 text-lg  md:p-4 xl:w-2/3'>
        <div className='flex h-full w-full flex-col justify-center py-12 sm:px-6 lg:px-8'>
          <section className='flex w-full flex-col items-center md:flex-row md:items-start'>
            <section className='flex w-2/4 flex-col items-center gap-2'>
              <DriverIcon className='h-28 md:h-48' />
              <div className='mt-2 w-full self-start'>
                <h2 className='text-xl font-bold'>Inicia sesión</h2>
                <p>Unete a la red de repartidores de Merkoline</p>
              </div>
            </section>

            <section className='w-2/4'>
              <div className='sm:mx-auto sm:w-full sm:max-w-md'>
                <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
                  Inicia sesión y entrega tus pedidos
                </h2>
              </div>

              <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
                <div className='bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10'>
                  <form className='space-y-6' onSubmit={handleSubmit}>
                    <div>
                      <label
                        htmlFor='email'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Correo
                      </label>
                      <div className='mt-1'>
                        <input
                          type='email'
                          name='email'
                          autoComplete='email'
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className={`${styles.default_input}`}
                        />
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor='password'
                        className='block text-sm font-medium text-gray-700'
                      >
                        Contraseña
                      </label>
                      <div className='relative mt-1'>
                        <input
                          type={visible ? 'text' : 'password'}
                          name='password'
                          autoComplete='current-password'
                          required
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className={`${styles.default_input}`}
                        />
                        {visible ? (
                          <AiOutlineEye
                            className='absolute right-2 top-2 cursor-pointer'
                            size={25}
                            onClick={() => setVisible(false)}
                          />
                        ) : (
                          <AiOutlineEyeInvisible
                            className='absolute right-2 top-2 cursor-pointer'
                            size={25}
                            onClick={() => setVisible(true)}
                          />
                        )}
                      </div>
                    </div>
                    {/* <div className={`${styles.noramlFlex} justify-between`}>
                      <div className={`${styles.noramlFlex}`}>
                        <input
                          type='checkbox'
                          name='remember-me'
                          id='remember-me'
                          className='h-4 w-4 rounded border-gray-300 text-primary-200 focus:ring-primary-200'
                        />
                        <label
                          htmlFor='remember-me'
                          className='ml-2 block text-sm text-gray-900'
                        >
                          Recordarme
                        </label>
                      </div>
                      <div className='text-sm'>
                        <a
                          href='/drivers/forgot-password'
                          className='font-medium text-primary-200 hover:text-primary-200'
                        >
                          ¿Olvidaste tu contraseña?
                        </a>
                      </div>
                    </div> */}
                    <div>
                      <button
                        type='submit'
                        className='group relative flex h-[40px] w-full justify-center rounded-md border border-transparent bg-primary-200 px-4 py-2 text-sm font-medium text-white hover:bg-primary-500'
                      >
                        Iniciar sesión
                      </button>
                    </div>
                    <div className={`${styles.noramlFlex} w-full`}>
                      <h4>¿No tienes cuenta?</h4>
                      <Link
                        to='/drivers-signup'
                        className='pl-2 text-primary-200'
                      >
                        Registrate
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
    </div>
  );
};

export default DriversLogin;
