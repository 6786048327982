import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  error: false,
  isAdmin: false,
  isAuthenticated: false,
  admin: null,
  sellers: null
};

export const adminReducer = createReducer(initialState, {
  LoadAdminRequest: (state) => {
    state.isLoading = true;
  },
  LoadAdminSuccess: (state, action) => {
    state.isAdmin = true;
    state.isLoading = false;
    state.isAuthenticated = true;
    state.admin = action.payload;
  },
  LoadAdminFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.isAdmin = false;
  },

  // get all sellers ---admin
  getAllSellersRequest: (state) => {
    state.isLoading = true;
  },
  getAllSellersSuccess: (state, action) => {
    state.isLoading = false;
    state.sellers = action.payload;
  },
  getAllSellerFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
});
