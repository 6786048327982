import { SubmitHandler, useForm } from 'react-hook-form';
import { RxCross2 } from 'react-icons/rx';
import { FaCheck } from 'react-icons/fa';

import MKDrawer from '../../../../UI/MKDrawer';
import MKButton from '../../../../UI/MKButton';
import { createRegionService } from '../../../../../services/admin/region';

type Inputs = {
  name: string;
  active: boolean;
};

interface ICreateRegionDrawerProps {
  handleCloseDrawer: () => void;
  Refetch: () => void;
}

const CreateRegionDrawer = ({
  handleCloseDrawer,
  Refetch,
}: ICreateRegionDrawerProps) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {};

  const handleSaveRegion: SubmitHandler<Inputs> = async ({ name, active }) => {
    const saveRegionRes = await createRegionService({ name, active });
    if (saveRegionRes.error || saveRegionRes.success === false) {
      return;
    }
    handleCloseDrawer();
    Refetch();
  };

  return (
    <MKDrawer>
      <section className='flex justify-center'>
        <div className='w-full overflow-y-scroll rounded-md bg-white '>
          <div className='my-4 w-full shadow'>
            <div className='flex items-center justify-between px-6 pb-2 '>
              <h5 className='text-center text-xl '>
                Agrega nuevo departamento
              </h5>
              <button onClick={handleCloseDrawer} className='flex'>
                <RxCross2 size={25} className='cursor-pointer' />
              </button>
            </div>
          </div>

          <form
            className='flex flex-col gap-4 px-4'
            onSubmit={handleSubmit(handleSaveRegion)}
          >
            <div>
              <label>Departamento</label>
              <input
                {...register('name')}
                placeholder='Nombre del departamento'
                className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm'
              />
            </div>

            <div className='flex items-center gap-2'>
              <label>Activo</label>
              <div className='relative'>
                <input
                  {...register('active')}
                  defaultChecked
                  type='checkbox'
                  className='peer relative mt-1 h-6 w-6 shrink-0 appearance-none rounded-md border'
                />
                <FaCheck className='pointer-events-none absolute left-1 top-2 h-4 w-4 fill-none stroke-white peer-checked:!fill-primary-200' />
              </div>
            </div>

            <MKButton className='mt-12 w-full' type='submit'>
              <p>Guardar</p>
            </MKButton>
          </form>
        </div>
      </section>
    </MKDrawer>
  );
};

export default CreateRegionDrawer;
