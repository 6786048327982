import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineArrowRight, AiOutlineMoneyCollect } from 'react-icons/ai';
import { FaListCheck, FaWallet, FaBoxOpen } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

import { getAllOrdersOfShop } from '../../redux/actions/order';
import { getAllProductsShop } from '../../redux/actions/product';
import styles from '../../styles/styles';

const DashboardHero = () => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller.shopId));
    dispatch(getAllProductsShop(seller.shopId));
  }, [dispatch]);
  console.log('seller:', seller);
  const availableBalance = seller?.shop?.availableBalance
    ? seller.shop.availableBalance?.toFixed(2)
    : '0.00'; //seller?.shop?.availableBalance?.toFixed(2);
  const columns = [
    { field: 'id', headerName: 'Order ID', minWidth: 150, flex: 0.7 },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.getValue(params.id, 'status') === 'Delivered'
          ? 'greenColor'
          : 'redColor';
      },
    },
    {
      field: 'itemsQty',
      headerName: 'Items Qty',
      type: 'number',
      minWidth: 130,
      flex: 0.7,
    },

    {
      field: 'total',
      headerName: 'Total',
      type: 'number',
      minWidth: 130,
      flex: 0.8,
    },

    {
      field: ' ',
      flex: 1,
      minWidth: 150,
      headerName: '',
      type: 'number',
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/dashboard/order/${params.id}`}>
              <Button>
                <AiOutlineArrowRight size={20} />
              </Button>
            </Link>
          </>
        );
      },
    },
  ];

  const row = [];

  orders &&
    orders.forEach((item) => {
      row.push({
        id: item._id,
        itemsQty: item.cart.reduce((acc, item) => acc + item.quantity, 0),
        total: 'C$ ' + item.totalPrice,
        status: item.status,
      });
    });
  return (
    <div className='w-full p-8'>
      <h3 className='pb-2 font-Poppins text-[22px]'>Resumen</h3>
      <div className='block w-full items-center justify-between 800px:flex'>
        <div className='mb-4 min-h-[10vh] w-full rounded bg-white px-2 py-5 shadow 800px:w-[30%]'>
          <div className='flex items-center'>
            <FaWallet size={25} className='mr-2' fill='#00000085' />
            <h3
              className={`${styles.productTitle} !text-[18px] !font-[400] leading-5 text-gray-600`}
            >
              Saldo de la cuenta
            </h3>
          </div>
          <h5 className='pl-[36px] pt-2 text-[22px] font-[500]'>
            C${availableBalance}
          </h5>
          <Link to='/dashboard-orders'>
            <h5 className='pl-2 pt-4 text-primary-200'>Ir a mi billetera</h5>
          </Link>
        </div>

        <div className='mb-4 min-h-[10vh] w-full rounded bg-white px-2 py-5 shadow 800px:w-[30%]'>
          <div className='flex items-center'>
            <FaListCheck size={25} className='mr-2 text-gray-500' />

            <h3
              className={`${styles.productTitle} !text-[18px] !font-[400] leading-5 text-gray-600`}
            >
              Todos los pedidos
            </h3>
          </div>
          <h5 className='pl-[36px] pt-2 text-[22px] font-[500]'>
            {orders && orders.length}
          </h5>
          <Link to='/dashboard-orders'>
            <h5 className='pl-2 pt-4 text-primary-200'>Ver pedidos</h5>
          </Link>
        </div>

        <div className='mb-4 min-h-[10vh] w-full rounded bg-white px-2 py-5 shadow 800px:w-[30%]'>
          <div className='flex items-center'>
            <FaBoxOpen size={30} className='mr-2 text-gray-500' />
            <h3
              className={`${styles.productTitle} !text-[18px] !font-[400] leading-5 text-gray-600`}
            >
              Todos los productos
            </h3>
          </div>
          <h5 className='pl-[36px] pt-2 text-[22px] font-[500]'>
            {products && products.length}
          </h5>
          <Link to='/dashboard-products'>
            <h5 className='pl-2 pt-4 text-primary-200'>Ver Productos</h5>
          </Link>
        </div>
      </div>
      <br />
      <h3 className='pb-2 font-Poppins text-[22px]'>Últimos pedidos</h3>
      <div className='min-h-[45vh] w-full rounded bg-white'>
        <DataGrid
          rows={row}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          autoHeight
        />
      </div>
    </div>
  );
};

export default DashboardHero;
