import { Link } from 'react-router-dom';
import DashboardCommonLayout from '../../../../components/Layout/DashboardCommonLayout';
import DashboardSideBar from '../../../../components/Shop/Layout/DashboardSideBar';
import MyStaffList from '../../../../components/Shop/Staff/MyStaffList';
import { twMerge } from 'tailwind-merge';
import styles from '../../../../styles/styles';
import { IoMdAdd } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { SHOP_RESOURCE_ROLE_NAME } from '../../../../utils/constants/common';

const StaffList = () => {
  // @ts-ignore
  const { seller } = useSelector((state) => state.seller);

  const readPermission =
    seller?.isAdmin ||
    seller?.permission[SHOP_RESOURCE_ROLE_NAME.staff]?.permissions?.read;

  const createPermission =
    seller?.isAdmin ||
    seller?.permission[SHOP_RESOURCE_ROLE_NAME.staff]?.permissions?.create;

  return (
    <div>
      <DashboardCommonLayout>
        <div className='flex w-full justify-between'>
          <div className='w-[80px] 800px:w-[330px]'>
            <DashboardSideBar active={12} />
          </div>
          <section className='w-full'>
            <div className='item-center mb-4 flex justify-between rounded-md border-b-2 bg-white p-3 shadow-sm'>
              <div className='flex items-center px-2'>
                <h3 className='text-center text-lg'>Empleados</h3>
              </div>
              {createPermission && (
                <section id='product-actions'>
                  <Link
                    to={'/dashboard-create-staff'}
                    className={twMerge(styles.button, 'my-1 h-10 px-2')}
                  >
                    <IoMdAdd />
                    Agregar Empleado
                  </Link>
                </section>
              )}
            </div>
            {readPermission && <MyStaffList />}
          </section>
        </div>
      </DashboardCommonLayout>
    </div>
  );
};

export default StaffList;
