import axios from 'axios';
import { server } from '../../server';
import { IShopRole } from '../../datatypes/interfaces/roles';

export const createRoleService = async (role: IShopRole) => {
  try {
    const result = await axios.post(`${server}/role/create`, role, {
      withCredentials: true,
    });
    if (result) {
      console.log('result create role:', result);
    }
    return result;
  } catch (error) {
    return { status: 500 };
  }
};

export const editRoleService = async (role: IShopRole, roleId: string) => {
  try {
    const result = await axios.put(
      `${server}/role/edit`,
      { role, roleId },
      {
        withCredentials: true,
      }
    );
    return result;
  } catch (error) {
    return { status: 500 };
  }
};

export const getShopRolesService = async () => {
  try {
    const { data } = await axios.get(`${server}/role/get-shop-roles`, {
      withCredentials: true,
    });

    return data;
  } catch (error) {
    return { status: 500 };
  }
};
