import DriversDashboard from '../../components/Drivers/DriversDashboard';
import { DriverLayout } from '../../components/Layout/DriverLayout';
import { DRIVER_NAVIGATION } from '../../utils/constants/navigation';

const DriversDashboardPage = () => {
  return (
    <>
      <DriverLayout active={DRIVER_NAVIGATION.dashboard.id}>
        <DriversDashboard />
      </DriverLayout>
    </>
  );
};

export default DriversDashboardPage;
