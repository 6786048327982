import { createReducer } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  isPotentialPartnerLoading: false,
  potentialPartners: null,
  seller: null,
  sellers: null,
};

export const sellerReducer = createReducer(initialState, {
  LoadSellerRequest: (state) => {
    state.isLoading = true;
  },
  LoadSellerSuccess: (state, action) => {
    state.isSeller = true;
    state.isLoading = false;
    state.seller = action.payload;
  },

  LoadSellerFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.isSeller = false;
  },

  // get all approved sellers ---admin
  getAllSellersRequest: (state) => {
    state.isLoading = true;
  },
  getAllSellersSuccess: (state, action) => {
    state.isLoading = false;
    state.sellers = action.payload;
  },
  getAllSellerFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },
  // get potential partners: waiting approval --admin
  LoadPotentialPartnerRequest: (state) => {
    state.isPotentialPartnerLoading = true;
    state.partnerError = false;
  },
  LoadPotentialPartnerSuccess: (state, action) => {
    state.isPotentialPartnerLoading = false;
    state.partnerError = false;
    state.potentialPartners = action.payload;
  },
  LoadPotentialPartnerError: (state) => {
    state.isPotentialPartnerLoading = false;
    state.partnerError = true;
    state.potentialPartners = null;
  },
  CreateBannerRequest: (state) => {
    state.isLoading = true;
  },
  CreateBannerSuccess: (state, action) => {
    state.isLoading = false;
    state.banner = action.payload;
  },
  CreateBannerFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
});
