import { useState } from 'react';
import {
  AiOutlineEye,
  AiOutlineEyeInvisible,
  AiOutlineClose,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { RxAvatar } from 'react-icons/rx';
import { getCountryDataList, getEmojiFlag } from 'countries-list';

import { server } from '../../server';
import MKButton from '../UI/MKButton';
import styles from '../../styles/styles';
import MKDrawer from '../UI/MKDrawer';
import { MapApp } from '../UI/GoogleMap';
import { getGeocode } from 'use-places-autocomplete';
import { useSelector } from 'react-redux';
import MKInput from '../UI/MKInput';
import Loader from '../Layout/Loader';

const ShopCreate = () => {
  const { configuration } = useSelector((state) => state.configuration);
  console.log('configuration:', configuration);
  const allCountries = getCountryDataList();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState();
  const [prevAddress, setprevAddress] = useState(null);
  const [address, setAddress] = useState(null);
  const [avatar, setAvatar] = useState();
  const [password, setPassword] = useState('');
  const [visible, setVisible] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedCountry, setselectedCountry] = useState(
    configuration ? configuration.country_code : 'NI'
  );

  const handleDrawer = () => {
    setDrawerOpen((prevState) => !prevState);
  };
  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };
  const handleSetAddress = () => {
    setDrawerOpen(false);
    setAddress({ ...prevAddress });
  };
  const handleSelectCountry = (value) => {
    setselectedCountry(value);
  };

  const handleChangeLocation = async (address) => {
    try {
      const [addressInfo, ...rest] = await getGeocode({ location: address });
      setprevAddress({
        coordinates: [address.lng, address.lat],
        neighborhood: addressInfo.address_components[1].long_name,
        city: addressInfo.address_components[3].long_name,
        region: addressInfo.address_components[4].long_name,
      });
    } catch (error) {} // setDrawerOpen(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const countryCode = allCountries.find(
      (country) => country.iso2 === selectedCountry
    );
    const phoneWithCountryCode = `+${countryCode.phone[0]}${phoneNumber}`;

    axios
      .post(`${server}/shop/create-shop`, {
        name,
        email,
        password,
        avatar,
        address,
        phoneNumber: phoneWithCountryCode,
      })
      .then((res) => {
        toast.success(res.data.message);
        setName('');
        setEmail('');
        setPassword('');
        setAvatar();
        setPhoneNumber();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleFileInputChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <div className='flex min-h-screen flex-col justify-center bg-gray-50 py-12 sm:px-6 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md'>
        <h2 className='mt-6 text-center text-3xl font-bold text-gray-900'>
          Registra tu negocio
        </h2>
      </div>
      <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-[35rem]'>
        <div className='bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10'>
          <form className='space-y-6' onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor='email'
                className='block text-sm font-medium text-gray-700'
              >
                Nombre de la tienda
              </label>
              <div className='mt-1'>
                <input
                  type='name'
                  name='name'
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={`${styles.default_input}`}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor='phone-number'
                className='block text-sm font-medium text-gray-700'
              >
                Número de teléfono
              </label>
              <section className='my-2 flex w-full items-center rounded-xl border bg-gray-100 '>
                <select
                  onChange={(e) => handleSelectCountry(e.target.value)}
                  id='countries'
                  className='ml-2 block w-10 border-r border-r-gray-400 bg-transparent text-xl text-gray-900 focus:border-primary-500 focus:ring-primary-500 '
                >
                  {allCountries.map((country) => (
                    <option
                      value={country.iso2}
                      selected={country.iso2 === selectedCountry}
                    >
                      <p className='text-3xl'>{getEmojiFlag(country.iso2)}</p>
                      <span className='ml-2 text-sm'>{country.native}</span>
                    </option>
                  ))}
                </select>
                <MKInput
                  className='ml-2 w-full rounded-none  border-none bg-transparent'
                  type='number'
                  placeholder='Número de teléfono'
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </section>
            </div>

            <div>
              <label
                htmlFor='email'
                className='block text-sm font-medium text-gray-700'
              >
                Correo
              </label>
              <div className='mt-1'>
                <input
                  type='email'
                  name='email'
                  autoComplete='email'
                  required
                  value={email}
                  placeholder='Ingresa tu correo'
                  onChange={(e) => setEmail(e.target.value)}
                  className={`${styles.default_input}`}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor='address'
                className='block text-sm font-medium text-gray-700'
              >
                Dirección
              </label>
              <MKButton
                type='button'
                variant='text'
                onClick={handleDrawer}
                className={`${styles.default_input}`}
              >
                <p className='line-clamp-1 text-gray-500'>
                  {address
                    ? `${address.neighborhood} - ${address.city}`
                    : 'Seleccionar ubicación en el mapa'}
                </p>
              </MKButton>
            </div>

            <div>
              <label
                htmlFor='password'
                className='block text-sm font-medium text-gray-700'
              >
                Contraseña
              </label>
              <div className='relative mt-1'>
                <input
                  type={visible ? 'text' : 'password'}
                  name='password'
                  autoComplete='current-password'
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={`${styles.default_input}`}
                />
                {visible ? (
                  <AiOutlineEye
                    className='absolute right-2 top-2 cursor-pointer'
                    size={25}
                    onClick={() => setVisible(false)}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className='absolute right-2 top-2 cursor-pointer'
                    size={25}
                    onClick={() => setVisible(true)}
                  />
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor='avatar'
                className='block text-sm font-medium text-gray-700'
              ></label>
              <div className='mt-2 flex items-center'>
                <span className='inline-block h-8 w-8 overflow-hidden rounded-full'>
                  {avatar ? (
                    <img
                      src={avatar}
                      alt='avatar'
                      className='h-full w-full rounded-full object-cover'
                    />
                  ) : (
                    <RxAvatar className='h-8 w-8' />
                  )}
                </span>
                <label
                  htmlFor='file-input'
                  className='ml-5 flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50'
                >
                  <span>Cargar imágen</span>
                  <input
                    type='file'
                    name='avatar'
                    id='file-input'
                    onChange={handleFileInputChange}
                    className='sr-only'
                  />
                </label>
              </div>
            </div>

            <div>
              <button
                type='submit'
                className='group relative flex h-[40px] w-full justify-center rounded-md border border-transparent bg-primary-200 px-4 py-2 text-sm font-medium text-white hover:bg-primary-500'
              >
                Registrarte
              </button>
            </div>
            <section className={`${styles.noramlFlex} w-full`}>
              <h4>¿Ya tienes una cuenta?</h4>
              <Link to='/shop-login' className='pl-2 text-primary-200'>
                Inicia sesión
              </Link>
            </section>
          </form>
        </div>
        {drawerOpen && (
          <MKDrawer>
            <section className='flex flex-col gap-4 p-3'>
              <section className='flex items-center justify-between gap-2'>
                <p className='mb-2 text-center text-lg font-bold'>
                  Agregar dirección
                </p>
                <MKButton
                  onClick={handleCloseDrawer}
                  variant='text'
                  className='flex h-10 w-10 items-center justify-center rounded-full border border-gray-300 bg-white'
                >
                  <AiOutlineClose size={25} className='text-primary-500' />
                </MKButton>
              </section>
              <MapApp
                width='100%'
                height='40vh'
                coordenadas={
                  address
                    ? {
                        lat: address.coordinates[1],
                        lng: address.coordinates[0],
                      }
                    : null
                }
                includeRadioInMap={false}
                enableSearch={false}
                onChangeLocation={handleChangeLocation}
              />
              <MKButton
                onClick={handleSetAddress}
                className=' self-center px-6'
              >
                <p>Seleccionar</p>
              </MKButton>
            </section>
          </MKDrawer>
        )}
      </div>
    </div>
  );
};

export default ShopCreate;
