import axios from 'axios';
import { useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { RxCross2 } from 'react-icons/rx';

import MKDrawer from '../../../UI/MKDrawer';
import { server } from '../../../../server';
import SelectCategory from '../Common/SelectCategory';
import useShopProducts from '../../../../hooks/products/useShopProducts';

import styles from '../../../../styles/styles';
import { IUpdateProduct } from '../../../../interfaces/products/createProduct';
import useCategories from '../../../../hooks/category/useCategoriesData';
import { useAppSelector } from '../../../../hooks/common/redux.hooks';

interface IEditProduct {
  currentProduct: IUpdateProduct;
  handleCloseModal: () => void;
}

const EditProduct = ({ currentProduct, handleCloseModal }: IEditProduct) => {
  const { categories } = useCategories();
  const { seller } = useAppSelector((state) => state.seller);

  const currentCategories =
    Array.isArray(currentProduct.categories) && Array.isArray(categories)
      ? currentProduct.categories.reduce((a, v) => {
          const matchCategoryDetails = categories.find(
            (categoryDB) => categoryDB._id === v
          );
          if (matchCategoryDetails) {
            return { ...a, [v]: { ...matchCategoryDetails } };
          }
          return { ...a };
        }, {})
      : {};

  const [selectedCategory, setSelectedCategory] = useState(currentCategories);
  const { clearState, refetchProducts } = useShopProducts({
    shopId: seller.shopId,
  });

  const [name, setName] = useState(currentProduct?.name || '');
  const [isActive, setIsActive] = useState<boolean>(currentProduct?.isActive);
  const [description, setDescription] = useState(currentProduct?.name || '');

  const [originalPrice, setOriginalPrice] = useState(
    currentProduct?.originalPrice || ''
  );
  const [discountPrice, setDiscountPrice] = useState(
    currentProduct?.discountPrice || ''
  );
  const [stock, setStock] = useState(currentProduct?.stock || '');
  const [images, setImages] = useState(currentProduct?.images || []);

  const onChangeSelected = (newSelected) => {
    setSelectedCategory(newSelected);
  };

  const handleSubmit = async () => {
    if (name === '' || Object.keys(selectedCategory).length === 0) {
      toast.error('Por favor completar los campos requeridos');
      return;
    }

    const categoryIds = Object.keys(selectedCategory).map(
      (cat) => selectedCategory[cat]._id
    );

    await axios
      .put(
        `${server}/product/update-product`,
        {
          _id: currentProduct._id,
          name,
          description,
          categories: categoryIds,
          originalPrice,
          discountPrice,
          stock,
          isActive,
        },
        { withCredentials: true }
      )
      .then((res) => {
        refetchProducts();
        toast.success('Producto actualizado con éxito');
        handleCloseModal();
      })
      .catch((err) => {
        toast.error('Disculpa, ha ocurrido un error');
      });
    clearState();
  };
  const handleImageChange = () => {};
  if (!currentProduct) {
    return <p>Loading...</p>;
  }
  return (
    <MKDrawer>
      <div className='flex justify-center'>
        <div className='w-full overflow-y-scroll rounded-md bg-white shadow'>
          <div className='my-4 w-full border-b'>
            <div className='flex items-center justify-between px-6 pb-2 shadow'>
              <h5 className='text-center text-xl'>Editar producto</h5>
              <button onClick={handleCloseModal} className='flex'>
                <RxCross2 size={25} className='cursor-pointer' />
              </button>
            </div>
          </div>

          {/* create product form */}
          <section className='flex flex-col gap-2 px-6 md:gap-3'>
            <div>
              <label className='pb-2'>
                Nombre <span className='text-red-500'>*</span>
              </label>
              <input
                type='text'
                name='name'
                value={name}
                className={styles.default_input}
                onChange={(e) => setName(e.target.value)}
                placeholder='Ingrese el nombre del producto...'
              />
            </div>

            <div>
              <label className='pb-2'>
                Description <span className='text-red-500'>*</span>
              </label>
              <textarea
                cols={30}
                required
                rows={8}
                name='description'
                value={description}
                className={styles.default_input}
                onChange={(e) => setDescription(e.target.value)}
                placeholder='Enter your product description...'
              ></textarea>
            </div>

            <div>
              <SelectCategory
                selectedCategory={selectedCategory}
                onChangeSelected={onChangeSelected}
              />
            </div>

            <div>
              <label className='pb-2'>
                Precio <span className='text-red-500'>*</span>
              </label>
              <input
                type='number'
                name='price'
                step='any'
                value={originalPrice}
                className={styles.default_input}
                onChange={(e) => setOriginalPrice(e.target.value)}
                placeholder='Enter your product price...'
              />
            </div>
            <div>
              <label className='pb-2'>Precio (Con descuento)</label>
              <input
                type='number'
                name='price'
                step='any'
                value={discountPrice}
                className={styles.default_input}
                onChange={(e) => setDiscountPrice(e.target.value)}
                placeholder='Enter your product price with discount...'
              />
            </div>
            <div>
              <label className='pb-2'>
                Existencias de productos <span className='text-red-500'>*</span>
              </label>
              <input
                type='number'
                name='price'
                value={stock}
                className={styles.default_input}
                onChange={(e) => setStock(e.target.value)}
                placeholder='Enter your product stock...'
              />
            </div>
            <div>
              <label className='pb-2'>
                Imagenes <span className='text-red-500'>*</span>
              </label>
              <input
                type='file'
                name=''
                id='upload'
                className='hidden'
                multiple
                onChange={handleImageChange}
              />
              <div className='flex w-full flex-wrap items-center'>
                <label htmlFor='upload'>
                  <AiOutlinePlusCircle
                    size={30}
                    className='mt-3'
                    color='#555'
                  />
                </label>
                {Array.isArray(images) &&
                  images.map((i) => (
                    <img
                      src={i.url}
                      key={i._id}
                      alt=''
                      className='m-2 h-[120px] w-[120px] object-cover'
                    />
                  ))}
              </div>
              <div className='flex items-center gap-2 pt-2'>
                <input
                  defaultChecked={isActive}
                  onChange={() => setIsActive(!isActive)}
                  className='h-4 w-4 accent-primary-200'
                  type='checkbox'
                />
                <label htmlFor='isActive'>Activo</label>
              </div>
              <br />
              <div className='flex justify-center'>
                <button
                  onClick={handleSubmit}
                  className={`${styles.button} w-2/3`}
                >
                  Guardar cambios
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
    </MKDrawer>
  );
};

export default EditProduct;
