import { FaCheck, FaMapMarkedAlt, FaMotorcycle } from 'react-icons/fa';
import { useAdminDrivers } from '../../../hooks/admin/useAdminDrivers';
import EmptyState from '../../Common/EmptyState';
import MKButton from '../../UI/MKButton';
import MKCard from '../../UI/MKCard';
import { MdMail, MdOutlinePhoneAndroid } from 'react-icons/md';
import { useState } from 'react';
import DriverDetails from './DriverDetails';
import { Driver } from '../../../interfaces/admin/driver';

const ActiveDrivers = () => {
  const { drivers } = useAdminDrivers();
  const [driverDetails, setDriverDetails] = useState<Driver | null>(null);
  const handleShowDriver = (driver: Driver) => {
    setDriverDetails(driver);
  };
  const handleHideDriver = () => {
    setDriverDetails(null);
  };
  return (
    <div>
      {Array.isArray(drivers) && drivers.length === 0 && (
        <>
          <EmptyState width={140} height={140} />
          <p className='text-center text-base md:text-xl'>
            No tienes repartidores aprobados
          </p>
        </>
      )}

      {Array.isArray(drivers) && drivers.length > 0 && (
        <section className='flex flex-col gap-2'>
          {drivers.map((driver) => (
            <MKButton
              variant='none'
              key={`potential-driver-${driver._id}`}
              className='w-full'
              onClick={() => handleShowDriver(driver)}
            >
              <MKCard className='flex flex-col gap-4 text-left'>
                <section className='border-b pb-2'>
                  <section className='flex items-center justify-between '>
                    <p>
                      {driver.firstName} {driver.lastName}
                    </p>
                  </section>
                </section>

                <div className='flex flex-wrap gap-2 md:gap-8'>
                  {/* general info */}
                  <section className='flex w-full flex-col gap-2 sm:w-auto  md:flex-row md:gap-8'>
                    <section className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
                      <div className='rounded-lg bg-primary-100 p-2'>
                        <MdMail className='text-primary-500' />
                      </div>
                      <div>
                        <p className='text-left font-semibold'>Correo</p>
                        <p className='text-gray-700'>{driver.email}</p>
                      </div>
                    </section>
                    <section className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
                      <div className='rounded-lg bg-primary-100 p-2'>
                        <MdOutlinePhoneAndroid className='text-primary-500' />
                      </div>
                      <div>
                        <p className='font-semibold'>Teléfono</p>
                        <p className='text-gray-700'>{driver.phoneNumber}</p>
                      </div>
                    </section>
                    <div className='flex items-center gap-2  rounded-lg border border-dashed p-2 md:px-4'>
                      <div className='rounded-lg bg-primary-100 p-2'>
                        <FaMapMarkedAlt className='text-primary-500' />
                      </div>
                      <div>
                        <p className='font-semibold'>Ciudad</p>
                        <p className='text-gray-700'>{driver.city} </p>
                      </div>
                    </div>
                    <div className='flex items-center gap-2  rounded-lg border border-dashed p-2 md:px-4'>
                      <div className='rounded-lg bg-primary-100 p-2'>
                        <FaMotorcycle className='text-primary-500' />
                      </div>
                      <div>
                        <p className='font-semibold'>Vehículo</p>
                        <p className='text-gray-700'>
                          {driver.vehicleInfo.vehicleType}
                        </p>
                      </div>
                    </div>
                  </section>
                </div>
              </MKCard>
            </MKButton>
          ))}
        </section>
      )}
      {driverDetails && (
        <DriverDetails
          handleHideDriver={handleHideDriver}
          driver={driverDetails}
        />
      )}
    </div>
  );
};

export default ActiveDrivers;
