import React, { useState } from 'react';
import MKDrawer from '../../UI/MKDrawer';
import { IoClose, IoMailOutline } from 'react-icons/io5';
import {
  MdOutlineHideImage,
  MdOutlineLocationOn,
  MdOutlinePhoneAndroid,
} from 'react-icons/md';
import { FaInfoCircle, FaMapMarkedAlt, FaMapPin } from 'react-icons/fa';
import { BiMessageSquareDetail } from 'react-icons/bi';
import { MapApp } from '../../UI/GoogleMap';

interface IAdditionalDetails {
  shop: any;
}
const AdditionalDetails = ({ shop }: IAdditionalDetails) => {
  const [openShopExtraInfo, setOpenShopExtraInfo] = useState(false);
  const openDrawer = () => {
    setOpenShopExtraInfo(true);
  };

  const closeDrawer = () => {
    setOpenShopExtraInfo(false);
  };

  return (
    <>
      <button onClick={openDrawer} className='self-center'>
        <FaInfoCircle className='h-8 w-8 text-primary-500' />
      </button>
      {openShopExtraInfo && (
        <MKDrawer>
          <section className='my-2 flex flex-col gap-2 p-2 md:my-4'>
            <div className='flex justify-between'>
              <div className='flex items-center gap-2'>
                {shop.avatar ? (
                  <img className='h-6 w-6' src={shop.avatar.url} alt='' />
                ) : (
                  <MdOutlineHideImage className='h-6 w-6 text-gray-400' />
                )}
                <p className='text-xl font-semibold'> {shop.name}</p>
              </div>
              <button
                onClick={closeDrawer}
                className='rounded-full border border-gray-600 px-2 py-2'
              >
                <IoClose />
              </button>
            </div>

            <section className='my-2 flex items-center'>
              <BiMessageSquareDetail className='h-6 w-6 text-primary-500' />
              <p className='text-lg font-semibold text-primary-500'>
                Información general
              </p>
            </section>
            <section className='flex items-center gap-2 border-b border-dashed'>
              <div className='rounded-lg bg-primary-100 p-2'>
                <IoMailOutline className='text-primary-500' />
              </div>
              <div>
                <p className='font-semibold'>Correo</p>
                <p className='text-gray-700'>{shop.email}</p>
              </div>
            </section>

            <section className='flex items-center gap-2 border-b border-dashed'>
              <div className='rounded-lg bg-primary-100 p-2'>
                <MdOutlinePhoneAndroid className='text-primary-500' />
              </div>
              <div>
                <p className='font-semibold'>Teléfono</p>
                <p className='text-gray-700'>{shop.phoneNumber}</p>
              </div>
            </section>

            <section className='my-2 flex items-center'>
              <MdOutlineLocationOn className='h-6 w-6 text-primary-500' />
              <p className='text-lg font-semibold text-primary-500'>
                Ubicación
              </p>
            </section>
            <section className='flex items-center gap-2 border-b border-dashed'>
              <div className='rounded-lg bg-primary-100 p-2'>
                <FaMapMarkedAlt className='text-primary-500' />
              </div>
              <div>
                <p className='font-semibold'>{shop.address.region}</p>
                <p className='text-gray-700'>{shop.address.city}</p>
              </div>
            </section>
            <section className='flex items-center gap-2 border-b border-dashed'>
              <div className='rounded-lg bg-primary-100 p-2'>
                <FaMapPin className='text-primary-500' />
              </div>
              <div>
                <p className='font-semibold'>Barrio / sector</p>
                <p className='text-gray-700'>{shop.address.neighborhood}</p>
              </div>
            </section>
            <MapApp
              width='100%'
              height='50vh'
              coordenadas={{
                lat: shop.address.coordinates[1],
                lng: shop.address.coordinates[0],
              }}
              includeRadioInMap={false}
              enableSearch={false}
              enableChangeLocation={false}
            />
          </section>
        </MKDrawer>
      )}
    </>
  );
};

export default AdditionalDetails;
