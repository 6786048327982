import OrderDetails from '../../components/Shop/OrderDetails/OrderDetails';
import DashboardCommonLayout from '../../components/Layout/DashboardCommonLayout';
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';

const ShopOrderDetails = () => {
  return (
    <DashboardCommonLayout>
      <div className='flex w-full justify-between'>
        <div className='w-[80px] 800px:w-[330px]'>
          <DashboardSideBar active={2} />
        </div>
        <div className='w-full'>
          <OrderDetails />
        </div>
      </div>
    </DashboardCommonLayout>
  );
};

export default ShopOrderDetails;
