// @ts-nocheck
import React, { useCallback, useRef } from 'react';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  Circle,
} from '@react-google-maps/api';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';

import MKCard from '../MKCard';

import { mapStyles } from './MapStyles';
import { useDispatch } from 'react-redux';
import { changeCityLocation } from '../../../redux/actions/city';

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
};

const apiKey = 'AIzaSyBOtRYq3YaMgSDqnA_zoMejQ7P8GUWAGrM';

interface IMapProps {
  height: string;
  width: string;
  coordenadas?: IMarker | null;
  enableSearch?: boolean;
  enableChangeLocation?: boolean;
  radio?: number;
  includeRadioInMap?: boolean;
  onChangeLocation?: (address) => void;
}
interface IMarker {
  lat: string;
  lng: string;
}
export const MapApp = (props: IMapProps) => {
  const {
    height,
    width,
    coordenadas,
    radio = 8000,
    enableSearch = true,
    enableChangeLocation = true,
    includeRadioInMap = true,
    onChangeLocation,
  } = props;

  const center = coordenadas
    ? coordenadas
    : {
        lat: 12.1271712,
        lng: -86.267002,
      };
  const dispatch = useDispatch();
  const mapContainerStyle = {
    height: height,
    width: width,
    borderStyle: '1px solid #FFFFFFF',
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: ['places'],
  });
  const [markers, setMarkers] = React.useState(
    coordenadas != undefined || coordenadas != null ? [coordenadas] : []
  );

  const onMapClick = React.useCallback(
    (e) => {
      if (!enableChangeLocation) {
        return;
      }
      setMarkers([
        {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        },
      ]);
      if (onChangeLocation) {
        onChangeLocation({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        });

        return;
      }
      // Default dispatch if location changes
      dispatch(
        changeCityLocation({
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
        })
      );
      // mapArr is to pass lat and lng destructured to the store
      // const [mapArr] = markers;
    },
    [markers]
  );

  const mapRef = useRef<google.maps.Map>();
  const onMapLoad = useCallback((map: google.maps.Map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(8);
    }
  }, []);

  if (loadError) return <p>Error</p>;
  if (!isLoaded) return <p>Loading...</p>;

  return (
    <div className='w-100 h-100 position-relative'>
      {/* <Locate panTo={panTo} /> */}
      {enableSearch && <Search panTo={panTo} />}

      <GoogleMap
        id='map'
        mapContainerStyle={mapContainerStyle}
        zoom={14}
        center={center}
        options={options}
        onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {markers.length > 0 &&
          markers.map((marker) => (
            <>
              <Marker
                key={`${marker.lat}-${marker.lng}`}
                position={{ lat: marker.lat, lng: marker.lng }}
              />
              {includeRadioInMap && (
                <Circle
                  options={{ strokeColor: '#215252' }}
                  center={{ lat: marker.lat, lng: marker.lng }}
                  radius={radio}
                />
              )}
            </>
          ))}
      </GoogleMap>
    </div>
  );
};

// function Locate({ panTo }) {
//   return (
//     <button
//       type='button'
//       className='locate'
//       onClick={(event) => {
//         event.preventDefault();
//         navigator.geolocation.getCurrentPosition(
//           (position) => {
//             panTo({
//               lat: position.coords.latitude,
//               lng: position.coords.longitude,
//             });
//           },
//           () => null
//         );
//         return false;
//       }}
//     >
//       <FaRegCompass />
//     </button>
//   );
// }

function Search({ panTo }) {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      // this options is to search places near to the below coordinates
      location: { lat: () => 12.1271712, lng: () => -86.267002 },
      radius: 5 * 1000,
    },
  });
  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = await getLatLng(results[0]);
      panTo({ lat, lng });
    } catch (error) {
      // console.log('😱 Fatality: ', error);
    }
  };

  return (
    <MKCard className='search overflow-hidden'>
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          className='rounded border-0'
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder='Busca tu Ciudad'
        />
        <ComboboxPopover>
          <ComboboxList>
            {status === 'OK' &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </MKCard>
  );
}
