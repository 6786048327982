import React, { InputHTMLAttributes } from 'react';
import { cn } from '../../../utils/cn';
import { twMerge } from 'tailwind-merge';

interface IMKInputProps extends InputHTMLAttributes<HTMLInputElement> {
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
}

const MKInput = ({
  startIcon,
  endIcon,
  type = 'text',
  className = '',
  ...props
}: IMKInputProps) => {
  return (
    <section className='relative items-center gap-2'>
      {startIcon && (
        <div className='absolute left-1.5 top-1/2 -translate-y-1/2 transform'>
          {startIcon}
        </div>
      )}
      <input
        className={twMerge(
          'placeholder:text-muted-foreground flex h-12 w-full rounded-xl border border-input px-4 py-2 text-sm ring-offset-background file:border-0 file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50',
          startIcon ? 'pl-8' : '',
          className
        )}
        type={type}
        {...props}
      ></input>
      {endIcon && (
        <div className='absolute right-1.5 top-1/2 -translate-y-1/2 transform'>
          {endIcon}
        </div>
      )}
    </section>
  );
};

export default MKInput;
