import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllProductsShop } from '../../redux/actions/product';
import { getAllEventsShop } from '../../redux/actions/event';
import ProductInShopDetail from '../Route/ProductCard/ProductInShopDetail';

const ShopProfileData = ({ isOwner, data }) => {
  const { products } = useSelector((state) => state.products);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductsShop(id));
    dispatch(getAllEventsShop(id));
  }, []);

  return (
    <div className='w-full'>
      <div className='flex flex-wrap gap-4 md:mb-12'>
        {products &&
          products.map((product, index) => (
            <>
              <ProductInShopDetail
                data={product}
                key={index}
                isShopDetail={true}
                shopId={id}
              />
            </>
          ))}
      </div>
    </div>
  );
};

export default ShopProfileData;
