import React, { useState } from 'react';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '../../UI/MKDrawer';
import MKButton from '../../UI/MKButton';
import { MdOutlineClose } from 'react-icons/md';
import { Banner } from '../../../interfaces/admin/banners';
import CreateBannerForm from './CreateBannerForm';
import { useBanners } from '../../../hooks/banners/useBanners';

interface BannerDetailsProps {
  banner: Banner;
}

const BannerDetails = ({ banner }: BannerDetailsProps) => {
  const { fetchBanners } = useBanners();

  const [open, setOpen] = useState(false);

  const handleSetClose = () => {
    fetchBanners("")
    setOpen(false);
  }
  return (
    <>
      <Drawer open={open} onOpenChange={setOpen}>
        <DrawerTrigger className='px-4 py-2 border border-primary-200 text-primary-200 rounded-md'>Ver detalles</DrawerTrigger>
        <DrawerContent className='px-4 md:px-24 my-2'>
          <DrawerHeader className='flex gap-4 border-b border-gray-400 p-0 py-2'>
            <DrawerClose className='rounded-full bg-gray-200'>
              <MKButton variant='text'>
                <MdOutlineClose size={25} />
              </MKButton>
            </DrawerClose>
            <section>
              <DrawerTitle>Banner</DrawerTitle>
              <DrawerDescription>Modificar banner.</DrawerDescription>
            </section>
          </DrawerHeader>
          <section className='py-2 md:py-4'>
            <CreateBannerForm banner={banner} handleClose={handleSetClose} />
          </section>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default BannerDetails;
