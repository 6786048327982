// @ts-nocheck
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DashboardCommonLayout from '../../../../components/Layout/DashboardCommonLayout';
import DashboardSideBar from '../../../../components/Shop/Layout/DashboardSideBar';
import styles from '../../../../styles/styles';
import MKCard from '../../../../components/UI/MKCard';
import { getShopRolesService } from '../../../../services/roles/roles';
import { toast } from 'react-toastify';
import { IoShieldCheckmark } from 'react-icons/io5';
import EditRole from '../EditRole';
import ReadRole from '../ReadRole';
import { twMerge } from 'tailwind-merge';
import { IoMdAdd } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { SHOP_RESOURCE_ROLE_NAME } from '../../../../utils/constants/common';

const RoleList = () => {
  const [roles, setRoles] = useState([]);
  const [roleDetail, setRoleDetail] = useState();
  const [openEditRole, setOpenEditRole] = useState(false);
  const [editRoleDrawer, setEditRoleDrawer] = useState(false);
  const { seller } = useSelector((state) => state.seller);

  const readPermission =
    seller?.isAdmin ||
    seller?.permission[SHOP_RESOURCE_ROLE_NAME.roles]?.permissions?.read;

  const updatePermission =
    seller?.isAdmin ||
    seller?.permission[SHOP_RESOURCE_ROLE_NAME.roles]?.permissions?.update;

  const createPermission =
    seller?.isAdmin ||
    seller?.permission[SHOP_RESOURCE_ROLE_NAME.roles]?.permissions?.create;

  const handleEditRoleDrawer = () => {
    setOpenEditRole((prevState) => !prevState);
    setEditRoleDrawer(false);
  };
  const handleEnableEdit = () => {
    setEditRoleDrawer((prevState) => !prevState);
  };
  const handleOpenDetail = (role) => {
    if (!updatePermission) {
      return;
    }
    setRoleDetail(role);
    handleEditRoleDrawer();
  };

  const getShopRoles = async () => {
    try {
      const result = await getShopRolesService();
      if (result.success) {
        setRoles(result.data);
      }
    } catch (error) {
      toast.error('Disculpa, ha ocurrido un error al obtener los roles');
    }
  };
  useEffect(() => {
    console.log('Mount RoleList');

    getShopRoles();
  }, []);

  return (
    <>
      <DashboardCommonLayout>
        <>
          <div className='flex w-full justify-between'>
            <div className='w-[80px] 800px:w-[330px]'>
              <DashboardSideBar active={13} />
            </div>
            <div className='w-full'>
              <MKCard>
                {roles.length === 0 ? (
                  <>
                    <section className='flex flex-col items-center justify-between md:flex-row'>
                      <p>Aun no has creado ningún rol</p>
                      {createPermission && (
                        <section>
                          <Link
                            to={'/dashboard-create-rol'}
                            className={twMerge(styles.button, 'my-1 h-10 px-2')}
                          >
                            <IoMdAdd />
                            Agregar Rol
                          </Link>
                        </section>
                      )}
                    </section>
                  </>
                ) : (
                  <>
                    <section className='w-full'>
                      <div className='item-center mb-4 flex justify-between rounded-md border-b-2 bg-white p-3 shadow-sm'>
                        <div className='flex items-center px-2'>
                          <h3 className='text-center text-lg'>Roles</h3>
                        </div>
                        {createPermission && (
                          <section>
                            <Link
                              to={'/dashboard-create-rol'}
                              className={twMerge(
                                styles.button,
                                'my-1 h-10 px-2'
                              )}
                            >
                              <IoMdAdd />
                              Agregar Rol
                            </Link>
                          </section>
                        )}
                      </div>
                    </section>
                    {readPermission &&
                      roles.map((role) => (
                        <button
                          onClick={() => handleOpenDetail(role)}
                          className='my-2 flex w-full items-center gap-3 rounded-lg border border-gray-200 p-3'
                        >
                          <IoShieldCheckmark className='h-6 w-6 text-primary-500' />

                          {role.name}
                        </button>
                      ))}
                  </>
                )}
              </MKCard>
            </div>
          </div>
        </>
      </DashboardCommonLayout>
      {openEditRole && editRoleDrawer && (
        <EditRole
          roleDetail={roleDetail}
          getShopRoles={getShopRoles}
          handleEditRoleDrawer={handleEditRoleDrawer}
        />
      )}
      {openEditRole && !editRoleDrawer && (
        <ReadRole
          handleEnableEditing={handleEnableEdit}
          roleDetail={roleDetail}
          handleEditRoleDrawer={handleEditRoleDrawer}
        />
      )}
    </>
  );
};

export default RoleList;
