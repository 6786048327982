import { useEffect, useState } from 'react';
import { HiPencilAlt } from 'react-icons/hi';
import { MdDeleteOutline } from 'react-icons/md';

import MKButton from '../../../../../UI/MKButton';
import MKCard from '../../../../../UI/MKCard';
import { BsCurrencyExchange } from 'react-icons/bs';
import AddCurrencyDrawer from '../AddCurrency/AddCurrencyDrawer';
import useAdminConfig from '../../../../../../hooks/admin/config/config.hooks';
import { IEditCurrencyAttributes } from '../../../../../../interfaces/admin/configuration';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '../../../../../UI/MKAlertDialog';
import { deleteCurrencyService } from '../../../../../../services/admin/config';
import { toast } from 'react-toastify';
import EmptyState from '../../../../../Common/EmptyState';
import { refreshConfiguration } from '../../../../../../redux/actions/config';
import { useAppDispatch } from '../../../../../../hooks/common/redux.hooks';

const CurrencyList = () => {
  const dispatch = useAppDispatch();
  const [editCurrency, setEditCurrency] = useState(false);
  const [editData, setEditData] = useState<IEditCurrencyAttributes | null>(
    null
  );
  const { config, loadingConfig, refresh } = useAdminConfig();
  const multipleCurrencies = config?.multiple_currencies
    ? config.multiple_currencies
    : null;
  useEffect(() => {}, [config]);

  const handleCloseDrawer = () => {
    setEditCurrency(false);
  };
  const handleEditData = (data, id) => {
    setEditData({ ...data, id });
    setEditCurrency(true);
  };
  const handleDeleteCurrency = async (id) => {
    const result = await deleteCurrencyService(id);
    if (result.error) {
      toast.error(
        'Ha ocurrido un error, asegurate de tener los permisos correctos'
      );
      return;
    }
    if (result.success) {
      dispatch(refreshConfiguration());
      toast.success('Moneda eliminada correctamente');
    }
  };
  return (
    <section>
      {multipleCurrencies !== null ? (
        <section className='mt-4 flex flex-col gap-2'>
          {Object.keys(multipleCurrencies).map((currency) => (
            <MKCard
              className='flex items-center justify-between'
              key={`curency-list-${currency}`}
            >
              <section className='flex items-center gap-2'>
                <BsCurrencyExchange className='h-6 w-6 text-gray-500' />
                <div>
                  <p className='text-lg'>{multipleCurrencies[currency].name}</p>
                  <p className='font-bold'>
                    {config.currency} 1 ={' '}
                    {multipleCurrencies[currency].abbreviation}{' '}
                    {multipleCurrencies[currency].conversion_rate}
                  </p>
                </div>
              </section>
              <section>
                <MKButton
                  onClick={() =>
                    handleEditData(multipleCurrencies[currency], currency)
                  }
                  variant='none'
                >
                  <HiPencilAlt className='h-6 w-6' />
                </MKButton>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <MKButton variant='none'>
                      <MdDeleteOutline className='h-6 w-6' />
                    </MKButton>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>
                        ¿Estás completamente seguro de eliminar esta moneda?
                      </AlertDialogTitle>
                      <AlertDialogDescription>
                        Esta acción no se puede deshacer. Esto eliminará
                        permanentemente la moneda de nuestros servidores
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancelar</AlertDialogCancel>
                      <AlertDialogAction
                        onClick={() => handleDeleteCurrency(currency)}
                      >
                        Continuar
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </section>
            </MKCard>
          ))}
          {editCurrency && editData && (
            <AddCurrencyDrawer
              currencyData={editData}
              handleCloseDrawer={handleCloseDrawer}
            />
          )}
        </section>
      ) : (
        <>
          <EmptyState width={140} height={140} />
          <p className='text-center text-lg text-gray-500 md:text-xl'>
            No hay monedas registradas
          </p>
        </>
      )}
    </section>
  );
};

export default CurrencyList;
