import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { MdArrowBackIosNew } from 'react-icons/md';
import { IoLocationOutline } from 'react-icons/io5';

import MKCard from '../../UI/MKCard';
import MKButton from '../../UI/MKButton';

import {
  ORDER_STATUS,
  SHOP_RESOURCE_ROLE_NAME,
} from '../../../utils/constants/common';

import { getAllOrdersOfShop } from '../../../redux/actions/order';
import { server } from '../../../server';
import { TbTruckDelivery } from 'react-icons/tb';
import AssignDealer from './AssignDealer';

const OrderDetails = () => {
  const { orders, isLoading } = useSelector((state) => state.order);
  const [openDeliveryDrawer, setOpenDeliveryDrawer] = useState();
  const [data, setData] = useState();

  const { seller } = useSelector((state) => state.seller);
  const updatePermission =
    seller.isAdmin ||
    seller?.permission[SHOP_RESOURCE_ROLE_NAME.orders]?.permissions?.update;
  const dispatch = useDispatch();
  const [status, setStatus] = useState('');
  const navigate = useNavigate();
  const { id } = useParams();

  const closeDrawer = () => {
    setOpenDeliveryDrawer(false);
  };

  useEffect(() => {}, []);

  useEffect(() => {
    if (orders) {
      const filter = orders.find((item) => item._id === id);
      setData(filter);
      setStatus(ORDER_STATUS[filter.status].value);
    }
  }, [orders]);

  const orderUpdateHandler = async (e) => {
    await axios
      .put(
        `${server}/order/update-order-status/${id}`,
        {
          status,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success('Estado de la orden actualizado!');
        navigate('/dashboard-orders');
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const refundOrderUpdateHandler = async (e) => {
    await axios
      .put(
        `${server}/order/order-refund-success/${id}`,
        {
          status,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success('Order updated!');
        dispatch(getAllOrdersOfShop(seller._id));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const formattedDate =
    data?.deliveryDate &&
    new Intl.DateTimeFormat('es', {
      weekday: 'long',
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }).format(new Date(data.deliveryDate));
  return (
    <section>
      <div className='flex w-full flex-col justify-between py-2 '>
        <div className='item-center flex rounded-md border-b-2 bg-white p-3 shadow-sm'>
          <div className='mr-4 self-center'>
            <button
              className='inline-block max-h-min p-1'
              onClick={() => navigate(-1)}
            >
              <MdArrowBackIosNew className='h-6 w-6 text-gray-400' />
            </button>
          </div>

          <div className='self-center px-2'>
            <h3 className='self-center text-center text-lg'>
              Detalles del pedido a entregar
            </h3>
          </div>
        </div>

        <section
          id='order-details'
          className='mt-4 flex flex-col items-center gap-4 md:flex-row md:items-start'
        >
          {/* Order details */}
          {data && (
            <section
              id='order-overview'
              className='max-h-min w-11/12 rounded-md border border-slate-200 px-4 py-2 shadow md:w-2/4'
            >
              <p className='text-center text-lg font-bold'>
                Resumen del pedido
              </p>

              <div className='flex w-full items-center justify-between pt-6'></div>
              <div className='flex w-full justify-between rounded-lg bg-primary-100 p-2'>
                <p className='text-lg'>Total: </p>
                <p className='text-right'>C${data?.totalPrice}</p>
              </div>

              <div className='items-center'>
                <div className='w-full'>
                  <p className='py-2 font-bold'>Método de entrega</p>
                  <MKCard className='mx-2'>
                    <div className='flex gap-2'>
                      <TbTruckDelivery className='h-6 w-6 self-center' />
                      <div className='w-full'>
                        <p className='text-sm'>Delivery</p>
                      </div>
                    </div>

                    {!data?.driverId && (
                      <MKButton
                        onClick={() => setOpenDeliveryDrawer(true)}
                        variant='text'
                        className=' text-primary-200'
                      >
                        Asignar repartidor
                      </MKButton>
                    )}

                    {data?.driverId && (
                      <MKButton
                        onClick={() => setOpenDeliveryDrawer(true)}
                        variant='text'
                        className=' text-primary-200'
                      >
                        Ver repartidor asignado
                      </MKButton>
                    )}
                  </MKCard>
                </div>
                <div className='w-full'>
                  <p className='py-3 font-bold'>Dirección de entrega</p>
                  <MKCard className='mx-2'>
                    <div className='flex'>
                      <IoLocationOutline className='h-6 w-6 self-center' />
                      <div className='w-full'>
                        <p className='text-sm'>
                          {data.shippingAddress.address1}
                        </p>
                        <p className='pt-2 text-sm text-gray-500'>
                          {data.shippingAddress.address2}
                        </p>
                      </div>
                    </div>
                  </MKCard>

                  <p className='py-3 font-bold'>Fecha y hora</p>
                  <MKCard className='mx-2'>
                    <p>{formattedDate}</p>
                  </MKCard>
                  <MKCard className='m-2'>
                    <p>{data.deliveryTime?.intervalDescription}</p>
                  </MKCard>
                  <p className='py-3 font-bold'>Contacto del usuario</p>
                  <MKCard className='mx-2'>
                    <p className=''>{data?.user?.phoneNumber}</p>
                  </MKCard>
                </div>
                <div className='w-full'>
                  <p className='pt-3 text-lg'>Información de pago:</p>
                  <p className='p-2'>
                    Estado:{' '}
                    {data?.paymentInfo?.status
                      ? data?.paymentInfo?.status
                      : 'No pagado'}
                  </p>
                </div>
              </div>
              <h4 className='pt-3 text-lg'>Estado del pedido:</h4>
              {data?.status !== 'Processing refund' &&
                data?.status !== 'Refund Success' && (
                  <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className='mt-2 h-10 w-full rounded-[5px] border px-2'
                  >
                    {Object.keys(ORDER_STATUS).map((option, index) => (
                      <option value={ORDER_STATUS[option].value} key={index}>
                        {ORDER_STATUS[option].label}
                      </option>
                    ))}
                  </select>
                )}
              {data?.status === 'Processing refund' ||
              data?.status === 'Refund Success' ? (
                <select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  className='mt-2 h-[35px] w-[200px] rounded-[5px] border'
                >
                  {['Processing refund', 'Refund Success']
                    .slice(
                      ['Processing refund', 'Refund Success'].indexOf(
                        data?.status
                      )
                    )
                    .map((option, index) => (
                      <option value={option} key={index}>
                        {option}
                      </option>
                    ))}
                </select>
              ) : null}
              {updatePermission && (
                <MKButton
                  className={` mt-4 w-full`}
                  onClick={orderUpdateHandler}
                >
                  Actualizar estado
                </MKButton>
              )}
            </section>
          )}
          {/* Products */}
          <section
            id='order-pducts'
            className='h-[80vh] w-full overflow-y-scroll rounded-md border border-slate-200  p-2 shadow'
          >
            <p className='mb-4 text-center text-lg font-bold'>Productos</p>
            {data &&
              data?.cart.map((item, index) => (
                <>
                  <MKCard className='mb-5 flex items-start'>
                    <img
                      src={`${item.images[0]?.url}`}
                      alt=''
                      className='h-[80px] w-[80x]'
                    />
                    <div className='w-full pl-3'>
                      <p className=' text-gray-600'>{item.name}</p>
                      <p className='text-gray-600'>
                        Precio unitario C${item.price.toFixed(2)}
                      </p>
                      <p className='text-lg font-bold'>
                        Cantidad {item.quantity}
                      </p>
                    </div>
                  </MKCard>
                </>
              ))}
          </section>
        </section>
      </div>
      {openDeliveryDrawer && (
        <AssignDealer
          orderId={data._id}
          closeDrawer={closeDrawer}
          driverId={data?.driverId}
        />
      )}
    </section>
  );
};

export default OrderDetails;
