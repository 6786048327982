import axios from 'axios';
import { server } from '../../server';

export const approvePotentialPartnerService = async (potentialPartner) => {
  try {
    const result = await axios.post<any, any>(
      `${server}/shop/approve-partner`,
      {
        partner: potentialPartner,
      },
      {
        withCredentials: true,
      }
    );
    return result;
  } catch (error) {
    return {
      error: true,
      message: error.message,
    };
  }
};

export const rejectPotentialPartnerService = async (potentialPartner) => {
  try {
    const result = await axios.post<any, any>(
      `${server}/shop/reject-partner`,
      {
        partner: potentialPartner,
      },
      {
        withCredentials: true,
      }
    );
    return result;
  } catch (error) {
    return {
      error: true,
      message: error.message,
    };
  }
};
