import axios from 'axios';
import { server } from '../../server';
import { DEFAULT_ERROR_MESSAGE } from '../../utils/constants/common';
import {
  ICurrencyAttributes,
  IEditCurrencyAttributes,
} from '../../interfaces/admin/configuration';

export const getConfigurationService = async () => {
  try {
    const { data } = await axios.get(`${server}/configuration/get-config`);
    return data;
  } catch (error: any) {
    return {
      error: true,
      message: error.message || DEFAULT_ERROR_MESSAGE,
    };
  }
};

export const saveConfigurationService = async ({
  country_name,
  currency_sign,
  currency,
  country_code,
  telephone_country_code,
  shipping_price,
  tax,
  service_fee,
}) => {
  try {
    const { data } = await axios.post(`${server}/configuration/save-config`, {
      country_name,
      currency_sign,
      currency,
      country_code,
      telephone_country_code,
      shipping_price,
      tax,
      service_fee,
    });
    return data;
  } catch (error: any) {
    return {
      error: true,
      message: error.message || DEFAULT_ERROR_MESSAGE,
    };
  }
};

export const addNewCurrencyService = async ({
  active,
  conversion_rate,
  currency_symbol,
  name,
  abbreviation = '',
}: ICurrencyAttributes) => {
  try {
    const { data } = await axios.post(`${server}/configuration/add-currency`, {
      active,
      conversion_rate,
      currency_symbol,
      name,
      abbreviation,
    });

    return data;
  } catch (error: any) {
    return {
      error: true,
      message: error.message || DEFAULT_ERROR_MESSAGE,
    };
  }
};

export const updateCurrencyService = async ({
  active,
  conversion_rate,
  currency_symbol,
  name,
  abbreviation = '',
  id,
}: IEditCurrencyAttributes) => {
  try {
    const { data } = await axios.post(
      `${server}/configuration/update-currency`,
      {
        active,
        conversion_rate,
        currency_symbol,
        name,
        abbreviation,
        id,
      }
    );

    return data;
  } catch (error: any) {
    return {
      error: true,
      message: error.message || DEFAULT_ERROR_MESSAGE,
    };
  }
};

export const deleteCurrencyService = async (id: string) => {
  try {
    const { data } = await axios.post(
      `${server}/configuration/delete-currency`,
      {
        id,
      }
    );

    return data;
  } catch (error: any) {
    return {
      error: true,
      message: error.message || DEFAULT_ERROR_MESSAGE,
    };
  }
};
