import { useMemo, useState } from 'react';
import { FaPaintBrush } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { Popover, PopoverContent, PopoverTrigger } from '../../UI/MKPopover';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../UI/MKTabs';
import MKButton from '../../UI/MKButton';
import { cn } from '../../../utils/cn';

export const ColorPicker = () => {
  const [background, setBackground] = useState('#091E3A');

  return (
    <div
      className='preview flex h-full min-h-[25px] w-full items-center justify-center rounded !bg-cover !bg-center p-5 transition-all'
      style={{ background }}
    >
      <GradientPicker background={background} setBackground={setBackground} />
    </div>
  );
};

export const GradientPicker = ({
  background,
  setBackground,
  className,
}: {
  background: string;
  setBackground: (background: string) => void;
  className?: string;
}) => {
  const solids = [
    '#E2E2E2',
    '#ff75c3',
    '#ffa647',
    '#ffe83f',
    '#9fff5b',
    '#70e2ff',
    '#cd93ff',
    '#09203f',
  ];

  const gradients = [
    'linear-gradient(to top left,#accbee,#e7f0fd)',
    'linear-gradient(to top left,#d5d4d0,#d5d4d0,#eeeeec)',
    'linear-gradient(to top left,#000000,#434343)',
    'linear-gradient(to top left,#09203f,#537895)',
    'linear-gradient(to top left,#AC32E4,#7918F2,#4801FF)',
    'linear-gradient(to top left,#f953c6,#b91d73)',
    'linear-gradient(to top left,#ee0979,#ff6a00)',
    'linear-gradient(to top left,#F00000,#DC281E)',
    'linear-gradient(to top left,#00c6ff,#0072ff)',
    'linear-gradient(to top left,#4facfe,#00f2fe)',
    'linear-gradient(to top left,#0ba360,#3cba92)',
    'linear-gradient(to top left,#FDFC47,#24FE41)',
    'linear-gradient(to top left,#8a2be2,#0000cd,#228b22,#ccff00)',
    'linear-gradient(to top left,#40E0D0,#FF8C00,#FF0080)',
    'linear-gradient(to top left,#fcc5e4,#fda34b,#ff7882,#c8699e,#7046aa,#0c1db8,#020f75)',
    'linear-gradient(to top left,#ff75c3,#ffa647,#ffe83f,#9fff5b,#70e2ff,#cd93ff)',
  ];

  const images = [
    'url(https://images.unsplash.com/photo-1691200099282-16fd34790ade?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2532&q=90)',
    'url(https://images.unsplash.com/photo-1691226099773-b13a89a1d167?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2532&q=90',
    'url(https://images.unsplash.com/photo-1688822863426-8c5f9b257090?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2532&q=90)',
    'url(https://images.unsplash.com/photo-1691225850735-6e4e51834cad?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2532&q=90)',
  ];

  const defaultTab = useMemo(() => {
    if (background.includes('url')) return 'image';
    if (background.includes('gradient')) return 'gradient';
    return 'solid';
  }, [background]);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button
          className={cn(
            'w-[220px] justify-start self-start rounded-2xl border border-primary-200 px-4 py-3 text-left font-normal text-primary-500 hover:bg-primary-200 hover:text-white',
            !background && 'text-muted-foreground',
            className
          )}
        >
          <div className='flex w-full items-center gap-2'>
            {background ? (
              <div
                className='h-4 w-4 rounded !bg-cover !bg-center transition-all'
                style={{ background }}
              ></div>
            ) : (
              <FaPaintBrush className='h-4 w-4' />
            )}
            <div className='flex-1 truncate'>
              {background ? background : 'Elegir color'}
            </div>
          </div>
        </button>
      </PopoverTrigger>
      <PopoverContent className='w-64 bg-white'>
        <Tabs defaultValue={defaultTab} className='w-full'>
          <TabsList className='mb-4 w-full'>
            <TabsTrigger className='flex-1' value='solid'>
              Solid
            </TabsTrigger>
            <TabsTrigger className='flex-1' value='gradient'>
              Gradient
            </TabsTrigger>
            <TabsTrigger className='flex-1' value='image'>
              Image
            </TabsTrigger>
          </TabsList>

          <TabsContent value='solid' className='mt-0 flex flex-wrap gap-1'>
            {solids.map((s) => (
              <div
                key={s}
                style={{ background: s }}
                className='h-6 w-6 cursor-pointer rounded-md active:scale-105'
                onClick={() => setBackground(s)}
              />
            ))}
          </TabsContent>

          <TabsContent value='gradient' className='mt-0'>
            <div className='mb-2 flex flex-wrap gap-1'>
              {gradients.map((s) => (
                <div
                  key={s}
                  style={{ background: s }}
                  className='h-6 w-6 cursor-pointer rounded-md active:scale-105'
                  onClick={() => setBackground(s)}
                />
              ))}
            </div>

            <GradientButton background={background}>
              💡 Get more at{' '}
              <Link
                to='https://gradient.page/css/ui-gradients'
                className='font-bold hover:underline'
                target='_blank'
              >
                Gradient Page
              </Link>
            </GradientButton>
          </TabsContent>

          <TabsContent value='image' className='mt-0'>
            <div className='mb-2 grid grid-cols-2 gap-1'>
              {images.map((s) => (
                <div
                  key={s}
                  style={{ backgroundImage: s }}
                  className='h-12 w-full cursor-pointer rounded-md bg-cover bg-center active:scale-105'
                  onClick={() => setBackground(s)}
                />
              ))}
            </div>

            <GradientButton background={background}>
              🎁 Get abstract{' '}
              <Link
                to='https://gradient.page/wallpapers'
                className='font-bold hover:underline'
                target='_blank'
              >
                wallpapers
              </Link>
            </GradientButton>
          </TabsContent>

          <TabsContent value='password'>Change your password here.</TabsContent>
        </Tabs>

        <input
          id='custom'
          value={background}
          className='col-span-2 mt-4 h-8'
          onChange={(e) => setBackground(e.currentTarget.value)}
        />
      </PopoverContent>
    </Popover>
  );
};

const GradientButton = ({
  background,
  children,
}: {
  background: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className='relative rounded-md !bg-cover !bg-center p-0.5 transition-all'
      style={{ background }}
    >
      <div className='bg-popover/80 rounded-md p-1 text-center text-xs'>
        {children}
      </div>
    </div>
  );
};
