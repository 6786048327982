import React, { useState } from 'react';
import Header from '../components/Layout/Header';
import styles from '../styles/styles';
import Loader from '../components/Layout/Loader';
import ProfileSideBar from '../components/Profile/ProfileSidebar';
import ProfileContent from '../components/Profile/ProfileContent';
import { useAppSelector } from '../hooks/common/redux.hooks';

const ProfilePage = () => {
  const { loading } = useAppSelector((state) => state.user);
  const [active, setActive] = useState(1);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header activeHeading={1} />
          <div className={`${styles.section} flex bg-[#f5f5f5] py-10`}>
            <div className='sticky mt-[18%] w-[50px] 800px:mt-0 800px:w-[335px]'>
              <ProfileSideBar active={active} setActive={setActive} />
            </div>
            <ProfileContent active={active} />
          </div>
        </>
      )}
    </div>
  );
};

export default ProfilePage;
