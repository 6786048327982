import { FaCheck } from 'react-icons/fa';
import { IoShieldCheckmark, IoShieldOutline } from 'react-icons/io5';
import { TRANSLATIONS } from '../../../../../translations/es-es';
import {
  INITIAL_PERMISSION_VALUES,
  PERMISSION_NAME,
} from '../../../../../utils/constants/common';
import {
  IPermissionState,
  IResourceRole,
} from '../../../../../datatypes/interfaces/roles';
import { useEffect, useState } from 'react';
import Permissions from './Permission';

interface IResources {
  name: string;
  resources: IResourceRole[];
  shouldEdit?: boolean;
  initialPermissionValues?: IPermissionState;
  handleAddNewResource?: (res: IResourceRole) => void;
  handleUpdateResource?: (res: IResourceRole) => void;
  handleRemoveResource?: (resourceName: string) => void;
}
const RoleResources = ({
  name,
  resources,
  shouldEdit = true,
  initialPermissionValues = INITIAL_PERMISSION_VALUES,
  handleAddNewResource = () => {},
  handleUpdateResource = () => {},
  handleRemoveResource = () => {},
}: IResources) => {
  const [permissionGranted, setPermissionGranted] = useState(
    initialPermissionValues
  );

  const isSelected = !!(
    resources.length > 0 && resources.find((res) => res.name === name)
  );

  const handleSelected = () => {
    if (!shouldEdit) {
      return;
    }
    // add resource to grant access
    if (!isSelected) {
      const resourceSetting = {
        name,
        permissions: { ...initialPermissionValues, read: true },
      };
      handleAddNewResource(resourceSetting);
      setPermissionGranted({ ...initialPermissionValues, read: true });
      return;
    }
    // remove resource from selected
    handleRemoveResource(name);
  };
  const handleUpdatePermission = (permissionUpdate) => {
    if (!isSelected || !shouldEdit) {
      return;
    }
    setPermissionGranted((prevValues) => {
      return { ...prevValues, ...permissionUpdate };
    });
  };
  const resourcePermissions = Object.keys(PERMISSION_NAME);

  useEffect(() => {
    const resourceSetting = {
      name,
      permissions: permissionGranted,
    };
    if (shouldEdit) {
      handleUpdateResource(resourceSetting);
    }
  }, [permissionGranted]);

  return (
    <div className='mb-2 w-full'>
      <section
        className={`flex items-center gap-4 rounded-xl border-2 p-2
          ${isSelected ? 'border-primary-100' : 'border-slate-200'}`}
      >
        <section className='w-full '>
          <button
            className={`flex w-full items-center justify-between ${
              isSelected ? 'border-b' : ''
            } py-2`}
            onClick={() => handleSelected()}
          >
            <section className='flex items-center'>
              <div className='flex h-8 w-8 items-center justify-center rounded-full bg-gray-300'>
                {isSelected && <FaCheck className='text-primary-500' />}
              </div>

              <div>
                <p className='mx-4 text-base font-bold'>
                  {TRANSLATIONS.roleNames[name]}
                </p>
              </div>
            </section>
            {!isSelected ? (
              <IoShieldOutline className='h-6 w-6' />
            ) : (
              <IoShieldCheckmark className='h-6 w-6 text-primary-500' />
            )}
          </button>
          {isSelected && (
            <section className='mt-4 flex flex-wrap gap-3'>
              {resourcePermissions.map((permission) => (
                <Permissions
                  handleUpdatePermission={handleUpdatePermission}
                  permissionName={permission}
                  permissionsGranted={permissionGranted}
                />
              ))}
            </section>
          )}
        </section>
      </section>
    </div>
  );
};

export default RoleResources;
