import axios from 'axios';
import { server } from '../../server';
import { createBannerService } from '../../services/banners/createBanner';

// get all sellers --- admin
export const getAllSellers = (queryParams) => async (dispatch) => {
  try {
    dispatch({
      type: 'getAllSellersRequest',
    });

    const { data } = await axios.get(`${server}/shop/admin-all-sellers`, {
      withCredentials: true,
      params: queryParams ? queryParams : {},
    });

    dispatch({
      type: 'getAllSellersSuccess',
      payload: data.sellers,
    });
  } catch (error) {
    dispatch({
      type: 'getAllSellerFailed',
      //   payload: error.response.data.message,
    });
  }
};

export const getPotentialPartners = (queryParams) => async (dispatch) => {
  try {
    dispatch({
      type: 'LoadPotentialPartnerRequest',
    });

    const { data } = await axios.get(`${server}/shop/admin-all-sellers`, {
      withCredentials: true,
      params: queryParams
        ? queryParams
        : {
            isApproved: false,
            isRejected: false,
          },
    });

    dispatch({
      type: 'LoadPotentialPartnerSuccess',
      payload: data.sellers,
    });
  } catch (error) {
    dispatch({
      type: 'LoadPotentialPartnerError',
      //   payload: error.response.data.message,
    });
  }
};

export const createBanner = (bannerData) => async (dispatch) => {
  dispatch({
    type: 'CreateBannerRequest',
  });
  const { data, success, message } = await createBannerService(bannerData);
  if (success) {
    dispatch({
      type: 'CreateBannerSuccess',
      payload: data,
    });
    return;
  }
  dispatch({
    type: 'CreateBannerFailed',
    payload: message || 'Error al crear el banner',
  });
};
