import { IoCloseCircleOutline } from 'react-icons/io5';
import MKDrawer from '../../../../components/UI/MKDrawer';
import styles from '../../../../styles/styles';
import { useState } from 'react';
import {
  IResourceRole,
  IShopRoleResult,
} from '../../../../datatypes/interfaces/roles';
import RoleResources from '../../../../components/Shop/Roles/CreateRole/Resources';
import { MdSaveAs } from 'react-icons/md';
import { joinResourceWithGrantedPermission } from '../utils/common';
import { editRoleService } from '../../../../services/roles/roles';
import { toast } from 'react-toastify';

interface IEditRoleProps {
  handleEditRoleDrawer: () => void;
  roleDetail: IShopRoleResult;
  getShopRoles: () => Promise<void>;
}

const EditRole = ({
  handleEditRoleDrawer,
  roleDetail,
  getShopRoles,
}: IEditRoleProps) => {
  const matchDBWithDefaultRoles = joinResourceWithGrantedPermission(roleDetail);
  const [updateName, setupdateName] = useState(roleDetail.name);
  const [updateResourcesAccess, setUpdateREsourcesAccess] = useState(
    roleDetail.resources
  );
  const handleAddNewResource = (res: IResourceRole) => {
    setUpdateREsourcesAccess((prev) => [...prev, res]);
  };
  const handleUpdateResource = (res: IResourceRole) => {
    const resourceToUpdate = updateResourcesAccess.map((resource) =>
      resource.name === res.name
        ? { ...resource, permissions: { ...res.permissions } }
        : resource
    );
    if (resourceToUpdate.length > 0) {
      setUpdateREsourcesAccess(resourceToUpdate);
    }
  };
  const handleRemoveResource = (resourceName: string) => {
    // setResource((prev) => [...prev, res]);
    const filterResource = updateResourcesAccess.filter(
      (resourceInRole) => resourceInRole.name !== resourceName
    );
    setUpdateREsourcesAccess(filterResource);
  };

  const handleSaveRoleChanges = async () => {
    const updateValues = { name: updateName, resources: updateResourcesAccess };

    const result = await editRoleService(updateValues, roleDetail._id);
    if (result.status === 500) {
      toast.error(
        'Disculpa, ha ocurrido un error al crear un rol, intenta mas tarde'
      );
      return;
    }
    if (result.status === 201) {
      getShopRoles();
      toast.success('Rol actualizado exitosamente');
    }

    handleEditRoleDrawer();
  };
  return (
    <MKDrawer>
      <section className='px-2 py-1'>
        <div className='flex  flex-col'>
          <button className='self-end' onClick={handleEditRoleDrawer}>
            <IoCloseCircleOutline className='h-8 w-8 text-gray-500' />
          </button>
          <section className=' mb-3 flex items-center justify-between border-b'>
            <p>Rol {roleDetail.name}</p>
            <div className='flex'>
              <button
                className={`${styles.button} gap-2 px-2`}
                onClick={handleSaveRoleChanges}
              >
                <MdSaveAs className='h-5 w-5 ' />
                Guardar cambios
              </button>
            </div>
          </section>
        </div>
        <div className=''>
          <>
            <div className='mb-6'>
              <label htmlFor='email' className='block font-medium '>
                Nombre del rol
              </label>
              <div className='mt-1'>
                <input
                  required
                  value={updateName}
                  onChange={(e) => setupdateName(e.target.value)}
                  className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-200 focus:outline-none focus:ring-primary-200 sm:text-sm'
                />
              </div>
            </div>{' '}
            {matchDBWithDefaultRoles.map((resource) => (
              <div>
                <RoleResources
                  name={resource.name}
                  handleAddNewResource={handleAddNewResource}
                  handleUpdateResource={handleUpdateResource}
                  handleRemoveResource={handleRemoveResource}
                  shouldEdit={true}
                  resources={updateResourcesAccess}
                  initialPermissionValues={resource.permissions}
                />
              </div>
            ))}
          </>
        </div>
      </section>
      <button
        className={`${styles.button} mb-0 rounded-b-none px-2`}
        onClick={handleSaveRoleChanges}
      >
        <MdSaveAs className='h-5 w-5 ' />
        Guardar cambios
      </button>
    </MKDrawer>
  );
};

export default EditRole;
