import styles from '../../styles/styles';
import ShopInfo from '../../components/Shop/ShopInfo/ShopInfo';
import ShopProfileData from '../../components/Shop/ShopProfileData';
import AdminHeader from '../../components/Layout/AdminHeader';
import AdminSideBar from '../../components/Admin/Layout/AdminSideBar';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { server } from '../../server';

const ShopPreviewPage = () => {
  const [data, setData] = useState({});
  const { id } = useParams();

  useEffect(() => {
    axios.get(`${server}/shop/get-shop-info/${id}`).then((res) => {
      setData(res.data.shop);
    });
  }, []);

  return (
    <section className='mx-auto my-0 max-w-[80rem]'>
      <AdminHeader />
      <div className='flex w-full'>
        <div className='flex w-full items-start justify-between'>
          <div className='w-[80px] 800px:w-[330px]'>
            <AdminSideBar active={3} />
          </div>
          <div className={`${styles.section} mx-auto my-0 max-w-[80rem]`}>
            <div className='w-full'>
              <div className=' rounded-[4px] border-b-2 bg-white shadow-sm'>
                <ShopInfo isOwner={false} data={data} />
              </div>
            </div>
            <div className="mt-5 w-full rounded-md 800px:mt-['unset']">
              <ShopProfileData isOwner={false} data={data} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShopPreviewPage;
