import React, { useEffect, useState } from 'react';
import { usePublicDrivers } from '../../../hooks/drivers/usePublicDrivers';
import { Avatar, AvatarFallback, AvatarImage } from '../../UI/MKAvatar';
import { FaMotorcycle } from 'react-icons/fa';
import { MdMail, MdOutlinePhoneAndroid } from 'react-icons/md';
import { Driver } from '../../../interfaces/admin/driver';

interface DealerAssignedProps {
  id: string;
  city: string;
}
const DealerAssigned = ({ id, city }: DealerAssignedProps) => {
  const [driver, setDriver] = useState<Driver | null>(null);
  const { getDriverById } = usePublicDrivers(city);
  const getDriverDetails = async () => {
    const result = await getDriverById(id);

    if (result.success && result.driver) {
      setDriver(result.driver);
    }
  };

  useEffect(() => {
    getDriverDetails();
  }, []);

  return (
    <>
      {driver && (
        <section className=''>
          <div className='my-2 flex items-center justify-center gap-2 md:my-4'>
            <Avatar className='h-10 w-10 rounded-full border border-gray-200'>
              <AvatarImage src={driver?.profileImage} />
              <AvatarFallback>
                {driver?.firstName[0]} {driver?.lastName[0]}
              </AvatarFallback>
            </Avatar>
            <p>
              {driver?.firstName} {driver?.lastName}
            </p>
          </div>
          <section className='flex flex-wrap gap-2'>
            <section className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
              <div className='rounded-lg bg-primary-100 p-2'>
                <MdOutlinePhoneAndroid className='text-primary-500' />
              </div>
              <div>
                <p className='font-semibold'>Teléfono</p>
                <p className='text-gray-700'>{driver.phoneNumber}</p>
              </div>
            </section>
            <section className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
              <div className='rounded-lg bg-primary-100 p-2'>
                <MdMail className='text-primary-500' />
              </div>
              <div>
                <p className='text-left font-semibold'>Correo</p>
                {/* @ts-ignore */}
                <p className='text-gray-700'>{driver.email}</p>
              </div>
            </section>
            <div className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
              <div className='rounded-lg bg-primary-100 p-2'>
                <FaMotorcycle className='text-primary-500' />
              </div>
              <div>
                <p className='font-semibold'>Vehículo</p>
                <p className='text-gray-700'>
                  {driver.vehicleInfo.vehicleType}
                </p>
              </div>
            </div>
          </section>
        </section>
      )}
    </>
  );
};

export default DealerAssigned;
