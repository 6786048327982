import ShopSettings from '../../../components/Shop/ShopSettings';
import DashboardSideBar from '../../../components/Shop/Layout/DashboardSideBar';
import DashboardCommonLayout from '../../../components/Layout/DashboardCommonLayout';

const ShopStaffProfilePage = () => {
  return (
    <DashboardCommonLayout>
      <div className='flex w-full items-start justify-between'>
        <div className='w-[80px] 800px:w-[330px]'>
          <DashboardSideBar active={11} />
        </div>
        <section className='w-full'>
          <ShopSettings />
        </section>
      </div>
    </DashboardCommonLayout>
  );
};

export default ShopStaffProfilePage;
