import axios from 'axios';
import {
  Banner,
  BannerResponse,
  CreateBanner,
} from '../../interfaces/admin/banners';
import { serverV3 } from '../../server';

export const createBannerService = async (bannerData: CreateBanner) => {
  const { city, ...restBannerData } = bannerData;
  try {
    const response = await axios.post<BannerResponse>(
      `${serverV3}/banners/create`,
      restBannerData,
      {
        headers: {
          cityId: city,
        },
        withCredentials: true,
      }
    );
    return {
      success: response.status === 201,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

export const uploadImageService = async (image: string) => {
  try {
    const formData = new FormData();
    formData.append('image', image); // Ensure 'image' is a File object

    const response = await axios.post(
      `${serverV3}/upload-image/banner-image`,
      formData
    );
    return {
      success: response.status === 201,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};

export const updateBannerService = async (bannerData: Partial<Banner>) => {
  const { city, ...restBannerData } = bannerData;
  try {
    const response = await axios.post<BannerResponse>(
      `${serverV3}/banners/${bannerData._id}/update`,
      restBannerData,
      {
        headers: {
          cityId: city,
        },
        withCredentials: true,
      }
    );
    return {
      success: response.status === 201,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};
