import { useState } from 'react';
import MKButton from '../../../UI/MKButton';
import BeginSiteConfigurationDrawer from './BeginSiteConfigurationDrawer';

const BeginSiteConfiguration = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  return (
    <>
      <MKButton
        onClick={() => setOpenDrawer(true)}
        className='my-4 hover:bg-primary-200 hover:text-white'
        variant='outlined'
      >
        <p>Empezar a configurar sitio</p>
      </MKButton>
      {openDrawer && (
        <BeginSiteConfigurationDrawer handleCloseDrawer={handleCloseDrawer} />
      )}
    </>
  );
};

export default BeginSiteConfiguration;
