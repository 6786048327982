export const TRANSLATIONS = {
  roleNames: {
    orders: 'Pedidos',
    products: 'Productos',
    staff: 'Empleados',
    roles: 'Roles',
    shopConfig: 'Configuración de tienda'
  },
  rolePermissionName: {
    create: 'Crear',
    read: 'Leer',
    update: 'Actualizar',
    delete: 'Eliminar'
  }
}