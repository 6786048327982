import { z } from 'zod';

export const createBannerResolver = z.object({
  title: z
    .string({
      required_error: 'El nombre es requerido ',
    })
    .min(3, 'El nombre debe tener al menos 3 caracteres'),
  description: z.string().nullish(),
  categoryId: z.string().nullish(),
  storeId: z.string().nullish(),
  productId: z.string().nullish(),
  isActive: z.boolean().nullish(),
  isApproved: z.boolean().nullish(),
  startDate: z.date().nullish(),
  endDate: z.date().nullish(),
});
// .refine((data) => data.endDate >= data.startDate, {
//   message:
//     'La fecha de finalización debe ser posterior a la fecha de inicio.',
//   path: ['endDate'], // This will point the error to the endDate field
// });
