import axios from 'axios';
import { server } from '../../server';

export const staffSignInService = async (email: string, password: string) => {
  try {
    const { data } = await axios.post(
      `${server}/shop/login-shop`,
      {
        email,
        password,
      },
      { withCredentials: true }
    );

    return {
      success: data.success,
      data: data.seller,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
};
