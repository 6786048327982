import { IoIosCheckboxOutline } from 'react-icons/io';
import { IPermissionState } from '../../../../../../datatypes/interfaces/roles';
import { TRANSLATIONS } from '../../../../../../translations/es-es';
import { MdCheckBoxOutlineBlank } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';

interface IPermissions {
  permissionName: string;
  permissionsGranted: IPermissionState;
  handleUpdatePermission: (permissionUpdate: any) => void;
}
const Permissions = ({
  permissionName,
  permissionsGranted,
  handleUpdatePermission,
}: IPermissions) => {
  const isPermissionGranted = permissionsGranted[permissionName];

  const handleOnClickPermission = () => {
    handleUpdatePermission({ [permissionName]: !isPermissionGranted });
  };

  return (
    <button
      onClick={handleOnClickPermission}
      className={`flex items-center gap-2 rounded-md border px-3 py-1 ${
        isPermissionGranted && 'bg-primary-500 text-primary-100'
      }
        `}
    >
      <div>
        {isPermissionGranted && <FaCheck className='text-primary-100' />}
      </div>
      {TRANSLATIONS.rolePermissionName[permissionName]}
    </button>
  );
};

export default Permissions;
