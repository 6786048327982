// @ts-nocheck
import { useState } from 'react';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import { MdOutlineHideImage } from 'react-icons/md';
import styles from '../../../../styles/styles';
import EditProduct from '../EditProduct';
import { useSelector } from 'react-redux';
import { SHOP_RESOURCE_ROLE_NAME } from '../../../../utils/constants/common';

interface ISingleProduct {
  product: any;
}

const SingleProduct = ({ product }: ISingleProduct) => {
  const { seller } = useSelector((state) => state.seller);

  const updatePermission =
    seller?.isAdmin ||
    seller?.permission[SHOP_RESOURCE_ROLE_NAME.products]?.permissions?.update;
  const [openEditProductDrawer, setOpenEditProductDrawer] = useState(false);
  const handleOpenDrawer = () => {
    setOpenEditProductDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenEditProductDrawer(false);
  };
  return (
    <>
      <section className='flex max-h-80 w-56 flex-col justify-between rounded-lg border border-slate-200 bg-white p-3'>
        <div>
          {Array.isArray(product.images) && product.images.length >= 1 ? (
            <img
              src={`${product.images && product.images[0]?.url}`}
              alt=''
              className='h-[150px] w-full object-contain'
            />
          ) : (
            <MdOutlineHideImage className='h-[150px] w-full text-gray-200' />
          )}

          <section className='px-2'>
            <div className='flex items-center justify-between pt-2'>
              <div className=''>
                {product.discountPrice ? (
                  <>
                    <p className={`${styles.productDiscountPrice}`}>
                      C$
                      {product.originalPrice === 0
                        ? product.originalPrice.toFixed(2)
                        : product.discountPrice?.toFixed(2)}
                    </p>
                    <p className={`${styles.price} text-black`}>
                      {product.originalPrice
                        ? 'C$' + product.originalPrice.toFixed(2)
                        : null}
                    </p>
                  </>
                ) : (
                  <p className={`${styles.productDiscountPrice}`}>
                    C$
                    {product.originalPrice.toFixed(2)}
                  </p>
                )}
              </div>
            </div>

            <h4 className='mb-2 line-clamp-1 font-[400] text-gray-600'>
              {product.name}
            </h4>
          </section>
        </div>
        {updatePermission && (
          <button
            onClick={handleOpenDrawer}
            className={`${styles.cart_button} p-1 text-white`}
          >
            <HiOutlinePencilAlt className='mr-2' />
            Editar
          </button>
        )}

        {openEditProductDrawer && (
          <EditProduct
            currentProduct={product}
            handleCloseModal={handleCloseDrawer}
          />
        )}
      </section>
    </>
  );
};

export default SingleProduct;
