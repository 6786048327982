import { useState } from 'react';
import { getRegionsAndCitiesService } from '../../../services/admin/cities';
import { Region } from '../../../interfaces/region';

export const useGetConfigRegions = () => {
  const [regionsAndCities, setRegionsAndCities] = useState<Region[]>(null);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState(false);

  const Refetch = () => {
    setRefresh(true);
  };

  const getAllCities = async () => {
    try {
      const response = await getRegionsAndCitiesService();

      if (!response || !response.success || !Array.isArray(response.data)) {
        setLoading(false);
        setRefresh(false);
        setError(true);
        return;
      }
      setLoading(false);
      setError(false);
      setRegionsAndCities(response.data);
    } catch (error) {
      setLoading(false);
      setRefresh(false);
    }
  };
  if (loading || refresh) {
    getAllCities();
  }

  return { regionsAndCities, error, loading, Refetch };
};
