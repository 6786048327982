import { useState } from 'react';
import DashboardCommonLayout from '../../../../components/Layout/DashboardCommonLayout';
import DashboardSideBar from '../../../../components/Shop/Layout/DashboardSideBar';
import MKCard from '../../../../components/UI/MKCard';
import styles from '../../../../styles/styles';
import StaffRoles from './StaffRoles';
import {
  IStaffRoleCreate,
  IStaffRoleId,
} from '../../../../datatypes/interfaces/staff';
import { createStaffService } from '../../../../services/staff/staff';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CreateStaff = () => {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const handleChangeSelected = (roleToChange: string) => {
    setSelectedRoles((prevState) => [...prevState, roleToChange]);
  };
  const handleRemoveRoleFromSelected = (roleToRemoveId: string) => {
    const removeSelected = selectedRoles.filter(
      (roleId) => roleId !== roleToRemoveId
    );
    setSelectedRoles(removeSelected);
  };
  const handleClickCreateStaff = async () => {
    try {
      const staffAttributes: IStaffRoleCreate = {
        name,
        lastName,
        email,
        password,
        roles: selectedRoles,
        isActive: true,
      };
      const result = await createStaffService(staffAttributes);
      if (result.status === 500) {
        toast.error(
          'Ha ocurrido un error, asegurate de que tienes los permisos necesarios'
        );
        return;
      }
      toast.success('Empleado creado con éxito');
      navigate('/dashboard-my-staff');
    } catch (error) {
      toast.error(
        'Ha ocurrido un error, asegurate de que tienes los permisos necesarios'
      );
    }
  };

  return (
    <DashboardCommonLayout>
      <>
        <div className='flex w-full justify-between'>
          <div className='w-[80px] 800px:w-[330px]'>
            <DashboardSideBar active={12} />
          </div>

          <div className='w-full'>
            <MKCard className='max-h-min w-full'>
              <p className='text-center text-lg font-bold'>Crear empleado</p>

              <section>
                <div>
                  <label className='pb-2'>
                    Nombre <span className='text-red-500'>*</span>
                  </label>
                  <input
                    type='text'
                    name='name'
                    value={name}
                    className='mt-2 block h-[35px] w-full appearance-none rounded-[3px] border border-gray-300 px-3 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Nombre...'
                  />
                </div>
                <div className='my-2'>
                  <label className='pb-2'>
                    Apellido <span className='text-red-500'>*</span>
                  </label>
                  <input
                    type='text'
                    name='name'
                    value={lastName}
                    className='mt-2 block h-[35px] w-full appearance-none rounded-[3px] border border-gray-300 px-3 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
                    onChange={(e) => setLastName(e.target.value)}
                    placeholder='Nombre...'
                  />
                </div>
                <div className='my-2'>
                  <label className='pb-2'>
                    Correo <span className='text-red-500'>*</span>
                  </label>
                  <input
                    type='text'
                    name='name'
                    value={email}
                    className='mt-2 block h-[35px] w-full appearance-none rounded-[3px] border border-gray-300 px-3 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Correo'
                  />
                </div>
                <div className='my-2'>
                  <label className='pb-2'>
                    Contraseña temporal <span className='text-red-500'>*</span>
                  </label>
                  <input
                    type='text'
                    name='name'
                    value={password}
                    className='mt-2 block h-[35px] w-full appearance-none rounded-[3px] border border-gray-300 px-3 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder='Contraseña...'
                  />
                </div>
                <div className='my-2'>
                  <p className='pb-2'>
                    Roles <span className='text-red-500'>*</span>
                  </p>
                  <StaffRoles
                    selectedRoles={selectedRoles}
                    handleChangeSelected={handleChangeSelected}
                    handleRemoveRoleFromSelected={handleRemoveRoleFromSelected}
                  />
                </div>
              </section>
              <div className='flex justify-center'>
                <button
                  onClick={handleClickCreateStaff}
                  className={`${styles.button} w-3/4`}
                >
                  Crear empleado
                </button>
              </div>
            </MKCard>
          </div>
        </div>
      </>
    </DashboardCommonLayout>
  );
};

export default CreateStaff;
