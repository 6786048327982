import React, { useEffect, useState } from 'react';
import { getShopRolesService } from '../../../../../services/roles/roles';
import { toast } from 'react-toastify';
import SingleStaffRole from '../SingleStaffRole';
import { IShopRoleResult } from '../../../../../datatypes/interfaces/roles';
import { IStaffRoleId } from '../../../../../datatypes/interfaces/staff';

interface IStaffRolesProps {
  selectedRoles: string[];
  handleChangeSelected: (roleToChange: string) => void;
  handleRemoveRoleFromSelected: (roleToRemoveId: string) => void;
}
const StaffRoles = ({
  selectedRoles,
  handleChangeSelected,
  handleRemoveRoleFromSelected,
}: IStaffRolesProps) => {
  const [shopRoles, setShopRoles] = useState<IShopRoleResult[]>([]);
  const handleSelectRoles = (roleId: string, isSelected: boolean) => {
    if (isSelected) {
      handleRemoveRoleFromSelected(roleId);
      return;
    }
    // add to state
    handleChangeSelected(roleId);
  };
  const getShopRoles = async () => {
    const result = await getShopRolesService();
    if (result.status === 500) {
      toast.error('Ha ocurrido un error al obtener los roles');
      return;
    }

    setShopRoles(result.data);
  };

  useEffect(() => {
    getShopRoles();
  }, []);

  return (
    <div>
      {shopRoles.map((role) => (
        <SingleStaffRole
          roleId={role._id}
          selectedRoles={selectedRoles}
          roleName={role.name}
          handleSelectRoles={handleSelectRoles}
        />
      ))}
    </div>
  );
};

export default StaffRoles;
