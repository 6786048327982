import React, { useState } from 'react';
import { ICategoryModel } from '../../../interfaces/category/categoryModel';
import { FaCheck, FaEdit } from 'react-icons/fa';
import AddCategory from './AddCategory';
import MKButton from '../../UI/MKButton';
import { MdOutlineBlock } from 'react-icons/md';
import useCategories from '../../../hooks/category/useCategoriesData';

const CategoryItem = ({ name, slug, isActive, image, _id }: ICategoryModel) => {
  const { refetch } = useCategories();
  const [editCategoryOpen, setEditCategoryOpen] = useState(false);
  const closeDrawer = (triggerFetch?: boolean) => {
    if (triggerFetch) {
      refetch();
    }
    setEditCategoryOpen(false);
  };
  return (
    <section className='flex gap-2 rounded-md border border-slate-300 md:gap-4'>
      <div
        style={{ background: image.mainColor }}
        className='relative h-48 w-48 overflow-hidden rounded-md'
      >
        <div className='absolute inset-x-0 top-2 text-center text-white md:top-4'>
          <p className='line-clamp-2 text-sm md:text-base'>{name}</p>
        </div>

        <div
          className='absolute -bottom-1/3 left-0 right-0 ml-auto mr-auto h-40 w-40 rounded-full'
          style={{ background: image.secondaryColor }}
        />
        <img
          src={image.url}
          alt=''
          className='absolute bottom-0 left-0 right-0 ml-auto mr-auto w-full object-cover'
        />
      </div>
      <section className='gap-2px-2 flex flex-1 justify-between px-2 py-2 md:py-4'>
        <div>
          <div className='rounded-lg bg-gradient-to-b from-primary-50 to-primary-100  px-2'>
            <p className='text-lg font-semibold'>{name}</p>
          </div>
          <p>Slug: /{slug}</p>
          {isActive ? (
            <section className='flex items-center gap-2'>
              <FaCheck className='text-green-500' />

              <p className='text-green-500'>Activo</p>
            </section>
          ) : (
            <section className='flex items-center gap-2'>
              <MdOutlineBlock className='text-red-500' />

              <p className='text-red-500'>Inactivo</p>
            </section>
          )}
        </div>
        <div>
          <MKButton
            onClick={() => {
              setEditCategoryOpen(true);
            }}
            variant='none'
            className=' p-2'
          >
            <FaEdit className='h-6 w-6 text-gray-500' />
          </MKButton>
        </div>
      </section>
      {editCategoryOpen && (
        <AddCategory
          closeAddCategoryDrawer={closeDrawer}
          categoryProps={{ name, slug, image, isActive, _id }}
        />
      )}
    </section>
  );
};

export default CategoryItem;
