import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  cityGeoLocation: null,
  cityRange: 3000
};

export const cityReducer = createReducer(initialState, {
  ChangeCityLocation: (state, action) => {
    state.cityGeoLocation = action.payload
  },
  ChangeCityRange: (state, action) => {
    state.cityRange = action.payload
  },
});
