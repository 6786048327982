import { MdCurrencyExchange } from 'react-icons/md';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../../../UI/MKAccordion';
import CurrencyList from './CurrencyList';
import AddCurrency from './AddCurrency';

const CurrencySettings = () => {
  return (
    <>
      <Accordion type='single' collapsible className='w-full'>
        <AccordionItem value='item-1'>
          <AccordionTrigger className='text-lg font-semibold'>
            <div className='flex items-center gap-2'>
              <MdCurrencyExchange />
              Configuración de moneda
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <AddCurrency />
            <CurrencyList />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
};

export default CurrencySettings;
