import React, { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { ButtonVariantType } from '../../../types/common';
import { defaultButtonVariantStyle } from './utils';

interface IMKButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  className?: string;
  variant?: ButtonVariantType;
}
const MKButton = ({
  children,
  variant,
  className = '',
  onClick,
  ...otherprops
}: IMKButtonProps) => {
  const defaultVariant = defaultButtonVariantStyle(variant);
  return (
    <button
      onClick={onClick}
      className={twMerge(defaultVariant, className)}
      {...otherprops}
    >
      {children}
    </button>
  );
};

export default MKButton;
