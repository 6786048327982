import AdminConfiguration from '../../components/Admin/AdminConfig';
import AdminSideBar from '../../components/Admin/Layout/AdminSideBar';
import AdminHeader from '../../components/Layout/AdminHeader';

const AdminProfilePage = () => {
  return (
    <div>
      <AdminHeader />
      <div className='flex w-full'>
        <div className='flex w-full items-start justify-between'>
          <div className='w-[80px] 800px:w-[330px]'>
            <AdminSideBar active={8} />
          </div>
          <AdminConfiguration />
        </div>
      </div>
    </div>
  );
};

export default AdminProfilePage;
