import AdminHeader from '../components/Layout/AdminHeader';
import AdminSideBar from '../components/Admin/Layout/AdminSideBar';
import PartnersList from 'src/components/Admin/Partners/PartnersList';

const AdminDashboardSellers = () => {
  return (
    <div>
      <AdminHeader />
      <div className='flex w-full'>
        <div className='flex w-full items-start justify-between'>
          <div className='w-[80px] 800px:w-[330px]'>
            <AdminSideBar active={3} />
          </div>
          <PartnersList />
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardSellers;
