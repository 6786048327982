import axios from 'axios';
import { server } from '../../server';
import { IStaffRoleCreate } from '../../datatypes/interfaces/staff';

export const createStaffService = async (staff: IStaffRoleCreate) => {
  try {
    const result = await axios.post(`${server}/staff/create`, staff, {
      withCredentials: true,
    });
    return result;
  } catch (error) {
    return { status: 500 };
  }
};

export const findMyStaff = async () => {
  try {
    const result = await axios.post(
      `${server}/staff/get-my-staff`,
      {},
      {
        withCredentials: true,
      }
    );
    return result.data;
  } catch (error) {
    return { status: 500 };
  }
};

export const findStaffRoles = async (staffId) => {
  try {
    const result = await axios.post(
      `${server}/staff/get-staff-details`,
      { _id: staffId },
      {
        withCredentials: true,
      }
    );
    return result?.data;
  } catch (error) {
    return { status: 500 };
  }
};

export const updateStaffService = async (staff) => {
  try {
    const updateResult = await axios.post(`${server}/staff/update`, staff, {
      withCredentials: true,
    });
    if (updateResult?.data?.success) {
      return updateResult.data;
    }
    return updateResult.data;
  } catch (error) {
    return { status: 500 };
  }
};

export const findAllStaffMembers = async () => {
  try {
    const { data } = await axios.get(`${server}/admin/admin-all-users`, {
      withCredentials: true,
    });
    return {
      success: true,
      data,
    };
  } catch (error: any) {
    return {
      success: false,
      message: error.response.data.message,
    };
  }
};
