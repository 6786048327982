import React from 'react';
import { BiMessageSquareDetail } from 'react-icons/bi';
import { FiPackage, FiShoppingBag } from 'react-icons/fi';
import { FaRegUser } from 'react-icons/fa';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const AdminHeader = () => {
  const { user } = useSelector((state) => state.user);

  return (
    <div className='sticky left-0 top-0 z-30 flex h-[80px] w-full items-center justify-between bg-white px-4 shadow'>
      <div>
        <Link to='/'>
          <img
            className='h20 w-20'
            src='/assets/mercoline-logo-300x300.jpeg'
            alt=''
          />
        </Link>
      </div>
      <div className='flex items-center'>
        <div className='mr-4 flex items-center'>
          <Link to='/dashboard-products' className='hidden 800px:block'>
            <FiShoppingBag
              color='#555'
              size={30}
              className='mx-5 cursor-pointer'
            />
          </Link>
          <Link to='/dashboard-orders' className='hidden 800px:block'>
            <FiPackage color='#555' size={30} className='mx-5 cursor-pointer' />
          </Link>
          <Link to='/dashboard-messages' className='hidden 800px:block'>
            <BiMessageSquareDetail
              color='#555'
              size={30}
              className='mx-5 cursor-pointer'
            />
          </Link>
          {user?.avatar?.url ? (
            <img
              src={`${user?.avatar?.url}`}
              alt=''
              className='h-[50px] w-[50px] rounded-full object-cover'
            />
          ) : (
            <>
              <FaRegUser color='#555' className='h-6 w-6 ' />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
