import axios from 'axios';
import { server } from '../../server';
import { DEFAULT_ERROR_MESSAGE } from '../../utils/constants/common';

interface IAdminLogin {
  email: string;
  password: string;
}

export const adminLoginService = async ({ email, password }: IAdminLogin) => {
  try {
    const { data } = await axios.post(`${server}/admin/login-admin`, {
      email,
      password,
    });
    return data;
  } catch (error: any) {
    return {
      error: true,
      message: error.message || DEFAULT_ERROR_MESSAGE,
    };
  }
};
