import { create } from 'zustand';
import { IFetchState } from '../interfaces/common';
import { BannerResponse } from '../interfaces/admin/banners';

interface IBannersStore {
  banners: BannerResponse | null;
  // createBanner: (banner: CreateBanner) => void;
  imageUploaded: string | null;
  state: IFetchState;
  uploadImage: (image: string) => void;
  createBanner: (loading: boolean, error?: boolean, message?: string) => void;
  setAllBanners: (banners: BannerResponse) => void;
  clearState: () => void;
}

export const useBannersStore = create<IBannersStore>((set) => ({
  banners: null,
  state: { loading: false, error: false, message: '', action: undefined },
  imageUploaded: null,
  createBanner: (
    loading: boolean,
    error: boolean = false,
    message: string = ''
  ) => {
    set({
      state: { loading, error, message, action: 'create' },
    });
  },
  uploadImage: (image: string) => {
    set({ imageUploaded: image });
  },
  setAllBanners: (banners: BannerResponse) => set({ banners }),
  clearState: () =>
    set({
      state: { loading: false, error: false, message: '', action: undefined },
    }),
}));
