import { MdAdd } from 'react-icons/md';
import MKButton from '../../../../../UI/MKButton';
import { useState } from 'react';
import AddCurrencyDrawer from './AddCurrencyDrawer';

const AddCurrency = () => {
  const [openAddNewCurrency, setOpenAddNewCurrency] = useState(false);
  const handleOpenDrawer = () => {
    setOpenAddNewCurrency(true);
  };
  const handleCloseDrawer = () => {
    setOpenAddNewCurrency(false);
  };
  return (
    <section className='flex justify-end'>
      <MKButton onClick={handleOpenDrawer} className='flex px-4'>
        <MdAdd className='h-6 w-6 items-center' />
        <p>Agregar nueva moneda</p>
      </MKButton>
      {openAddNewCurrency && (
        <AddCurrencyDrawer handleCloseDrawer={handleCloseDrawer} />
      )}
    </section>
  );
};

export default AddCurrency;
