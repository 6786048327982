import React, { useEffect, useState } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createProduct } from '../../redux/actions/product';
import { categoriesData } from '../../static/data';
import { toast } from 'react-toastify';
import { MdArrowBackIosNew } from 'react-icons/md';
import styles from '../../styles/styles';

const CreateProduct = () => {
  const { seller } = useSelector((state) => state.seller);
  const { success, error } = useSelector((state) => state.products);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [images, setImages] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [tags, setTags] = useState('');
  const [originalPrice, setOriginalPrice] = useState();
  const [discountPrice, setDiscountPrice] = useState();
  const [stock, setStock] = useState();

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
    if (success) {
      toast.success('Product created successfully!');
      navigate('/dashboard');
      window.location.reload();
    }
  }, [dispatch, error, success]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    console.log('files:', files.length);
    // setImages([]);

    files.forEach((file) => {
      const reader = new FileReader();
      console.log('reader.readyState:', reader.readyState);

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newForm = new FormData();

    images.forEach((image) => {
      newForm.set('images', image);
    });
    newForm.append('name', name);
    newForm.append('description', description);
    newForm.append('category', category);
    newForm.append('tags', tags);
    newForm.append('originalPrice', originalPrice);
    newForm.append('discountPrice', discountPrice);
    newForm.append('stock', stock);
    newForm.append('shopId', seller.shopId);
    dispatch(
      createProduct({
        name,
        description,
        category,
        tags,
        originalPrice,
        discountPrice,
        stock,
        shopId: seller.shopId,
        images,
      })
    );
  };
  console.log('images:', images);

  return (
    <div className='h-full w-full overflow-y-scroll  rounded-md  p-2'>
      <div className='item-center mb-4 flex justify-between rounded-md border-b-2 bg-white p-3 shadow-sm'>
        <div className='flex items-center px-2'>
          <div className='mr-4 self-center'>
            <button
              className='inline-block max-h-min p-1'
              onClick={() => navigate('/dashboard-products')}
            >
              <MdArrowBackIosNew className='h-6 w-6 text-gray-400' />
            </button>
          </div>{' '}
          <h3 className='text-center text-lg'>Crear producto</h3>
        </div>
      </div>

      {/* create product form */}
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className='pb-2'>
            Nombre <span className='text-red-500'>*</span>
          </label>
          <input
            type='text'
            name='name'
            value={name}
            className='mt-2 block h-[35px] w-full appearance-none rounded-[3px] border border-gray-300 px-3 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
            onChange={(e) => setName(e.target.value)}
            placeholder='Enter your product name...'
          />
        </div>
        <br />
        <div>
          <label className='pb-2'>
            Descripción <span className='text-red-500'>*</span>
          </label>
          <textarea
            cols='30'
            required
            rows='8'
            type='text'
            name='description'
            value={description}
            className='mt-2 block w-full appearance-none rounded-[3px] border border-gray-300 px-3 pt-2 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
            onChange={(e) => setDescription(e.target.value)}
            placeholder='Enter your product description...'
          ></textarea>
        </div>
        <br />
        <div>
          <label className='pb-2'>
            Categoria <span className='text-red-500'>*</span>
          </label>
          <select
            className='mt-2 h-[35px] w-full rounded-[5px] border'
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value='Choose a category'>Selecciona una categoria</option>
            {categoriesData &&
              categoriesData.map((i) => (
                <option value={i.title} key={i.title}>
                  {i.title}
                </option>
              ))}
          </select>
        </div>
        <br />
        <div>
          <label className='pb-2'>Tags</label>
          <input
            type='text'
            name='tags'
            value={tags}
            className='mt-2 block h-[35px] w-full appearance-none rounded-[3px] border border-gray-300 px-3 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
            onChange={(e) => setTags(e.target.value)}
            placeholder='Enter your product tags...'
          />
        </div>
        <br />
        <div>
          <label className='pb-2'>Precio</label>
          <input
            type='number'
            name='price'
            value={originalPrice}
            className='mt-2 block h-[35px] w-full appearance-none rounded-[3px] border border-gray-300 px-3 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
            onChange={(e) => setOriginalPrice(e.target.value)}
            placeholder='Enter your product price...'
          />
        </div>
        <br />
        <div>
          <label className='pb-2'>
            Precio (Con Descuento) <span className='text-red-500'>*</span>
          </label>
          <input
            type='number'
            name='price'
            value={discountPrice}
            className='mt-2 block h-[35px] w-full appearance-none rounded-[3px] border border-gray-300 px-3 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
            onChange={(e) => setDiscountPrice(e.target.value)}
            placeholder='Enter your product price with discount...'
          />
        </div>
        <br />
        <div>
          <label className='pb-2'>
            Stock <span className='text-red-500'>*</span>
          </label>
          <input
            type='number'
            name='price'
            value={stock}
            className='mt-2 block h-[35px] w-full appearance-none rounded-[3px] border border-gray-300 px-3 placeholder-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm'
            onChange={(e) => setStock(e.target.value)}
            placeholder='Enter your product stock...'
          />
        </div>
        <br />
        <div>
          <label className='pb-2'>
            Cargar Imagen<span className='text-red-500'>*</span>
          </label>
          <input
            type='file'
            name=''
            id='upload'
            className='hidden'
            multiple
            onChange={handleImageChange}
          />
          <div className='flex w-full flex-wrap items-center'>
            <label htmlFor='upload'>
              <AiOutlinePlusCircle size={30} className='mt-3' color='#555' />
            </label>
            {images &&
              images.map((i) => (
                <img
                  src={i}
                  key={i}
                  alt=''
                  className='m-2 h-[120px] w-[120px] object-cover'
                />
              ))}
          </div>
          <br />
          <div className='flex justify-center'>
            <input
              type='submit'
              value='Create'
              className={`${styles.button} w-2/3`}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateProduct;
