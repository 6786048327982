import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import AllProducts from '../../components/Shop/AllProducts';
import DashboardCommonLayout from '../../components/Layout/DashboardCommonLayout';
import { useSelector } from 'react-redux';
import { SHOP_RESOURCE_ROLE_NAME } from 'src/utils/constants/common';

const ShopAllProducts = () => {
  const { seller } = useSelector((state) => state.seller);
  const readPermission =
    seller?.isAdmin ||
    seller?.permission[SHOP_RESOURCE_ROLE_NAME.products]?.permissions?.read;

  return (
    <DashboardCommonLayout>
      <>
        <div className='flex w-full justify-between'>
          <div className='w-[80px] 800px:w-[330px]'>
            <DashboardSideBar active={3} />
          </div>
          {readPermission && (
            <section className='w-full'>
              <AllProducts />
            </section>
          )}
          {!readPermission && (
            <section>
              <p>No tienes acceso a este modulo</p>
            </section>
          )}
        </div>
      </>
    </DashboardCommonLayout>
  );
};

export default ShopAllProducts;
