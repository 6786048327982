const styles = {
  custom_container: 'w-11/12 hidden sm:block',
  heading:
    'text-[27px] text-center md:text-start font-[600] font-Roboto pb-[20px]',
  section: 'w-11/12 mx-auto',
  productTitle: 'text-xl font-[600] font-Roboto text-[#333]',
  productDiscountPrice: 'font-bold text-[18px] text-[#333] font-Roboto',
  price: 'font-[500] text-[16px] text-[#d55b45] pl-3 mt-[-4px] line-through',
  shop_name: 'pt-3 text-[15px] text-blue-400 pb-3',
  active_indicator: 'absolute bottom-[-27%] left-0 h-[3px] w-full bg-[crimson]',
  button:
    ' bg-primary-200 h-[50px] my-3 flex items-center justify-center rounded-xl cursor-pointer hover:bg-primary-500 text-white hover:text-primary-100',
  cart_button:
    'px-[10px] h-[38px] rounded-xl bg-primary-200 hover:bg-primary-500 text-white hover:text-primary-100 flex items-center justify-center cursor-pointer',
  cart_button_text: 'text-[#fff] text-[16px] font-[600]',
  input: 'w-full border p-1 rounded-[5px]',
  activeStatus:
    'w-[10px] h-[10px] rounded-full absolute top-0 right-1 bg-[#40d132]',
  noramlFlex: 'flex items-center',
  default_input:
    'block w-full rounded-md border border-gray-200 px-3 bg-gray-50 py-3 placeholder-gray-400 shadow-sm focus:border-primary-200 focus:outline-none focus:ring-primary-200 sm:text-sm',
  formTextError: 'text-red-500 text-sm',
};

export default styles;
