import useDriverSession from '../../../hooks/drivers/useDriverSession';
import MKCard from '../../UI/MKCard';

const OrdersOnHold = () => {
  const { driverPendingOrders } = useDriverSession();
  return (
    <section>
      <p>Ordenes en espera de aprobación</p>
      <MKCard>
        <p>Lista</p>
      </MKCard>
    </section>
  );
};

export default OrdersOnHold;
