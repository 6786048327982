import React, { useState } from 'react';
import { IoMdAdd } from 'react-icons/io';
import { twMerge } from 'tailwind-merge';

import MKButton from '../../UI/MKButton';
import styles from '../../../styles/styles';
import useCategories from '../../../hooks/category/useCategoriesData';
import CategoryItem from './CategoryItem';
import EmptyState from '../../Common/EmptyState';
import AddCategory from './AddCategory';

const CategoryList = () => {
  const [enableCreateCategory, setEnableCreateCategory] = useState(false);
  const { categories, state, refetch } = useCategories();

  const closeAddCategoryDrawer = (triggerFetch?: boolean) => {
    setEnableCreateCategory(false);
    if (triggerFetch) {
      refetch();
    }
  };
  return (
    <>
      <section className='mt-4 w-full px-2 md:px-6'>
        <div className='item-center mb-4 flex w-full justify-between rounded-md border-b-2 p-3 shadow-sm'>
          <div className='flex items-center px-2'>
            <h3 className='text-center text-lg'>Categoría de productos</h3>
          </div>

          <MKButton
            onClick={() => setEnableCreateCategory(true)}
            variant='none'
          >
            <section className={twMerge(styles.button, 'my-1 h-10  px-2')}>
              <IoMdAdd />
              Crear categoría
            </section>
          </MKButton>
        </div>

        {!enableCreateCategory && state.loading && <p>Cargando...</p>}

        {Array.isArray(categories) &&
          (enableCreateCategory || !state.loading) && (
            <section>
              {categories.length === 0 ? (
                <section>
                  <EmptyState />
                  <p className='text-center text-base md:text-xl'>
                    No tienes productos agregados
                  </p>
                </section>
              ) : (
                <section className=' flex flex-col gap-4'>
                  {categories.map((category) => (
                    <div key={`cl-${category._id}`}>
                      <CategoryItem {...category} />
                    </div>
                  ))}
                </section>
              )}
            </section>
          )}
      </section>
      {enableCreateCategory && (
        <AddCategory closeAddCategoryDrawer={closeAddCategoryDrawer} />
      )}
    </>
  );
};

export default CategoryList;
