import SinglePartner from './SinglePartner';
interface IAllPartnersListProps {
  partners: any[];
}
const AllPartnersList = ({ partners }: IAllPartnersListProps) => {
  return (
    <section className='flex w-full flex-col gap-3'>
      {partners.map((partner) => (
        <SinglePartner partner={partner} />
      ))}
    </section>
  );
};

export default AllPartnersList;
