import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/common/redux.hooks';

const ProtectedRoute = ({ children }) => {
  const { loading, isAuthenticated } = useAppSelector((state) => state.user);
  if (loading === false) {
    if (!isAuthenticated) {
      return <Navigate to='/login' replace />;
    }
    return children;
  }
};

export default ProtectedRoute;
