
// get city location --- admin
export const changeCityLocation = (location) => (dispatch) => {
  dispatch({
    type: "ChangeCityLocation",
    payload: location,
  });
};

export const changeCityRange = (range) => (dispatch) => {
  dispatch({
    type: "ChangeCityRange",
    payload: range,
  });
};
