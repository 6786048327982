import { IoMdCloseCircle } from 'react-icons/io';
import MKButton from '../../UI/MKButton';
import MKDrawer from '../../UI/MKDrawer';
import { useAppSelector } from '../../../hooks/common/redux.hooks';
import { usePublicDrivers } from '../../../hooks/drivers/usePublicDrivers';
import LoadingStateAnimation from '../../Common/LoadingState';
import EmptyState from '../../Common/EmptyState';
import {
  MdAssignmentTurnedIn,
  MdCurrencyExchange,
  MdMail,
  MdOutlinePhoneAndroid,
} from 'react-icons/md';
import { FaMapMarkedAlt, FaMotorcycle } from 'react-icons/fa';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '../../UI/MKAccordion';
import { toast } from 'react-toastify';
import MKCard from '../../UI/MKCard';
import DealerAssigned from './DealerAssigned';

interface AssignDelaerProps {
  closeDrawer: () => void;
  orderId: string;
  driverId?: string;
}

const AssignDealer = ({
  closeDrawer,
  orderId,
  driverId,
}: AssignDelaerProps) => {
  console.log('orderId', orderId);

  const { seller } = useAppSelector((state) => state.seller);
  const shopCity = seller?.shop?.address?.city;

  const { drivers, driverState, assignDriverToOrder } =
    usePublicDrivers(orderId);

  const handleAssignDriverToOrder = async (driverId: string) => {
    const result = await assignDriverToOrder(orderId, driverId);
    if (result.success) {
      toast.success('Repartidor asignado correctamente');
      closeDrawer();
      return;
    }
    toast.error('Error al asignar repartidor');
  };

  console.log('drivers', drivers?.[3]);

  return (
    <MKDrawer>
      <section className='flex flex-col gap-2 p-2 md:p-4'>
        <section className='flex w-full items-center justify-between gap-2'>
          <p className='text-lg'>
            {driverId ? 'Repartidores' : 'Repartidores disponibles'}
          </p>
          <MKButton onClick={() => closeDrawer()} variant='none'>
            <IoMdCloseCircle className='h-7 w-7' />
          </MKButton>
        </section>
        {/* <MKButton variant='outlined'>
          <p>Asignar repartidor propio</p>
        </MKButton> */}

        {driverId && typeof orderId === 'string' && (
          <MKCard className='mt-2 flex flex-col items-center border-green-700'>
            <p className='text-lg font-bold'>Repartidor asignado</p>
            <DealerAssigned id={driverId} city={orderId} />
          </MKCard>
        )}

        {driverState.loading && (
          <section className='flex flex-col items-center'>
            <LoadingStateAnimation />
            <p className='text-center text-lg font-bold text-gray-400'>
              Buscando...
            </p>
          </section>
        )}

        {driverId && (
          <p className='my-2 text-center text-lg font-bold text-gray-500'>
            Asignar otro repartidor
          </p>
        )}
        {Array.isArray(drivers) && (
          <>
            {drivers.length === 0 ? (
              <section>
                <EmptyState />
                <p>No hemos encontrado repartidores cercanos</p>
              </section>
            ) : (
              <>
                {drivers.map((driver) => (
                  <section key={`driver-assign-${driver._id}`}>
                    <Accordion type='single' collapsible className='w-full'>
                      <AccordionItem value='item-1'>
                        <AccordionTrigger className='text-lg font-semibold'>
                          <div className='flex items-center gap-2'>
                            {driver.firstName} {driver.lastName}{' '}
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          <MKButton
                            variant='outlined'
                            className='mb-3 flex items-center gap-2'
                            onClick={() =>
                              handleAssignDriverToOrder(driver._id)
                            }
                          >
                            <MdAssignmentTurnedIn />
                            <p>Asignar este repartidor</p>
                          </MKButton>
                          <section className='flex flex-wrap gap-2'>
                            <div className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
                              <div className='rounded-lg bg-primary-100 p-2'>
                                <FaMapMarkedAlt className='text-primary-500' />
                              </div>
                              <div>
                                <p className='font-semibold'>Ciudad</p>
                                <p className='text-gray-700'>{driver.city} </p>
                              </div>
                            </div>
                            <section className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
                              <div className='rounded-lg bg-primary-100 p-2'>
                                <MdOutlinePhoneAndroid className='text-primary-500' />
                              </div>
                              <div>
                                <p className='font-semibold'>Teléfono</p>
                                <p className='text-gray-700'>
                                  {driver.phoneNumber}
                                </p>
                              </div>
                            </section>
                            <section className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
                              <div className='rounded-lg bg-primary-100 p-2'>
                                <MdMail className='text-primary-500' />
                              </div>
                              <div>
                                <p className='text-left font-semibold'>
                                  Correo
                                </p>
                                <p className='text-gray-700'>
                                  {/* @ts-ignore */}
                                  {driver.email}
                                </p>
                              </div>
                            </section>
                            <div className='flex items-center gap-2 rounded-lg border border-dashed p-2 md:px-4'>
                              <div className='rounded-lg bg-primary-100 p-2'>
                                <FaMotorcycle className='text-primary-500' />
                              </div>
                              <div>
                                <p className='font-semibold'>Vehículo</p>
                                <p className='text-gray-700'>
                                  {driver.vehicleInfo.vehicleType}
                                </p>
                              </div>
                            </div>
                          </section>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  </section>
                ))}
              </>
            )}
          </>
        )}
      </section>
    </MKDrawer>
  );
};

export default AssignDealer;
