import MKCard from '../../../UI/MKCard';
import { getEmojiFlag } from 'countries-list';
import { FaTruck, FaWallet } from 'react-icons/fa';
import { MdOutlinePhone } from 'react-icons/md';

import useAdminConfig from '../../../../hooks/admin/config/config.hooks';
import MKButton from '../../../UI/MKButton';

import BeginSiteConfiguration from './BeginSiteConfiguration';
import RegionsCitiesList from './Regions/RegionsAndCitiesList';
import CurrencySettings from './CurrencySettings';

const ConfigList = () => {
  const { config, loadingConfig } = useAdminConfig();

  return (
    <>
      {/* Show this ui once we get a response fro server and if there does not exist a previous configuration  */}
      {config === null && !loadingConfig && <BeginSiteConfiguration />}
      {config !== null && !loadingConfig && (
        <>
          {/* Country config */}
          <section>
            <section className='flex justify-between md:flex-row'>
              <div className='flex items-center gap-4'>
                <p>Pais</p>
                <MKCard className='bg-slate-50 p-2'>
                  <p>
                    {/* @ts-ignore */}
                    {getEmojiFlag(config.country_code)} {config.country_name}
                  </p>
                </MKCard>
              </div>
              <MKButton
                variant='contained'
                className='w-24 hover:bg-primary-500'
              >
                <p className='text-white'>Editar</p>
              </MKButton>
            </section>
            <section className='my-4 flex flex-col gap-6 md:flex-row'>
              <MKCard className='w-full md:w-2/6'>
                <p className='mb-2 border-b text-lg font-bold text-slate-500'>
                  Configuración local
                </p>
                <div className='flex items-center justify-between gap-4'>
                  <p>Código de pais</p>
                  <MKCard className='bg-slate-50 p-2 px-4'>
                    <p>{config.country_code} </p>
                  </MKCard>
                </div>

                <div className=' mt-3 flex items-center justify-between gap-4'>
                  <p>Código telefónico de país</p>
                  <MKCard className='bg-slate-50 p-2'>
                    <p className='felx row flex items-center gap-2'>
                      <MdOutlinePhone />
                      {config.telephone_country_code}
                    </p>
                  </MKCard>
                </div>

                <div className='mt-3 flex items-center justify-between gap-4'>
                  <p>Moneda</p>
                  <MKCard className='bg-slate-50 p-2'>
                    <p className='felx row flex items-center gap-2'>
                      <FaWallet />
                      {config.currency}
                    </p>
                  </MKCard>
                </div>
              </MKCard>

              <MKCard className='w-full'>
                <p className='mb-2 border-b text-lg font-bold text-slate-500'>
                  Configuración Financiera
                </p>
                <div className=' flex items-center justify-between gap-4'>
                  <p>Tarifa de servicio</p>
                  <MKCard className='bg-slate-50 p-2'>
                    <p>{config.service_fee}%</p>
                  </MKCard>
                </div>

                <div className='mt-3 flex items-center justify-between gap-4'>
                  <p>Costo de delivery</p>
                  <MKCard className='bg-slate-50 p-2'>
                    <p className='felx row flex items-center gap-2'>
                      <FaTruck />
                      {config.currency_sign} {config.shipping_price}
                    </p>
                  </MKCard>
                </div>

                <div className='mt-3 flex items-center justify-between gap-4'>
                  <p>Impuesto (IVA)</p>
                  <MKCard className='bg-slate-50 p-2'>
                    <p>{config.tax}%</p>
                  </MKCard>
                </div>
              </MKCard>
            </section>
          </section>

          {/* Cities */}
          <RegionsCitiesList />

          {/* Currency */}
          <MKCard className='my-4'>
            <CurrencySettings />
          </MKCard>
        </>
      )}
    </>
  );
};

export default ConfigList;
