import { Button } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from '../Layout/Loader';
import { getAllOrdersOfShop } from '../../redux/actions/order';
import MKCard from '../UI/MKCard';
import {
  ORDER_STATUS,
  SHOP_RESOURCE_ROLE_NAME,
} from '../../utils/constants/common';
import { MdOutlineHideImage } from 'react-icons/md';

const AllOrders = () => {
  const { orders, isLoading } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller.shopId));
  }, [dispatch]);
  const PRODUCT_IMAGES_TO_SHOW = 6;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='mx-8 mt-4 w-full bg-white pt-1'>
          {orders &&
            orders.map((order) => (
              <MKCard className='my-2'>
                <Link to={`/dashboard-order/${order._id}`}>
                  <p className='text-sm font-bold text-primary-200'>
                    {ORDER_STATUS[order.status]?.label}
                  </p>
                  <p>
                    {order?.deliveryDate &&
                      new Intl.DateTimeFormat('es', {
                        weekday: 'long',
                        day: '2-digit',
                        month: 'long',
                        year: 'numeric',
                      }).format(new Date(order?.deliveryDate))}
                  </p>
                  <section>
                    {Array.isArray(order?.cart) && (
                      <div className='flex flex-row gap-4'>
                        {[...order.cart.slice(0, PRODUCT_IMAGES_TO_SHOW)].map(
                          (product) => (
                            <div className='rounded-full border p-3'>
                              {Array.isArray(product.images) &&
                              product.images.length > 0 ? (
                                <img
                                  className='h-12 w-12 object-contain'
                                  src={product.images[0].url}
                                  alt={product.name}
                                />
                              ) : (
                                <MdOutlineHideImage className='h-12 w-12 text-gray-300' />
                              )}
                            </div>
                          )
                        )}
                        {order.cart.length >= PRODUCT_IMAGES_TO_SHOW + 1 && (
                          <div className=' rounded-full border p-3'>
                            <div className='flex h-12 w-12 items-center justify-center text-center'>
                              +{order.cart.length - PRODUCT_IMAGES_TO_SHOW}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </section>
                  <div className='mt-4 flex flex-row items-center'>
                    <div>
                      <p>Total: C$ {order.totalPrice.toFixed(2)}</p>
                    </div>
                    <div className='flex'>
                      <button className='ml-4 rounded-md bg-primary-200 p-2 text-white'>
                        Ver detalle
                      </button>
                    </div>
                  </div>
                </Link>
              </MKCard>
            ))}
        </div>
      )}
    </>
  );
};

export default AllOrders;
