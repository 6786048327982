import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../hooks/common/redux.hooks';

const ProtectedAdminRoute = ({ children }) => {
  const { isLoading, isAuthenticated } = useAppSelector((state) => state.admin);
  const [activeUser, setActiveUser] = useState(isAuthenticated);
  const [setIsLoading, setSetIsLoading] = useState(isLoading);

  useEffect(() => {
    setActiveUser(isAuthenticated);
  }, [isAuthenticated]);
  useEffect(() => {
    setSetIsLoading(isLoading);
  }, [isLoading]);

  if (setIsLoading === false) {
    if (!activeUser) {
      return <Navigate to='/admin-login' replace />;
    }
    return children;
  }

  return <></>;
};

export default ProtectedAdminRoute;
