import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { findMyStaff } from '../../../../services/staff/staff';
import MKCard from '../../../UI/MKCard';
import StaffDetails from '../../../../pages/Shop/Staff/StaffDetails';
import UpdateStaff from '../../../../pages/Shop/Staff/UpdateStaff';
import { MdOutlineMailOutline } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa';

const MyStaffList = () => {
  const [myStaff, setMyStaff] = useState([]);
  const [openStaffDetails, setOpenStaffDetails] = useState(false);
  const [staffDetailsDrawer, setStaffDetailsDrawer] = useState();
  const [enableEditing, setEnableEditing] = useState(false);
  const handleOpenDetail = (staff) => {
    setOpenStaffDetails(true);
    setStaffDetailsDrawer(staff);
  };

  const handleEnableEditing = () => {
    setEnableEditing(true);
  };
  const handleCloseDrawer = () => {
    setOpenStaffDetails(false);
    setEnableEditing(false);
  };

  const getMyStaff = async () => {
    const result = await findMyStaff();
    if (result.status === 500) {
      toast.error(
        'Ha ocurrido un error, asegurate que tienes los permisos necesarios'
      );
      return;
    }
    console.log('allMyStaff:', result.alMyStaffWithRoles);

    setMyStaff(result.alMyStaffWithRoles);
  };

  useEffect(() => {
    getMyStaff();
    console.log('mount my staff list');
  }, []);

  return (
    <section className='flex w-full flex-col'>
      {Array.isArray(myStaff) &&
        myStaff.length > 0 &&
        myStaff.map((staff) => (
          <button onClick={() => handleOpenDetail(staff)}>
            <MKCard className='mb-2'>
              <section className='flex justify-between'>
                <div>
                  <p className='text-primary-200'>
                    {staff.name} {staff.lastName}
                  </p>
                  <section className='flex items-center justify-center'>
                    <div className='flex items-center'>
                      <MdOutlineMailOutline />
                      {staff.email}
                    </div>
                  </section>
                </div>
                <div className='flex items-center'>
                  {staff?.isActive && (
                    <div className='flex h-6 w-6 items-center justify-center rounded-sm border border-primary-200'>
                      <FaCheck className=' text-primary-200' />
                    </div>
                  )}
                  Empleado activo
                </div>
              </section>
            </MKCard>
          </button>
        ))}
      {openStaffDetails && staffDetailsDrawer && (
        <StaffDetails
          handleCloseStaffDrawer={handleCloseDrawer}
          handleEnableEditing={handleEnableEditing}
          staff={staffDetailsDrawer}
        />
      )}
      {openStaffDetails && enableEditing && (
        <UpdateStaff
          handleCloseStaffDrawer={handleCloseDrawer}
          staff={staffDetailsDrawer}
          getMyStaff={getMyStaff}
        />
      )}
    </section>
  );
};

export default MyStaffList;
