import { useState } from 'react';
import { toast } from 'react-toastify';
import { RxCross2 } from 'react-icons/rx';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { SubmitHandler, useForm } from 'react-hook-form';

import MKDrawer from '../../../UI/MKDrawer';
import { ICreateProduct } from '../../../../interfaces/products/createProduct';
import useShopProducts from '../../../../hooks/products/useShopProducts';

import styles from '../../../../styles/styles';
import { REQUEST_STATUS } from '../../../../utils/constants/common';
import SelectCategory from '../Common/SelectCategory';
import { useAppSelector } from '../../../../hooks/common/redux.hooks';

interface ICreateProductProps {
  handleCloseModal: () => void;
}

const CreateProduct = ({ handleCloseModal }: ICreateProductProps) => {
  const [selectedCategory, setSelectedCategory] = useState({});
  const { seller } = useAppSelector((state) => state.seller);

  const { refetchProducts, status, isLoading, clearState, create } =
    useShopProducts({ shopId: seller.shopId });

  const onChangeSelected = (newSelected) => {
    setSelectedCategory(newSelected);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ICreateProduct>({ defaultValues: { isActive: true } });
  const [images, setImages] = useState([]);

  const handleCreateProduct: SubmitHandler<ICreateProduct> = async ({
    name,
    originalPrice,
    discountPrice,
    description,
    stock,
    isActive,
  }) => {
    if (name === '' || Object.keys(selectedCategory).length === 0) {
      toast.error('Por favor completar los campos requeridos');
      return;
    }
    if (Number(originalPrice) <= Number(discountPrice)) {
      toast.error('El precio de descuento debe ser menor al precio original');
      return;
    }
    const categoryIds = Object.keys(selectedCategory).map(
      (cat) => selectedCategory[cat]._id
    );

    create({
      name,
      originalPrice,
      discountPrice,
      description,
      categories: categoryIds,
      stock,
      images: images,
      isActive,
    });
  };

  if (!isLoading && status.success && status.type === REQUEST_STATUS.create) {
    clearState();
    toast.success('Producto agregado exitosamente');
    refetchProducts();
    handleCloseModal();
  }

  if (!isLoading && status.error && status.type === REQUEST_STATUS.create) {
    toast.error('Lo sentimos, ha ocurrido un error al agregar este producto');
    clearState();
  }

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    // setImages([]);

    files.forEach((file: any) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, reader.result]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <MKDrawer>
      <div className='flex justify-center'>
        <div className='w-full overflow-y-scroll rounded-md bg-white shadow'>
          <div className='my-4 w-full border-b'>
            <div className='flex items-center justify-between px-6 pb-2 shadow'>
              <h5 className='text-center text-xl'>Crear producto</h5>
              <button onClick={handleCloseModal} className='flex'>
                <RxCross2 size={25} className='cursor-pointer' />
              </button>
            </div>
          </div>

          {/* create product form */}
          <form onSubmit={handleSubmit(handleCreateProduct)}>
            <section className='flex flex-col gap-2 px-6 md:gap-4 '>
              <div>
                <label className='pb-2'>
                  Nombre <span className='text-red-500'>*</span>
                </label>
                <input
                  type='text'
                  name='name'
                  {...register('name')}
                  className={styles.default_input}
                  required
                  placeholder='Ingresa el nombre de tu producto...'
                />
              </div>

              <div>
                <label className='pb-2'>
                  Descripción <span className='text-red-500'>*</span>
                </label>
                <textarea
                  cols={30}
                  required
                  rows={8}
                  name='description'
                  className={styles.default_input}
                  placeholder='Ingresa una descripción del producto...'
                  {...register('description')}
                ></textarea>
              </div>

              <div>
                <SelectCategory
                  selectedCategory={selectedCategory}
                  onChangeSelected={onChangeSelected}
                />
              </div>

              <div>
                <label className='pb-2'>
                  Precio <span className='text-red-500'>*</span>
                </label>
                <input
                  type='number'
                  name='price'
                  step='any'
                  required
                  className={styles.default_input}
                  placeholder='Ingrese el precio del producto...'
                  {...register('originalPrice')}
                />
              </div>
              <div>
                <label className='pb-2'>Precio (Con descuento)</label>
                <input
                  type='number'
                  step='any'
                  name='discountprice'
                  className={styles.default_input}
                  placeholder='Ingrese el precio del producto con discuento...'
                  {...register('discountPrice')}
                />
              </div>
              <div>
                <label className='pb-2'>
                  Existencias de productos{' '}
                  <span className='text-red-500'>*</span>
                </label>
                <input
                  type='number'
                  name='price'
                  required
                  min={0}
                  className={styles.default_input}
                  {...register('stock')}
                  placeholder='Cantidad de productos en existencias...'
                />
              </div>
              <div>
                <label className='pb-2'>
                  Imagenes <span className='text-red-500'>*</span>
                </label>
                <input
                  type='file'
                  name=''
                  id='upload'
                  className='hidden'
                  multiple
                  onChange={handleImageChange}
                />
                <div className='flex w-full flex-wrap items-center'>
                  <label htmlFor='upload'>
                    <AiOutlinePlusCircle
                      size={30}
                      className='mt-3 text-primary-500'
                    />
                  </label>
                  {Array.isArray(images) &&
                    images.length > 0 &&
                    images.map((i, key) => (
                      <img
                        src={i}
                        key={`prod-image${key}`}
                        alt=''
                        className='m-2 h-[120px] w-[120px] object-cover'
                      />
                    ))}
                </div>
                <div className='flex items-center gap-2 pt-2'>
                  <input
                    className='h-4 w-4 accent-primary-200'
                    {...register('isActive')}
                    type='checkbox'
                  />
                  <label htmlFor='isActive'>Activo</label>
                </div>
                <div className='flex justify-center'>
                  <button type='submit' className={`${styles.button} w-2/3`}>
                    Guardar cambios
                  </button>
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
    </MKDrawer>
  );
};

export default CreateProduct;
