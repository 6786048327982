import axios from 'axios';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { server } from '../../server';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowRight, AiOutlineSend } from 'react-icons/ai';
import styles from '../../styles/styles';
import { TfiGallery } from 'react-icons/tfi';
import { getTimeAgo } from '../../utils/datetime/time';

const DashboardMessages = () => {
  const { seller, isLoading } = useSelector((state) => state.seller);
  const [conversations, setConversations] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [currentChat, setCurrentChat] = useState();
  const [messages, setMessages] = useState([]);
  const [userData, setUserData] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [activeStatus, setActiveStatus] = useState(false);
  const [images, setImages] = useState();
  const [open, setOpen] = useState(false);
  const scrollRef = useRef(null);

  useEffect(() => {
    arrivalMessage &&
      currentChat?.members.includes(arrivalMessage.sender) &&
      setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    const getConversation = async () => {
      try {
        const resonse = await axios.get(
          `${server}/conversation/get-all-conversation-seller/${seller?._id}`,
          {
            withCredentials: true,
          }
        );

        setConversations(resonse.data.conversations);
      } catch (error) {
        // console.log(error);
      }
    };
    getConversation();
  }, [seller, messages]);

  const onlineCheck = (chat) => {
    const chatMembers = chat.members.find((member) => member !== seller?._id);
    const online = onlineUsers.find((user) => user.userId === chatMembers);

    return online ? true : false;
  };

  // get messages
  useEffect(() => {
    const getMessage = async () => {
      try {
        const response = await axios.get(
          `${server}/message/get-all-messages/${currentChat?._id}`
        );
        setMessages(response.data.messages);
      } catch (error) {
        console.log(error);
      }
    };
    getMessage();
  }, [currentChat]);

  const handleImageUpload = async (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setImages(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ beahaviour: 'smooth' });
  }, [messages]);

  return (
    <div className='m-5 h-[85vh] w-[90%] overflow-y-scroll rounded bg-white'>
      {!open && (
        <>
          <h1 className='py-3 text-center font-Poppins text-[30px]'>
            All Messages
          </h1>
          {/* All messages list */}
          {conversations &&
            conversations.map((item, index) => (
              <MessageList
                data={item}
                key={index}
                index={index}
                setOpen={setOpen}
                setCurrentChat={setCurrentChat}
                me={seller._id}
                setUserData={setUserData}
                userData={userData}
                online={onlineCheck(item)}
                setActiveStatus={setActiveStatus}
                isLoading={isLoading}
              />
            ))}
        </>
      )}
    </div>
  );
};

const MessageList = ({
  data,
  index,
  setOpen,
  setCurrentChat,
  me,
  setUserData,
  online,
  setActiveStatus,
  isLoading,
}) => {
  console.log(data);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const handleClick = (id) => {
    navigate(`/dashboard-messages?${id}`);
    setOpen(true);
  };
  const [active, setActive] = useState(0);

  useEffect(() => {
    const userId = data.members.find((user) => user != me);

    const getUser = async () => {
      try {
        const res = await axios.get(`${server}/user/user-info/${userId}`);
        setUser(res.data.user);
      } catch (error) {
        console.log(error);
      }
    };
    getUser();
  }, [me, data]);

  return (
    <div
      className={`flex w-full p-3 px-3 ${
        active === index ? 'bg-[#00000010]' : 'bg-transparent'
      }  cursor-pointer`}
      onClick={(e) =>
        setActive(index) ||
        handleClick(data._id) ||
        setCurrentChat(data) ||
        setUserData(user) ||
        setActiveStatus(online)
      }
    >
      <div className='relative'>
        <img
          src={`${user?.avatar?.url}`}
          alt=''
          className='h-[50px] w-[50px] rounded-full'
        />
        {online ? (
          <div className='absolute right-[2px] top-[2px] h-[12px] w-[12px] rounded-full bg-green-400' />
        ) : (
          <div className='absolute right-[2px] top-[2px] h-[12px] w-[12px] rounded-full bg-[#c7b9b9]' />
        )}
      </div>
      <div className='pl-3'>
        <h1 className='text-[18px]'>{user?.name}</h1>
        <p className='text-[16px] text-[#000c]'>
          {!isLoading && data?.lastMessageId !== user?._id
            ? 'You:'
            : user?.name.split(' ')[0] + ': '}{' '}
          {data?.lastMessage}
        </p>
      </div>
    </div>
  );
};

const SellerInbox = ({
  scrollRef,
  setOpen,
  newMessage,
  setNewMessage,
  sendMessageHandler,
  messages,
  sellerId,
  userData,
  activeStatus,
  handleImageUpload,
}) => {
  return (
    <div className='flex min-h-full w-full flex-col justify-between'>
      {/* message header */}
      <div className='flex w-full items-center justify-between bg-slate-200 p-3'>
        <div className='flex'>
          <img
            src={`${userData?.avatar?.url}`}
            alt=''
            className='h-[60px] w-[60px] rounded-full'
          />
          <div className='pl-3'>
            <h1 className='text-[18px] font-[600]'>{userData?.name}</h1>
            <h1>{activeStatus ? 'Active Now' : ''}</h1>
          </div>
        </div>
        <AiOutlineArrowRight
          size={20}
          className='cursor-pointer'
          onClick={() => setOpen(false)}
        />
      </div>

      {/* messages */}
      <div className='h-[65vh] overflow-y-scroll px-3 py-3'>
        {messages &&
          messages.map((item, index) => {
            return (
              <div
                className={`my-2 flex w-full ${
                  item.sender === sellerId ? 'justify-end' : 'justify-start'
                }`}
                ref={scrollRef}
              >
                {item.sender !== sellerId && (
                  <img
                    src={`${userData?.avatar?.url}`}
                    className='mr-3 h-[40px] w-[40px] rounded-full'
                    alt=''
                  />
                )}
                {item.images && (
                  <img
                    src={`${item.images?.url}`}
                    className='mr-2 h-[300px] w-[300px] rounded-[10px] object-cover'
                  />
                )}
                {item.text !== '' && (
                  <div>
                    <div
                      className={`w-max rounded p-2 ${
                        item.sender === sellerId ? 'bg-[#000]' : 'bg-[#38c776]'
                      } h-min text-[#fff]`}
                    >
                      <p>{item.text}</p>
                    </div>

                    <p className='pt-1 text-[12px] text-[#000000d3]'>
                      {getTimeAgo(item.createdAt)}
                    </p>
                  </div>
                )}
              </div>
            );
          })}
      </div>

      {/* send message input */}
      <form
        aria-required={true}
        className='relative flex w-full items-center justify-between p-3'
        onSubmit={sendMessageHandler}
      >
        <div className='w-[30px]'>
          <input
            type='file'
            name=''
            id='image'
            className='hidden'
            onChange={handleImageUpload}
          />
          <label htmlFor='image'>
            <TfiGallery className='cursor-pointer' size={20} />
          </label>
        </div>
        <div className='w-full'>
          <input
            type='text'
            required
            placeholder='Enter your message...'
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className={`${styles.input}`}
          />
          <input type='submit' value='Send' className='hidden' id='send' />
          <label htmlFor='send'>
            <AiOutlineSend
              size={20}
              className='absolute right-4 top-5 cursor-pointer'
            />
          </label>
        </div>
      </form>
    </div>
  );
};

export default DashboardMessages;
