import React from 'react';
import { useGetConfigRegions } from '../../../hooks/admin/config/cities.hooks';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../UI/MKSelect';

interface SelectRegionCityProps {
  selectedRegion: string;
  onChangeSelectedRegion: (region: string) => void;
  onChangeSelectedCity: (city: string) => void;
}
const SelectRegionCity = ({
  selectedRegion,
  onChangeSelectedRegion,
  onChangeSelectedCity,
}: SelectRegionCityProps) => {
  const { regionsAndCities } = useGetConfigRegions();

  const citiesBySelectedRegion =
    Array.isArray(regionsAndCities) &&
    regionsAndCities.find((region) => region._id === selectedRegion);

  return (
    <div className='flex gap-2'>
      <Select onValueChange={onChangeSelectedRegion}>
        <SelectTrigger className='w-[180px]'>
          <SelectValue placeholder='Departamento' />
        </SelectTrigger>
        <SelectContent className='bg-white'>
          {regionsAndCities &&
            regionsAndCities.map((region) => (
              <SelectItem value={region._id}>{region.name}</SelectItem>
            ))}
        </SelectContent>
      </Select>
      {typeof citiesBySelectedRegion !== 'undefined' && (
        <Select onValueChange={onChangeSelectedCity}>
          <SelectTrigger className='w-[180px]'>
            <SelectValue placeholder='Ciudad' />
          </SelectTrigger>
          <SelectContent className='bg-white'>
            {citiesBySelectedRegion.cities?.map((city) => (
              <SelectItem value={city._id}>{city.name}</SelectItem>
            ))}
          </SelectContent>
        </Select>
      )}
    </div>
  );
};

export default SelectRegionCity;
