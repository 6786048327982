import axios from 'axios';
import { server } from '../../server';
import { DEFAULT_ERROR_MESSAGE } from '../../utils/constants/common';

interface IRegionProps {
  name: string;
  active: boolean;
}
export const createRegionService = async ({ name, active }: IRegionProps) => {
  try {
    const { data } = await axios.post(`${server}/region/create-region`, {
      name,
      active,
    });
    return data;
  } catch (error) {
    return { error: true, message: error?.message || DEFAULT_ERROR_MESSAGE };
  }
};
