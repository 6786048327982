import { useState } from 'react';
import { useSelector } from 'react-redux';
import { saveConfigurationService } from '../../../services/admin/config';
import { useAppDispatch } from '../../common/redux.hooks';
import { refreshConfiguration } from '../../../redux/actions/config';

const useAdminConfig = () => {
  const dispatch = useAppDispatch();
  const { refresh, isLoading, error, configuration } = useSelector(
    // @ts-ignore
    (state) => state.configuration
  );
  const getConfig = async () => {
    dispatch(refreshConfiguration);
  };
  if (!isLoading && configuration === null) {
    getConfig();
  }

  return {
    config: configuration,
    loadingConfig: isLoading,
    refresh,
  };
};

interface ISaveConfigProps {
  country_name: string;
  currency_sign: string;
  currency: string;
  country_code: string;
  telephone_country_code: string;
  shipping_price: number;
  tax: number;
  service_fee: number;
}
export const useSaveAdminConfig = () => {
  const [savingConfig, setSavingConfig] = useState(false);
  const [saveConfigError, setSaveConfigError] = useState(false);

  const saveConfiguration = async ({
    country_name,
    country_code,
    currency_sign,
    currency,
    telephone_country_code,
    shipping_price,
    tax,
    service_fee,
  }: ISaveConfigProps) => {
    setSavingConfig(true);
    const configurationResponse = await saveConfigurationService({
      country_name,
      country_code,
      currency_sign,
      currency,
      telephone_country_code,
      shipping_price,
      tax,
      service_fee,
    });
    setSavingConfig(false);
    if (configurationResponse.success) {
      return configurationResponse;
    }
    if (configurationResponse.error) {
      setSaveConfigError(true);
      return {
        error: true,
      };
    }
  };

  return { saveConfigError, savingConfig, saveConfiguration };
};

export default useAdminConfig;
