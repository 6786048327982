import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { GiMoneyStack } from 'react-icons/gi';
import { FiShoppingBag } from 'react-icons/fi';
import { IoStorefrontOutline } from 'react-icons/io5';
import { FaMapMarkedAlt, FaMapPin } from 'react-icons/fa';

import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';

import { getAllOrdersOfAdmin } from '../../redux/actions/order';
import Loader from '../Layout/Loader';
import MKCard from '../UI/MKCard';
import { getAllSellers } from '../../redux/actions/sellers';
import { LineChart } from './Charts/LineChart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const AdminDashboardMain = () => {
  const dispatch = useDispatch();

  const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)',
          'rgba(255, 159, 64, 0.2)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Vendedores top',
      },
    },
  };
  const labels = [
    'Tienda San Benito',
    'Super Fitoria',
    'Super eco',
    'Super asiatico',
  ];

  const barData = {
    labels,
    datasets: [
      {
        label: 'Total',
        data: [300, 953, 599, 244],
        backgroundColor: '#C7E9E8',
      },
    ],
  };

  const { adminOrders, adminOrderLoading } = useSelector(
    (state) => state.order
  );
  const { sellers } = useSelector((state) => state.seller);

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
    if (!sellers) {
      dispatch(getAllSellers({ isApproved: true }));
    }
  }, []);

  const adminEarning =
    adminOrders &&
    adminOrders.reduce((acc, item) => acc + item.totalPrice * 0.1, 0);

  const adminBalance = adminEarning ? adminEarning.toFixed(2) : '0.00';

  const partners =
    Array.isArray(sellers) &&
    sellers.reduce((acc, current) => {
      return acc + (current.isApproved ? 1 : 0);
    }, 0);

  const potentialPartners =
    Array.isArray(sellers) &&
    sellers.filter((seller) => seller.isApproved === false);

  return (
    <>
      {adminOrderLoading ? (
        <Loader />
      ) : (
        <div className='w-full p-4'>
          <h3 className='pb-2 font-Poppins text-[22px]'>Resumen</h3>
          <div className='block w-full justify-between gap-2 md:flex'>
            <section className='flex w-1/2 justify-between gap-2'>
              <div className='h-36 w-full rounded bg-white px-2 py-2 shadow md:w-[30%]'>
                <div className='mb-2 flex items-center gap-2'>
                  <GiMoneyStack size={25} className=' text-gray-500' />
                  <h5 className=' text-3xl font-bold '>C$ {adminBalance}</h5>
                </div>
                <h3 className={` text-base leading-5 text-gray-500`}>
                  Ganancias totales
                </h3>
              </div>

              <div className='h-36  w-full rounded bg-white px-2 py-2 shadow md:w-[30%]'>
                <div className='mb-2 flex items-center'>
                  <IoStorefrontOutline
                    size={25}
                    className='mr-2 text-gray-500'
                  />
                  <h5 className='text-3xl font-bold'>{partners}</h5>
                </div>
                <h3 className={`text-base leading-5 text-gray-500`}>
                  Afiliados
                </h3>
                <Link to='/admin-sellers'>
                  <h5 className='pl-2 pt-4 text-primary-200'>Ver Afiliados</h5>
                </Link>
              </div>

              <div className='h-36 w-full rounded bg-white px-2 py-2 shadow md:w-[30%]'>
                <div className='mb-2 flex items-center'>
                  <FiShoppingBag size={20} className='mr-2 text-gray-500' />
                  <h5 className='text-3xl font-bold'>
                    {adminOrders && adminOrders.length}
                  </h5>
                </div>
                <h3 className={`text-base leading-5 text-gray-500`}>
                  Mis pedidos
                </h3>
                <Link to='/admin-orders'>
                  <h5 className='pl-2 pt-4  text-primary-200'>Ver todos</h5>
                </Link>
              </div>
            </section>

            <div className='w-2/5 items-start'>
              <MKCard className=''>
                <section>
                  <section className='flex  justify-between'>
                    <div>
                      <p className=' text-lg font-bold'>
                        Afiliados Potenciales
                      </p>
                      <p className='text-gray-400'>En espera de aprobación</p>
                    </div>
                    <Link
                      to='/admin-sellers'
                      className='flex items-center justify-center rounded-xl bg-primary-200 px-3'
                    >
                      <p className='text-white'>Ver todos</p>
                    </Link>
                  </section>

                  <section className='my-2 md:my-4'>
                    {Array.isArray(potentialPartners) &&
                      potentialPartners?.map((potentialPartner) => (
                        <MKCard
                          key={`dashboard-partners-${potentialPartner._id}`}
                          className='flex flex-col gap-4'
                        >
                          <section className='flex items-center justify-between border-b pb-2'>
                            <p>{potentialPartner.name}</p>
                            {/* <MKButton
                              variant='outlined'
                              className='flex items-center px-2 py-1'
                            >
                              <FaCheck />
                              <p>Aprobar</p>
                            </MKButton> */}
                          </section>
                          <section>
                            <div className='flex items-center gap-2'>
                              <FaMapMarkedAlt className='text-gray-700' />
                              {potentialPartner.address.region} -{' '}
                              {potentialPartner.address.city}
                            </div>
                            <div className='flex items-center gap-2'>
                              <FaMapPin className='text-gray-700' />
                              {potentialPartner.address.neighborhood}
                            </div>
                          </section>
                        </MKCard>
                      ))}
                  </section>
                </section>
              </MKCard>
            </div>
          </div>

          <section className='flex justify-between gap-2'>
            <MKCard className='mt-10 w-2/5'>
              <Bar options={options} data={barData} />{' '}
            </MKCard>

            <MKCard className='mt-10 h-max w-2/5'>
              <LineChart data={data} />
            </MKCard>
          </section>
        </div>
      )}
    </>
  );
};

export default AdminDashboardMain;
