import { RxCross2 } from 'react-icons/rx';
import { FaCheck } from 'react-icons/fa';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Wrapper } from '@googlemaps/react-wrapper';

import MKButton from '../../../../UI/MKButton';
import CityMap from './CityMap';
import { MapApp } from '../../../../UI/GoogleMap';
import { useDispatch, useSelector } from 'react-redux';
import { changeCityRange } from '../../../../../redux/actions/city';
import { createCityService } from '../../../../../services/admin/cities';

interface ICreateCityDrawerProps {
  _id: string;
  disableCreateCity: () => void;
}
type Inputs = {
  name: string;
  range: number;
  active: boolean;
};

const CreateCityDrawer = ({
  _id,
  disableCreateCity,
}: ICreateCityDrawerProps) => {
  // @ts-ignore
  const { cityGeoLocation, cityRange } = useSelector((state) => state.city);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data);
    const res = await createCityService({
      name: data.name,
      regionId: _id,
      active: data.active,
      location: cityGeoLocation,
      range: Number(cityRange),
    });
    if (res.error || res.success === false) {
      console.log('error:');
      return;
    }
    disableCreateCity();
  };
  const handleChangeRadio = (e: any) => {
    const cityOperationRange = e.target.value;
    if (!isNaN(cityOperationRange)) {
      // @ts-ignore
      dispatch(changeCityRange(Number(cityOperationRange)));
    }
  };
  return (
    <section>
      <section className='flex justify-center'>
        <div className='w-full overflow-y-scroll rounded-md bg-white '>
          <div className='my-4 w-full shadow'>
            <div className='flex items-center justify-between px-6 pb-2 '>
              <h5 className='text-center text-xl '>Agrega nueva ciudad</h5>
            </div>
          </div>

          <form
            className='flex flex-col gap-4 px-4'
            onSubmit={handleSubmit(onSubmit)}
          >
            <section className='mb-4 flex items-center gap-8'>
              <div className='flex items-center gap-2'>
                <label>Ciudad</label>
                <input
                  {...register('name')}
                  placeholder='Nombre de la ciudad'
                  className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm'
                />
              </div>
              <div className='flex items-center gap-2'>
                <label>Activa</label>
                <div className='relative'>
                  <input
                    {...register('active')}
                    placeholder='Nombre de la ciudad'
                    defaultChecked
                    type='checkbox'
                    className='peer relative mt-1 h-6 w-6 shrink-0 appearance-none rounded-md border'
                  />

                  <FaCheck className='pointer-events-none absolute left-1 top-2 h-4 w-4 fill-none stroke-white peer-checked:!fill-primary-200' />
                </div>
              </div>

              <div className='flex items-center gap-2'>
                <label>Radio</label>
                <input
                  {...register('range')}
                  placeholder='Rango de operaciones'
                  defaultChecked
                  onChange={handleChangeRadio}
                  type='number'
                  className='block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm'
                />
              </div>
            </section>
            <MapApp width='100%' height='60vh' radio={cityRange} />

            <MKButton className='mt-12' type='submit'>
              <p>Crear ciudad</p>
            </MKButton>
          </form>
        </div>
      </section>
    </section>
  );
};

export default CreateCityDrawer;
