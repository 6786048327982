import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import ShopInfo from '../../components/Shop/ShopInfo/ShopInfo';
import ShopProfileData from '../../components/Shop/ShopProfileData';
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import DashboardCommonLayout from '../../components/Layout/DashboardCommonLayout';

import { server } from '../../server';
import styles from '../../styles/styles';

const ShopHomePage = () => {
  const [data, setData] = useState({});
  const { id } = useParams();

  useEffect(() => {
    axios.get(`${server}/shop/get-shop-info/${id}`).then((res) => {
      setData(res.data.shop);
    });
  }, []);

  return (
    <DashboardCommonLayout>
      <div className='flex w-full justify-between'>
        <div className='w-[80px] 800px:w-[330px]'>
          <DashboardSideBar active={11} />
        </div>
        <div className={`${styles.section}`}>
          <div className='flex w-full flex-col justify-between gap-2 py-10 md:gap-4'>
            <div className='sticky left-0 top-10 z-10 w-full overflow-y-scroll rounded-md bg-slate-50 shadow-md'>
              <ShopInfo data={data} isOwner={true} />
            </div>
            <div className='w-full'>
              <ShopProfileData isOwner={true} />
            </div>
          </div>
        </div>
      </div>
    </DashboardCommonLayout>
  );
};

export default ShopHomePage;
