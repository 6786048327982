import MKButton from '../../../UI/MKButton';
import useCategories from '../../../../hooks/category/useCategoriesData';
import { Popover, PopoverContent, PopoverTrigger } from '../../../UI/MKPopover';
import { FaCheckCircle, FaRegCircle } from 'react-icons/fa';
import styles from '../../../../styles/styles';
import { ICategoryModel } from '../../../../interfaces/category/categoryModel';
import { cn } from '../../../../utils/cn';

interface ISelectCategoryProps {
  selectedCategory: Object;
  onChangeSelected: (newSelected: any) => void;
}

const SelectCategory = ({
  selectedCategory,
  onChangeSelected,
}: ISelectCategoryProps) => {
  const { categories } = useCategories();

  const updateSelectedCategory = (key: string, values: ICategoryModel) => {
    const isSelected = !!selectedCategory[key];

    const newState = { ...selectedCategory, [key]: { ...values } };
    if (typeof isSelected == undefined) {
      onChangeSelected(newState);
      return;
    }
    if (isSelected === true) {
      delete newState[key];
    }
    onChangeSelected(newState);
  };
  const selectedCategoriesLength = Object.keys(selectedCategory).length;

  return (
    <div>
      <p>
        Categorias <span className='text-red-500'>*</span>
      </p>
      <Popover>
        <PopoverTrigger asChild>
          {selectedCategoriesLength === 0 ? (
            <p className={cn(styles.default_input, ' h-12 ')}>
              Seleccionar categorias
            </p>
          ) : (
            <div
              className={cn(
                styles.default_input,
                'flex h-12 items-center gap-2'
              )}
            >
              {Object.keys(selectedCategory)
                .slice(0, 2)
                .map((category) => (
                  <div className='rounded-xl border border-primary-200 px-2 py-1'>
                    {selectedCategory[category]?.name}
                  </div>
                ))}
              {selectedCategoriesLength - 2 > 0 && (
                <div className='text-gray-400'>
                  +{selectedCategoriesLength - 2} mas
                </div>
              )}
            </div>
          )}
        </PopoverTrigger>
        <PopoverContent className='w-80 bg-white'>
          <div className='grid gap-4'>
            <div className='space-y-2'>
              <p className='text-muted-foreground text-sm'>
                Selecciona las categorias
              </p>
            </div>
            <div className='grid gap-2 '>
              {categories?.map((category) => (
                <div
                  key={`select-category${category._id}`}
                  className='flex items-center gap-2'
                >
                  <MKButton
                    onClick={() =>
                      updateSelectedCategory(category._id, category)
                    }
                    variant='none'
                    className='flex items-center gap-2'
                  >
                    {selectedCategory[category._id] ? (
                      <FaCheckCircle />
                    ) : (
                      <FaRegCircle />
                    )}
                    <img
                      className='h-6 w-6 rounded-full'
                      style={{ background: category.image.mainColor }}
                      src={category.image.url}
                      alt=''
                    />
                    <p>{category.name}</p>
                  </MKButton>
                </div>
              ))}
            </div>
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default SelectCategory;
