import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import AllOrders from '../../components/Shop/AllOrders';
import DashboardCommonLayout from '../../components/Layout/DashboardCommonLayout';
import { useSelector } from 'react-redux';
import { SHOP_RESOURCE_ROLE_NAME } from 'src/utils/constants/common';

const ShopAllOrders = () => {
  const { seller } = useSelector((state) => state.seller);
  const readPermission =
    seller.isAdmin ||
    seller.permission[SHOP_RESOURCE_ROLE_NAME.orders]?.permissions?.read;
  return (
    <DashboardCommonLayout>
      <div className='flex w-full justify-between'>
        <div className='w-[80px] 800px:w-[330px]'>
          <DashboardSideBar active={2} />
        </div>
        {!readPermission && (
          <p>No tienes permisos para acceder a este módulo</p>
        )}
        {readPermission && <AllOrders />}
      </div>
    </DashboardCommonLayout>
  );
};

export default ShopAllOrders;
